/* eslint-disable default-param-last */
import { validateEmail } from '@/helpers/common';
import COUNTRIES from '@/helpers/countries';
import ACTION_TYPE from './action-type';

const initialState = {
  listingId: '',
  selectedTab: 'ABOUT_YOU',
  isAboutYouUpdated: false,
  isAboutYouCompleted: false,
  aboutYou: {
    id: '',
    title: { value: 'Mr', label: 'Mr' },
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    postcode: '',
    countryCode: '',
    phone: '',
    fullPhone: '',
    jobTitle: '',
    salary: '',
    flat: '',
    address1: '',
    address2: '',
    city: '',
    county: '',
    country: null,
    defaultAddress: true,
    errors: {},
    documents: {
      selfie: false,
      dl: false,
      poa: false,
      dvla: false,
      verifcationCompleted: false,
      onfidoToken: '',
    },
  },
  isDriverUpdated: false,
  isDriverYouCompleted: false,
  driver: {
    id: '',
    mainDriver: '',
    title: '',
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    countryCode: '44',
    phone: '',
    fullPhone: '44',
    jobTitle: '',
    salary: '',
    errors: {},
  },
  updatePricingList: false,
  deliveryDate: null,
  mileageId: null,
  contractId: null,
  listingColorId: null,
  additionalDriver: {
    id: null,
    firstName: null,
    lastName: null,
    documents: {
      selfie: false,
      dl: false,
      poa: false,
      dvla: false,
      verifcationCompleted: false,
      onfidoToken: '',
    },
  },
  agreement: false,
  pricing: {},
  overviewDetails: {},
  appliedPromotion: { submitComplete: false },
  triggerGACompleteOnBoarding: false,
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.RESET:
      return {
        ...initialState,
        appliedPromotion: { ...state.appliedPromotion },
      };

    case ACTION_TYPE.LISTING_ID:
      return { ...state, listingId: action.payload.value };

    case ACTION_TYPE.SWITCH_TAB:
      return { ...state, selectedTab: action.payload.value };

    case ACTION_TYPE.INITIALIZE:
      return {
        ...state,
        aboutYou: {
          ...state.aboutYou,
          ...action.payload,
          dob: action.payload?.dateOfBirth ? new Date(action.payload?.dateOfBirth) : null,
          title: { value: action.payload?.title, label: action.payload?.title },
          country: COUNTRIES.find(country => country.value === action.payload?.country),
        },
      };

    case ACTION_TYPE.INITIALIZE_DRIVER:
      return {
        ...state,
        driver: {
          ...state.driver,
          ...action.payload,
          dob: action.payload?.dateOfBirth ? new Date(action.payload?.dateOfBirth) : null,
          title: action.payload?.title
            ? { value: action.payload?.title, label: action.payload?.title }
            : null,
        },
      };

    case ACTION_TYPE.REMOVER_DRIVER:
      return {
        ...state,
        driver: {
          ...initialState.driver,
        },
        additionalDriver: {
          ...initialState.additionalDriver,
        },
      };

    case ACTION_TYPE.ON_CHANGE:
      return {
        ...state,
        aboutYou: { ...state.aboutYou, [action.payload.key]: action.payload.value },
        isAboutYouUpdated: true,
      };

    case ACTION_TYPE.ON_CHANGE_ABOUT_YOU_STATUS:
      return {
        ...state,
        isAboutYouUpdated: false,
      };

    case ACTION_TYPE.ON_CHANGE_DRIVER:
      return {
        ...state,
        driver: { ...state.driver, [action.payload.key]: action.payload.value },
        isDriverUpdated: true,
      };

    case ACTION_TYPE.ON_CHANGE_DOCUMENTS_MAIN_DRIVER:
      return {
        ...state,
        aboutYou: {
          ...state.aboutYou,
          documents: {
            ...state.aboutYou.documents,
            [action.payload.key]: action.payload.value,
          },
        },
      };

    case ACTION_TYPE.ON_CHANGE_DOCUMENTS_EXTRA_DRIVER:
      return {
        ...state,
        additionalDriver: {
          ...state.additionalDriver,
          documents: {
            ...state.additionalDriver.documents,
            [action.payload.key]: action.payload.value,
          },
        },
      };

    case ACTION_TYPE.ON_BLUR:
      if (!action.payload.value.trim()) {
        return {
          ...state,
          aboutYou: {
            ...state.aboutYou,
            errors: {
              ...state.aboutYou.errors,
              [action.payload.key]: 'This field is required',
            },
          },
        };
      }
      if (action.payload.key === 'email') {
        return {
          ...state,
          aboutYou: {
            ...state.aboutYou,
            errors: {
              ...state.aboutYou.errors,
              [action.payload.key]: !validateEmail(action.payload.value.trim())
                ? 'You have entered an invalid email address!'
                : '',
            },
          },
        };
      }
      return {
        ...state,
        aboutYou: {
          ...state.aboutYou,
          errors: {
            ...state.aboutYou.errors,
            [action.payload.key]: '',
          },
        },
      };

    case ACTION_TYPE.AUTOFILL:
      return {
        ...state,
        aboutYou: {
          ...state.aboutYou,
          ...action.payload.value,
          country: COUNTRIES.find(country => country.value === action.payload.value?.country),
        },
      };

    case ACTION_TYPE.SAVE_DELIVERY_DATE:
      return {
        ...state,
        deliveryDate: action.payload.value,
      };

    case ACTION_TYPE.ADDITIONAL_SERVICES:
      return {
        ...state,
        mileageId: action.payload.value,
      };
    case ACTION_TYPE.CONTRACT_LENGTH:
      return {
        ...state,
        contractId: action.payload.value,
      };
    case ACTION_TYPE.ADDITIONAL_DRIVER:
      return {
        ...state,
        additionalDriver: {
          ...state.additionalDriver,
          ...action.payload.value,
        },
      };

    case ACTION_TYPE.UPDATE_PRICING_LIST:
      return {
        ...state,
        updatePricingList: action.payload.value,
      };

    case ACTION_TYPE.ON_TAB_COMPLETED:
      return {
        ...state,
      };

    case ACTION_TYPE.LISTING_COLOR_ID:
      return {
        ...state,
        listingColorId: action.payload.value,
      };

    case ACTION_TYPE.ON_AGREEMENT:
      return {
        ...state,
        agreement: action.payload.value,
      };
    case ACTION_TYPE.ON_GET_PRICING:
      return {
        ...state,
        pricing: action.payload.value,
      };
    case ACTION_TYPE.ON_GET_OVERVIEW_DETAILS:
      return {
        ...state,
        overviewDetails: action.payload.value,
      };
    case ACTION_TYPE.ON_CHANGE_EXTRA_DRIVER:
      return {
        ...state,
        additionalDriver: {
          ...state.additionalDriver,
          [action.payload.key]: action.payload.value,
        },
      };
    case ACTION_TYPE.RESET_DOCUMENTS_MAIN_DRIVER:
      return {
        ...state,
        aboutYou: {
          ...state.aboutYou,
          documents: {
            ...state.aboutYou.documents,
            selfie: false,
            dl: false,
            poa: false,
            dvla: false,
            verifcationCompleted: false,
          },
        },
      };

    case ACTION_TYPE.RESET_DOCUMENTS_EXTRA_DRIVER:
      return {
        ...state,
        additionalDriver: {
          ...state.additionalDriver,
          documents: {
            ...state.additionalDriver.documents,
            selfie: false,
            dl: false,
            poa: false,
            dvla: false,
            verifcationCompleted: false,
          },
        },
      };

    case ACTION_TYPE.APPLY_PROMOTION:
      return {
        ...state,
        appliedPromotion: {
          ...state.appliedPromotion,
          ...action.payload,
        },
      };

    case ACTION_TYPE.RESET_APPLY_PROMOTION:
      return {
        ...state,
        appliedPromotion: { ...initialState.appliedPromotion },
      };

    case ACTION_TYPE.APPLY_PROMOTION_DISCOUNT_AMOUNT:
      return {
        ...state,
        appliedPromotion: {
          ...state.appliedPromotion,
          discountAmount: action.payload.value,
        },
      };

    case ACTION_TYPE.APPLY_PROMOTION_SUBMIT_COMPLETE:
      return {
        ...state,
        appliedPromotion: {
          ...state.appliedPromotion,
          submitComplete: action.payload.value,
        },
      };

    case ACTION_TYPE.APPLY_PROMOTION_SWAP_VEHICLE:
      return {
        ...state,
        appliedPromotion: {
          ...state.appliedPromotion,
          applyPromotionSwapVehicle: action.payload.value,
        },
      };
    case ACTION_TYPE.TRIGGER_GA_COMPLETE_ONBOARDING_EVENT:
      return {
        ...state,
        triggerGACompleteOnBoarding: action.payload.value,
      };
    default:
      return state;
  }
};

export default bookingReducer;
