import classNames from 'classnames/bind';
import Icon from '../Icon';
import style from './input-search.module.scss';

const cx = classNames.bind(style);

function InputSearch(props) {
  const { type = 'search', placeholder, value, onChange, onKeyDown, onSubmit, disabled } = props;
  if (onSubmit) {
    return (
      <div className={style.searchForm}>
        <button onClick={onSubmit} className={style.searchIcon}>
          <Icon icon="search" size={20} color="#000" />
        </button>
        <input {...{ type, placeholder, value, onChange, onKeyDown, disabled }} />
      </div>
    );
  }
  return (
    <input
      {...{ type, placeholder, value, onChange, onKeyDown, disabled }}
      className={cx(style['search-field'], {
        disabled,
      })}
    />
  );
}

export default InputSearch;
