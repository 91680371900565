/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import style from '@/components/general/SubscriptionAgreement/agreement.module.scss';
import {
  subscriptionList,
  responsibilitiesList,
  driveFuzeResponsibilitiesList,
  liabilityList,
  vehicleConditionList,
  dataProtectionList,
  telematicsList,
  serviceList,
  governingLawList,
  generalList,
  insuranceList,
  continuousPaymentList,
  tollsList,
  damageList,
  cancellationList,
} from './list';
import { cancellationListV4, tollsListV4 } from './listV4';

const RenderNestedList = props => {
  const { data, startKey, reference } = props;
  return (
    <div id={`#Section${startKey}`} ref={reference}>
      {data.map((item, index) => (
        <>
          <div id={`#Section${startKey}${index + 1}`} key={index} className={style.mainText}>
            {startKey}.{index + 1} {item.entry.mainText}
            {item?.entry?.subText && (
              <div style={{ padding: '16px' }}>
                {item?.entry?.subText.map((element, idx) => (
                  <div
                    id={`#Section${startKey}${index + 1}${idx + 1}`}
                    key={idx}
                    className={style.subText}
                  >
                    {startKey}.{index + 1}.{idx + 1} {element.subList}
                  </div>
                ))}
              </div>
            )}
          </div>
          {item?.entry?.newParagraphNoPoint && (
            <div
              className={
                item?.entry?.newParagraphNoPoint?.className === 'subText'
                  ? style.subText
                  : style.mainText
              }
              style={{ padding: item?.entry?.newParagraphNoPoint?.padding }}
            >
              {item?.entry?.newParagraphNoPoint?.text}
            </div>
          )}
          {item?.entry?.caption && <div className={style.caption}>{item?.entry?.caption}</div>}
        </>
      ))}
    </div>
  );
};
const SubscriptionConditions = props => {
  const { agreementRef, version } = props;
  const conditionsArray = [
    { key: 1, title: 'Subscription' },
    { key: 2, title: 'Responsibilities of the driver' },
    { key: 3, title: 'Responsibilities of Drive Fuze' },
    { key: 4, title: 'Liability' },
    { key: 5, title: 'Conditions for using the vehicle' },
    { key: 6, title: 'Subscription fees, fines, tolls and other charges' },
    { key: 7, title: 'Insurance' },
    { key: 8, title: 'Accidents, Damage and theft' },
    { key: 9, title: 'Servicing, Maintenance and breakdown cover' },
    { key: 10, title: 'Telematics' },
    { key: 11, title: 'Cancellation, default and termination' },
    { key: 12, title: 'Continuous payment authority' },
    { key: 13, title: 'Governing Law' },
    { key: 14, title: 'General' },
    { key: 15, title: 'Data Protection' },
  ];

  const returnList = key => {
    switch (key) {
      case 1:
        return <RenderNestedList data={subscriptionList} startKey={1} reference={agreementRef} />;
      case 2:
        return <RenderNestedList data={responsibilitiesList} startKey={2} />;
      case 3:
        return <RenderNestedList data={driveFuzeResponsibilitiesList} startKey={3} />;
      case 4:
        return <RenderNestedList data={liabilityList} startKey={4} />;
      case 5:
        return <RenderNestedList data={vehicleConditionList} startKey={5} />;
      case 6:
        return <RenderNestedList data={version === 'v4' ? tollsListV4 : tollsList} startKey={6} />;
      case 7:
        return <RenderNestedList data={insuranceList} startKey={7} />;
      case 8:
        return <RenderNestedList data={damageList} startKey={8} />;
      case 9:
        return <RenderNestedList data={serviceList} startKey={9} />;
      case 10:
        return <RenderNestedList data={telematicsList} startKey={10} />;
      case 11:
        return (
          <RenderNestedList
            data={version === 'v4' ? cancellationListV4 : cancellationList}
            startKey={11}
          />
        );
      case 12:
        return <RenderNestedList data={continuousPaymentList} startKey={12} />;
      case 13:
        return <RenderNestedList data={governingLawList} startKey={13} />;
      case 14:
        return <RenderNestedList data={generalList} startKey={14} />;
      case 15:
        return <RenderNestedList data={dataProtectionList} startKey={15} />;
      default:
        return null;
    }
  };
  return (
    <div className={style.conditionContainer}>
      {conditionsArray.map((item, index) => (
        <div key={index} className={style.conditionRow}>
          <div className={style.conditionHeading}>
            {index + 1}. {item.title}
          </div>
          <div className={style.conditionContent}>{returnList(item.key)}</div>
        </div>
      ))}
    </div>
  );
};

export default SubscriptionConditions;
