const LOGIN_CONSTANTS = {
  INITIATED: 'initiated',
  EMAIL_VERIFIED: 'email_verified',
  RESTRICTED_POSTCODE: 'restricted_postcode',
  ONBOARD: 'onboarded',
};

export const INVOICE_STATUS_LABEL = {
  DUE: 'Due',
  PAID: 'Paid',
  PAYMENT_FAILED: 'Payment Failed',
};

export default LOGIN_CONSTANTS;

export const contractTypeParser = {
  monthlyRolling: {
    status: 'monthly-rolling',
    label: 'Monthly rolling',
  },
  nonRolling: {
    status: 'non-rolling',
    label: 'Non rolling',
  },
};
