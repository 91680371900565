const SWAP_ACTION_TYPE = {
  SWAP_INITIAL_ABOUT_YOU: 'SWAP_INITIAL_ABOUT_YOU',
  SWAP_INITIALIZE_SUBSCRIPTION: 'SWAP_INITIALIZE_SUBSCRIPTION',
  SWAP_SHOW_DOCUMENTS: 'SWAP_SHOW_DOCUMENTS',
  SWAP_DELIVERY: 'SWAP_DELIVERY',
  SWAP_SHOW_ABOUT_YOU: 'SWAP_SHOW_ABOUT_YOU',
  SWAP_TABS_ENABLE_DISABLE: 'SWAP_TABS_ENABLE_DISABLE',
  SWAP_IS_SAME_ADDRESS: 'SWAP_IS_SAME_ADDRESS',
  SWAP_IS_SAME_JOB_SALARY: 'SWAP_IS_SAME_JOB_SALARY',
  UPDATE_VEHICLE_COLOUR: 'UPDATE_VEHICLE_COLOUR',
  SWAP_EXISTS: 'SWAP_EXISTS',
  SWAP_RESET: 'SWAP_RESET',
  NEXT_PAYMENT_DATE: 'NEXT_PAYMENT_DATE',
};

export default SWAP_ACTION_TYPE;
