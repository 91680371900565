import style from '@/components/general/SubscriptionAgreement/agreement.module.scss';

const RenderTable = ({ title, value, value2, data }) => {
  return (
    <div className={style.tableType_1}>
      <div className={style.cardWrapper}>
        <div className={style.cardTitle}>{title}</div>
        {value !== 'earlyFees' && (
          <div className={style.cardData}>
            {value} <br></br>
            {value2}
          </div>
        )}
        {value === 'earlyFees' && (
          <div className={style.cardData}>
            {data?.metadata?.earlyTerminationFees.length === 0 ? (
              <p>
                A 14-day notice period shall apply should the Driver elect to cancel or change the
                vehicle before the next rolling contract renewal date. If such notice is not given
                to Drive Fuze, the Driver will be liable to pay for the full Subscription Fee
                whether or not the Vehicle is returned either within or at the end of the new month.
              </p>
            ) : (
              <p>
                {data?.metadata?.earlyTerminationFees.map((el, index) => (
                  <p key={index}>
                    After {el.month} {el.month !== 1 ? 'months' : 'month'}: £{el.returnFee}
                  </p>
                ))}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RenderTable;
