export const cancellationListV4 = [
  {
    entry: {
      mainText: `For the avoidance of doubt this Agreement and the Subscription Term ends only with the Driver's return of the Vehicle to Drive Fuze or a third party specified by Drive Fuze.`,
    },
  },
  {
    entry: {
      mainText: `Driver may elect to cancel their subscription and the following applies unless otherwise stated:`,
      subText: [
        {
          subList: `at any point before the Subscription Start Date, the Driver can cancel the subscription. The Initial Payment paid in advance by the Driver will be refunded (refunds can take up to 10 working days to appear in the Driver's bank account). We shall retain £100 as a cancellation charge to cover the vehicle administration cost`,
        },
        {
          subList: `if the Driver cancels the subscription after 09.00 on the day before the Start Date and Drive Fuze are unable to cancel the delivery of the Vehicle, we shall retain 50% of the Delivery Charge paid.]`,
        },
        {
          subList: `if the Subscription Start Date has begun and the Driver has accepted the vehicle upon delivery by signing the check-in sheet a refund of the Monthly Subscription Fee and Initial Payment will not apply.`,
        },
        {
          subList: `If the Subscription Start Date has begun and the Driver rejects the Vehicle by not signing the Vehicle Condition Report and does not choose to subscribe then a refund of the Initial Payment under 6.7 will apply`,
        },
        {
          subList: `After payment of the first Subscription fee, when the Vehicle has been accepted and delivered the Driver will be required to pay the Subscription Fees for the Minimum Subscription Term. Should the Driver elect to cancel or change the vehicle before the end of the Minimum Subscription Term, an amount equal to two months Subscription Fee will be charged as an Early Termination Fee as detailed above in this Agreement.`,
        },
        {
          subList: `On the final month of the Minimum Subscription Term,  a 14-day notice period shall apply should the Driver elect to cancel or change the vehicle before the next rolling contract renewal date. If such notice is not given to Drive Fuze, the Driver will be liable to pay for the full Subscription Fee  whether or not the Vehicle is returned either within or at the end of the new month.`,
        },
        {
          subList: `After all the payments during the Minimum Subscription Term, a 14-day notice period shall apply should the Driver elect to cancel or change the vehicle before the next rolling contract renewal date. If such notice is not given to Drive Fuze, the Driver will be liable to pay for the full Subscription Fee  whether or not the Vehicle is returned either within or at the end of the new month.`,
        },
      ],
    },
  },
  {
    entry: {
      mainText: `Drive Fuze may cancel a subscription prior to the Subscription Start Date where reasonably required (for example, if an unforeseen Vehicle fault arises, or if the availability of the Vehicle changes) by notifying the Driver at any point prior to the Driver collecting the Vehicle (or having the Vehicle delivered to Driver, as the parties may agree), and any amounts paid shall be refunded in full to the Driver.`,
    },
  },
  {
    entry: {
      mainText: `If Drive Fuze attempts to collect the Vehicle from the Driver on the last day of the Subscription Term but is unable to do so due to the Driver's fault, Drive Fuze will allow a grace period of one day for the Driver to return the Vehicle. After this one day grace period, the Driver shall be liable to pay the daily rate for any additional time for which the Driver uses the Vehicle. This shall be calculated using the Daily Subscription Rate included in the Subscription Agreement (e.g., if a Driver returns a Vehicle two (2) days late, they will be charged two day's Subscription Fees based on the Daily Subscription Rate).`,
    },
  },
  {
    entry: {
      mainText: `Drive Fuze may terminate this Agreement if any of the following occur:`,
      subText: [
        {
          subList: `the Driver fails to pay any Subscription Fee, Fine, Toll, charges or any other amount due under this Agreement on the date when it becomes due;`,
        },
        {
          subList: `the Driver or Named Driver(s) breach their obligations under this Agreement including, without limitation, conditions 2, 5 or 7 of this Agreement`,
        },
        {
          subList: `the Driver is no longer in possession or control of the Vehicle;`,
        },
        {
          subList: `the Driver becomes bankrupt or enters into any arrangement or composition with creditors;`,
        },
        {
          subList: `the Driver or Named Driver receives endorsements or convictions on their driving licence which are unacceptable to Drive Fuze;`,
        },
        {
          subList: `the Driver is driving in a dangerous or unacceptable manner and not complying with the Telematics Terms & Conditions;`,
        },
        {
          subList: `if any insurance policy under condition 7 becomes invalid at any point during the Subscription Term;`,
        },
        {
          subList: `if any information supplied by the Driver to Drive Fuze in connection with this Agreement is inaccurate or misleading in any material way;`,
        },
        {
          subList: `if the Driver cancels the Payment Authority details in 12 without informing Drive Fuze within 3 business days of alternative Payment Instructions.`,
        },
      ],
    },
  },
  {
    entry: {
      mainText: `Drive Fuze has the right to terminate the Agreement at the end of the Minimum Subscription Term end date.  If terminated, the Vehicle must be returned to Drive Fuze, and the driver would be refunded any payments under 6.9`,
    },
  },
  {
    entry: {
      mainText: `Upon early termination of this Agreement, the Driver shall immediately be liable for (a) two months Subscription Fees as detailed in 11.2.5; (b) any arrears of Subscription Fees and other amounts due and payable at the time (including for the avoidance of doubt any Fines, Tolls, and other charges) and (c) any other amounts which may become due and payable under this Subscription Agreement.`,
    },
  },
  {
    entry: {
      mainText: `Upon expiry of the Subscription Term or on any early termination of this Subscription Agreement for any reason, the Driver's right to use of the Vehicle will cease and the Vehicle must immediately be returned to Drive Fuze or such location as Drive Fuze directs. Drive Fuze may repossess the Vehicle if the Driver fails to return the Vehicle and the Driver shall indemnify Drive Fuze in relation to any loss and damage resulting from the repossession of the Vehicle without prejudice to Drive Fuze's other rights and remedies under this Agreement.`,
    },
  },
  {
    entry: {
      mainText: `If the Driver fails to return the Vehicle to Drive Fuze on the last day of the Subscription Term or on any earlier termination as required the Driver shall be liable for an overdue fee for each day that the Vehicle is not returned (the Delay Period) pro rata as in condition 11.4.`,
    },
  },
  {
    entry: {
      mainText: `Failure by the Driver to return the Vehicle to Drive Fuze promptly in accordance with condition 11.8 may result in Drive Fuze reporting the Driver to the relevant authorities. Drive Fuze may arrange for any insurance on the Vehicle to end (if it has not ended already) and may report that the Vehicle is no longer insured to third parties in its sole discretion.`,
    },
  },
  {
    entry: {
      mainText: `On return of the Vehicle, the Driver must remove any debris, rubbish and the Driver's personal items from the Vehicle. Drive Fuze and/or our Partners will not be liable for loss or damage of belongings left in the vehicle upon collection.`,
    },
  },
  {
    entry: {
      mainText: `The Driver must then hand the keys to the Vehicle to Drive Fuze, or a third party on behalf of Drive Fuze, (or return them in a manner otherwise agreed with Drive Fuze).`,
    },
  },
  {
    entry: {
      mainText: `Drive Fuze may amend the Subscription End Date to such earlier time as may be reasonably required in connection with its business purposes, provided that the Driver shall be entitled to a pro rata refund of the Subscription Fees (at the daily subscription rate identified in the Subscription Agreement) for the number of days by which the Subscription Term has been shortened.`,
    },
  },
];

export const tollsListV4 = [
  {
    entry: {
      mainText: `The Driver must pay the Initial Payment and the Subscription Fees at the rate and on the dates specified in the Subscription Agreement. Unless the Driver gives the Owner 14 days prior written notice, to expire no earlier than the expiry of the Minimum Subscription Term, the Subscription of the Vehicle shall automatically continue, such extension period being the “Extension Period” and the Driver shall continue to make the Subscription Fees on the same frequency as set out above and the Driver shall continue to be bound by the terms of this Agreement until terminated, either by the Owner at our discretion or in accordance with clause 11 or by the Driver in accordance with this clause 6.`,
    },
  },
  {
    entry: {
      mainText: `The Driver shall be liable for the following charges and pay them on demand during and/or following the Subscription Term:`,
      subText: [
        {
          subList: `all charges which are payable after the discovery of damage following re-Inspection of the Vehicle when returned by the Driver to Drive Fuze;`,
        },
        {
          subList: `all charges, fines and court costs including congestion charges, parking, traffic, speeding or other offences, and any civil penalty payable relating to the Vehicle (“Fines”) and any tolls, towing away or clamping fees or charges including toll road fees, and Congestion Charges (“Tolls”). Drive Fuze will pay all charges and fines and pass on the costs to the Driver including the administration charge set out in 6.1.3. Drive Fuze will not pay any fines or charges directly in the instance of a speeding or custodial fine. In these Instances Drive Fuze is obligated by law to pass on the primary driver details to the relevant police authority.`,
        },
        {
          subList: `any value added tax and all other taxes and levies on any of the Fines, Tolls and charges, as appropriate including a £50 administration fee for each Fine, Toll or charge that the Driver incurs during the Subscription Term;`,
        },
        {
          subList: `any reasonably incurred, foreseeable losses, costs and charges resulting from the breach by the Driver of this Agreement (such losses being foreseeable where they are contemplated by Drive Fuze and the Driver at the time this Agreement is entered into);`,
        },
        {
          subList: `any other charges arising under this Agreement, including Vehicle delivery and collection fees; and`,
        },
        {
          subList: `any charges in relation to the repossession of the Vehicle, including, without limitation, third party agent costs, transportation required for the repossession of the Vehicle, legal proceedings in relation to the repossession of the Vehicle and any other costs, charges and expenses in relation to or in connection with a breach of this Agreement by the Driver.`,
        },
        {
          subList: `Any early termination fees`,
        },
      ],
    },
  },
  {
    entry: {
      mainText: `For the avoidance of doubt, the Driver shall be liable for any Fines, Tolls and other charges, issued by public authorities or private parking companies, incurred during the Subscription Term even if such Fines, Tolls and other charges are not discovered until after the end of the Subscription Term.`,
    },
  },
  {
    entry: {
      mainText: `It is the responsibility of the Driver to pay the relevant authorities directly for any speeding or custodial Fines. In the case of any speeding or custodial Fine, the Driver acknowledges and agrees that Drive Fuze may pass on the Driver's details to the police or relevant authority, who may then contact the Driver directly. Drive Fuze is not liable for any escalation in value of a Fine as a result of it being delivered to an out of-date address. It is the Driver's responsibility to inform Drive Fuze of any change of address so that Fines may be delivered to them in sufficient time to prevent escalation.`,
    },
  },
  {
    entry: {
      mainText: `The Driver shall keep the Vehicle in good and roadworthy condition (fair wear and tear excepted) throughout the Subscription Term and return the Vehicle in good and roadworthy condition to Drive Fuze at the end of the Subscription Term.`,
    },
  },
  {
    entry: {
      mainText: `If the Driver and/or any Named Driver or any other person smoke inside the Vehicle (for the avoidance of doubt this includes the use of electronic smoking devices), the Driver shall be liable to pay Drive Fuze a fixed sum as compensation of £500. Please note additional charges from damage caused by smoking (cigarette burn in a seat for example) will also be incurred in addition to the £500 charge.`,
    },
  },
  {
    entry: {
      mainText: `Drive Fuze will on behalf of the Driver  pay  in full any Transport for London Congestion Charge Zone fees  or Dart Charge fees (“Charges”) incurred during the Subscription Term . Drive Fuze will apply these Charges to the next Subscription Fee .`,
    },
  },
  {
    entry: {
      mainText: `Drive Fuze credit applied to the Driver’s account during their Subscription has no cash redemption value and cannot be exchanged for cash. Applied credit can only be used on a Drive Fuze Subscription.`,
    },
  },
  {
    entry: {
      mainText: `The Initial Payment will be returned to the Driver 30 days after the termination of this Agreement less:`,
      newParagraphNoPoint: {
        text: `(a) any sums then due to Drive Fuze from the Driver under this Agreement.`,
        className: `subText`,
        padding: `16px`,
      },
    },
  },
  {
    entry: {
      mainText: `If the Driver fails to make a Subscription Fee payment or any other payments or fees, due under the terms of this Agreement, the Driver will be charged a late payment charge of £25 per event.`,
    },
  },
];
