import { useState, useEffect } from 'react';
import breakpoints from '../breakpoints';

export default function useBreakpoint(breakpointStr) {
  const [isBreakpoint, setIsBreakpoint] = useState(false);

  useEffect(() => {
    const onResize = () => {
      const windowWidth = document.documentElement.clientWidth;

      if (windowWidth <= breakpoints[breakpointStr]) {
        setIsBreakpoint(true);
      }
      if (windowWidth > breakpoints[breakpointStr]) {
        setIsBreakpoint(false);
      }
    };

    onResize();

    window.addEventListener('resize', onResize);

    return function unMount() {
      window.removeEventListener('resize', onResize);
    };
  });

  return isBreakpoint;
}
