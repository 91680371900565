/* eslint-disable default-param-last */
import ACTION_TYPE from './action-type';

const initialState = {
  loading: false,
};

const loadingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTION_TYPE.SHOW_LOADING:
      state = {
        ...state,
        loading: true,
      };
      return state;
    case ACTION_TYPE.HIDE_LOADING:
      state = {
        ...state,
        loading: false,
      };
      return state;
    default:
      return state;
  }
};

export default loadingReducer;
