import cx from 'classnames';
import Icon from '@/components/general/Icon';
import Button from '../Button';
import style from './emptyInfoCard.module.scss';

const EmptyInfoCard = props => {
  const {
    message,
    mediumHeight,
    smallerHeight,
    cta,
    backgroundColor,
    bgIconColor = '#DDE3F9',
  } = props;
  return (
    <div
      className={cx(
        style.emptyHist,
        { [style.smallerHeight]: !!smallerHeight },
        { [style.mediumHeight]: !!mediumHeight },
      )}
      style={{ backgroundColor }}
    >
      <p>{message}</p>
      {cta && (
        <div className={style.ctaBtn}>
          <Button onClick={cta.onClick}>{cta.label}</Button>
        </div>
      )}
      <div className={style.bgIcon}>
        <Icon icon="brand-logo" size={mediumHeight ? 380 : 390} color={bgIconColor} />
      </div>
    </div>
  );
};

export default EmptyInfoCard;
