import React, { useEffect, useState } from 'react';
import Image from 'next/legacy/image';
import ImageGallery from 'react-image-gallery';
import CONFIG from '@/helpers/config';
import usePortal from '@/hooks/usePortal';
import Icon from '../Icon';
import style from './imageAndGalleryDisplay.module.scss';

const ImageAndGalleryDisplay = ({ title, value }) => {
  const awsUrl = CONFIG.API.aws_url;

  const { usePortalComponent } = usePortal();
  const Portal = usePortalComponent('div');

  const [showGallery, setShowGallery] = useState('');
  const [galleryImages, setGalleryImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);

  const handleClick = imageUrl => {
    setShowGallery(true);
    const galleryIndex = galleryImages.findIndex(image => {
      return `${awsUrl}${imageUrl}` === image.original;
    });
    if (galleryIndex >= 0) setSelectedImage(galleryIndex);
  };

  const openFile = url => {
    window.open(`${awsUrl}${url}`, '_blank');
  };

  useEffect(() => {
    const gallery = [];
    if (value?.length) {
      value.forEach(item => {
        if (item.type !== 'pdf') {
          gallery.push({
            original: `${CONFIG.API.aws_url + item.image}`,
            thumbnail: `${CONFIG.API.aws_url + item.image}`,
          });
        }
      });
      setGalleryImages(gallery);
    }
  }, [value]);

  return (
    <div className={style.displayInfo}>
      <span className={style.infoTitle}>{title}</span>
      <div className={style['images-section']}>
        {value?.map(el =>
          el.type === 'pdf' ? (
            <div key={el.id} className={style.pdfContainer}>
              <div className={style.pdfAction} onClick={() => openFile(el.image)}>
                <Icon icon="external-link" size={20} color="#001E78" />
              </div>
              <div className={style.pdf}>
                <Icon icon="document" size={100} color="#99A5C9" />
              </div>
            </div>
          ) : (
            <div key={el.id} className={style.imageTile} onClick={() => handleClick(el.image)}>
              <Image
                src={`${CONFIG.API.aws_url}${el.image}`}
                alt={el.fkFinePcnId}
                width={238}
                height={150}
              />
            </div>
          ),
        )}
      </div>
      {showGallery && (
        <Portal>
          <div className={style['gallery-overlay']}>
            <div className={style['gallery-view']}>
              <ImageGallery
                items={galleryImages}
                showPlayButton={false}
                showFullscreenButton={false}
                startIndex={selectedImage}
                slideDuration={galleryImages.length === 2 ? 0 : 450} // It removes animation for slides with two images to avoid a bug from the library.
              />
              <span onClick={() => setShowGallery(false)}>
                <Icon icon="cancel" size={30} color="#fff" />
              </span>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default ImageAndGalleryDisplay;
