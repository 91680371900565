import React from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ROUTE_NAME from '@/helpers/url-constants';
import { Button } from '../general';
import SocialMediaIcons from './SocialMediaIcons';
import style from './footer.module.scss';

const footerLinks = [
  { id: 1, title: 'Book a vehicle', route: ROUTE_NAME.BROWSE_VEHICLES },
  { id: 2, title: 'About us', route: ROUTE_NAME.ABOUT_US },
  { id: 3, title: 'Support', route: ROUTE_NAME.SUPPORT },
  { id: 4, title: 'Privacy Policy', route: ROUTE_NAME.PRIVACY_POLICY },
  { id: 5, title: 'Terms & Conditions', route: ROUTE_NAME.TERMS_AND_CONDITIONS },
  { id: 6, title: 'Financial certification', route: ROUTE_NAME.FINANCIAL_CERTIFICATION },
  { id: 7, title: 'FAQs', route: ROUTE_NAME.FAQ },
  { id: 8, title: 'Complaints', route: ROUTE_NAME.COMPLAINT },
];

export default function Footer() {
  const router = useRouter();

  const isOnBrowseVehiclesPage = router.pathname === ROUTE_NAME.BROWSE_VEHICLES;

  const handleRouteChange = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBrowseCta = () => {
    if (isOnBrowseVehiclesPage) {
      handleRouteChange();
    } else {
      router.push(ROUTE_NAME.BROWSE_VEHICLES);
    }
  };

  return (
    <footer className={style.footer}>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.left}>
            <div className={style.footerLogo}>
              <Link href={ROUTE_NAME.LANDING_PAGE} legacyBehavior>
                <div className={style.brandLogo}>
                  <Image src="/assets/logo/fuze-logo.svg" alt="" width={210} height={36} />
                </div>
              </Link>
            </div>
            <div className={style.footerLinks}>
              <div className={style.footerContainer}>
                {footerLinks.map(item => (
                  <div key={item.id} className={style.linksColumn}>
                    <div className={style.link}>
                      <Link href={item.route} legacyBehavior>
                        {item.title}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={style.socialMediaLinks}>
              <SocialMediaIcons />
            </div>
            <div className={style.bvrlaLogoHolder}>
              <Image
                src="/assets/images/logo-bvrla.png"
                alt="Logo of BVRLA"
                width={153}
                height={63}
              />
            </div>
          </div>
          <div className={style.right}>
            <p className={style.promotionText}>Experience freedom with a Drive Fuze subscription</p>
            <div className={style.browseVehicleBtn}>
              <Button onClick={() => handleBrowseCta()} white>
                Browse vehicles
              </Button>
            </div>
          </div>
        </div>
        <p className={style.copyright}>
          Copyright {new Date().getFullYear() > 1111 && new Date().getFullYear()} Drive Fuze
          Limited. Drive Fuze is a registered trademark of Drive Fuze Ltd. All rights reserved.
          Drive Fuze Limited, Monometer House, Rectory Grove, Leigh On Sea, Essex, England, SS9 2HL.
          Company Registration Number 13840019. Drive Fuze Limited are Authorised and Regulated by
          the Financial Conduct Authority.
        </p>
      </div>
    </footer>
  );
}
