const ACTION_TYPE = {
  SWITCH_RETURN_TAB: 'SWITCH_RETURN_TAB',
  INITIALIZE_RETURN: 'INITIALIZE_RETURN',
  SAVE_COLLECTION_DATE: 'SAVE_COLLECTION_DATE',
  UPDATE_RETURN_PRICING_DATA: 'UPDATE_RETURN_PRICING_DATA',
  FOURTEEN_DAYS_LOGIC: 'FOURTEEN_DAYS_LOGIC',
  // ON_TAB_COMPLETED: 'ON_TAB_COMPLETED',
};

export default ACTION_TYPE;
