/* eslint-disable no-plusplus */
import React from 'react';
import Files from 'react-files';
import classNames from 'classnames/bind';
import LinkIcon from '../LinkIcon';
import style from './selectfile.module.scss';

const SelectFile = props => {
  const cx = classNames.bind(style);
  const {
    accepts = null,
    clickable = true,
    customClass = '',
    dropzoneClass = '',
    disclaimer = '',
    error = '',
    handleOnChange,
    handleError,
    multiple = false,
    maxFileSize,
    minFileSize = 0,
    maxFiles,
    isDisabled,
    placeholder = { icon: '', text: '', dnd: true, subtext: '' },
  } = props;

  const onSelectFile = async data => {
    const imageData = new FormData();
    if (data?.length > 0) {
      for (let i = 0; i < data.length; i++) {
        imageData.append('files', data[i]);
      }
      handleOnChange(imageData);
    }
  };

  const onFileError = err => {
    if (err.code === 1) {
      handleError('Invalid file type');
    } else if (err.code === 2) {
      handleError(`Max upload file size is ${maxFileSize / 1000 ** 2} MB`);
    } else if (err.code === 3) {
      handleError(`Min file upload size is ${minFileSize / 1000 ** 2} MB`);
    } else if (err.code === 4) {
      handleError(`Max ${maxFiles} can be uploaded`);
    } else {
      handleError('');
    }
  };

  return (
    <div className={cx(style['input-wrapper'], { disabled: isDisabled }, customClass)}>
      <Files
        className={cx(style['files-dropzone'], dropzoneClass)}
        dragActiveClassName={style['files-dropzone-active']}
        maxFiles={maxFiles}
        multiple={multiple}
        maxFileSize={maxFileSize}
        minFileSize={minFileSize}
        accepts={accepts}
        clickable={clickable}
        onChange={onSelectFile}
        onError={onFileError}
      >
        {placeholder.text && (
          <div
            style={{ maxWidth: '243px', display: 'flex', flexDirection: 'column', gap: '12.5px' }}
          >
            {placeholder.dnd && <h5>Upload or drag and drop an image.</h5>}
            <div className={style.upload}>
              <LinkIcon icon={placeholder.icon} iconside="left" customClass={style.upload}>
                {placeholder.text}
              </LinkIcon>
            </div>
            <h5 className={style.subtext}>{placeholder.subtext}</h5>
          </div>
        )}
      </Files>
      {disclaimer && <p className={style['input-disclaimer']}>{disclaimer}</p>}
      {error && <p className={style['validation-error']}>{error}</p>}
    </div>
  );
};

export default SelectFile;
