const ADD_DRIVER_ACTION_TYPE = {
  GET_ADD_DRIVER_PRICE_INFO: 'GET_ADD_DRIVER_PRICE_INFO',
  SWITCH_TAB_ADD_DRIVER: 'SWITCH_TAB_ADD_DRIVER',
  RESET_INITIAL_STATE: 'RESET_INITIAL_STATE',
  ADD_ADDITIONAL_DRIVER_ID: 'ADD_ADDITIONAL_DRIVER_ID',
  ADDITIONAL_DRIVER_DATE: 'ADDITIONAL_DRIVER_DATE',
  REMOVE_DRIVER_ID_DATE: 'REMOVE_DRIVER_ID_DATE',
  AGREEMENT_CHECK: 'AGREEMENT_CHECK',
};

export default ADD_DRIVER_ACTION_TYPE;
