import { toast } from 'react-toastify';
import Icon from '../Icon';
import ToastBody from './ToastBody';

export const toastSuccess = ({ title, message, ctaLink, onClick }) => {
  return toast.success(
    <ToastBody {...{ title: title || 'Success', message, ctaLink, onClick }} />,
    {
      icon: <Icon icon="approve" size={24} color="#74F2A6" />,
      toastId: `toastId-${message}`,
    },
  );
};

export const toastError = ({ title, message, ctaLink, onClick }) => {
  return toast.error(
    <ToastBody {...{ title: title || 'Something went wrong', message, ctaLink, onClick }} />,
    {
      icon: <Icon icon="close" size={24} color="#FF6969" />,
      toastId: `toastId-${message}`,
    },
  );
};

export const toastWarning = ({ title, message, ctaLink, onClick }) => {
  return toast.warning(
    <ToastBody {...{ title: title || 'Warning', message, ctaLink, onClick }} />,
    {
      icon: <Icon icon="alert" size={24} color="#517E8D" />,
      toastId: `toastId-${message}`,
    },
  );
};

export const toastInfo = ({ title, message, ctaLink, onClick }) => {
  return toast.info(
    <ToastBody {...{ title: title || 'Information', message, ctaLink, onClick }} />,
    {
      icon: <Icon icon="information" size={24} color="#ACC1C8" />,
      toastId: `toastId-${message}`,
    },
  );
};
