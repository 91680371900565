import { useState } from 'react';
import cx from 'classnames';
import Icon from '@/components/general/Icon';
import style from './pre-order-form.module.scss';

const RenderTable3PreOrder = props => {
  const { isMobile, mileage } = props;
  const [activeIndex, setActiveIndex] = useState(-1);
  const handleClick = index => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };
  const tableData = [
    {
      chargesValue: 'Losses on breach',
      fixedOrVarValue: 'Variable',
      totalChargeValue: (
        <p className={style.tableText}>
          Any reasonably incurred, foreseeable losses, costs and charges resulting from the breach
          by the Driver of this Agreement (such losses being foreseeable where they are contemplated
          by Drive Fuze and the Driver at the time this Agreement is entered into including for the
          avoidance of doubt all charges in relation to repossession of the Vehicle including third
          party agents and legal proceedings).
        </p>
      ),
    },
    {
      chargesValue: 'Smoking Fee',
      fixedOrVarValue: (
        <>
          <p>Fixed</p>
          <p>£500</p>
        </>
      ),
      totalChargeValue: (
        <>
          <p className={style.tableText}>
            If the Driver and/or any Named Driver(s) or any person smoke (for the avoidance of doubt
            this includes the use of electronic smoking devices) inside the Vehicle in contravention
            of this Agreement, the Driver shall be liable to pay Drive Fuze a fixed sum as
            compensation of £500.
          </p>
          <p className={cx(style.tableText, style['mt-16'])}>
            Please note additional charges from damage caused by smoking (burn in a seat for
            example) will also be incurred in addition to the £500 charge.
          </p>
        </>
      ),
    },
    {
      chargesValue: 'Excess Mileage Charge',
      fixedOrVarValue: 'Variable',
      totalChargeValue: (
        <>
          <p className={style.tableText}>
            This is a fee the Driver must pay if the Driver exceeds the Mileage Allowance for the
            Vehicle. The Driver agrees to the following Mileage Allowance, and to pay the Excess
            Mileage Charge for each mile in excess of the Mileage Allowance upon return of the
            Vehicle to Drive Fuze:
          </p>
          <p className={cx(style.tableText, style['mt-16'])}>
            Mileage Allowance [per Month]: {mileage}
          </p>
          <p className={style.tableText}>Miles Excess Mileage Charge: £0.35 per mile</p>
        </>
      ),
    },
    {
      chargesValue: 'Servicing rescheduling costs',
      fixedOrVarValue: 'Variable',
      totalChargeValue: (
        <p className={style.tableText}>
          It is the Driver&apos;s responsibility to keep servicing or maintenance appointments
          arranged with Drive Fuze in accordance with this Agreement. The Driver will pay any
          rescheduling costs if an appointment is missed.
        </p>
      ),
    },
    {
      chargesValue: 'Overdue Fee',
      fixedOrVarValue: 'Variable',
      totalChargeValue: (
        <p className={style.tableText}>
          If the Driver fails to return the Vehicle to Drive Fuze by the last day of the
          Subscription Term or as required on earlier termination, the Driver may also be liable for
          additional charges in relation to the period following such failure until return of the
          Vehicle to Drive Fuze.
        </p>
      ),
    },
    {
      chargesValue: 'Refuelling Charge',
      fixedOrVarValue: 'Variable',
      totalChargeValue: (
        <p className={style.tableText}>
          The cost of any shortfall in fuel in the Vehicle when it is returned to Drive Fuze and any
          additional collection charges incurred including but not limited to waiting charges or
          vehicle transporter charges.
        </p>
      ),
    },
    {
      chargesValue: 'Key Replacement Charge',
      fixedOrVarValue: 'Variable',
      totalChargeValue: (
        <p className={style.tableText}>
          The replacement cost of any lost keys on return of the Vehicle to Drive Fuze.
        </p>
      ),
    },
    {
      chargesValue: 'Charges and Fines',
      fixedOrVarValue: 'Variable',
      totalChargeValue: (
        <p className={style.tableText}>
          All charges, fines, road traffic offences, speeding offences, tolls, congestion charges,
          parking or towing away or clamping fees and any other such charges including a £50
          administration fee for Drive Fuze&apos;s time in dealing with such Fine or Toll.
        </p>
      ),
    },
    {
      chargesValue: 'Total Loss',
      fixedOrVarValue: 'Variable',
      totalChargeValue: (
        <p className={style.tableText}>
          If the Vehicle is a Total Loss the Driver will have to make up any shortfall between the
          insurance monies received and the amount the Driver is due to pay Drive Fuze under this
          Agreement.
        </p>
      ),
    },
  ];

  return (
    <div className={style.tableType_2}>
      <table className={style.tableHeader}>
        <tr>
          <th>Charges</th>
          <th>Fixed or Variable</th>
          {!isMobile && <th>Total Charge</th>}
        </tr>
      </table>
      {tableData.map((item, index) => (
        <table key={index} className={style.tableBlock}>
          <tr>
            <th>{item.chargesValue}</th>
            <th>{item.fixedOrVarValue}</th>
            {!isMobile ? (
              <th>{item.totalChargeValue}</th>
            ) : (
              <th onClick={() => handleClick(index)}>
                <Icon
                  icon={'cancel-big'}
                  size={20}
                  color="#001E78"
                  style={{
                    rotate: index !== activeIndex ? '135deg' : '',
                    transition: 'ease 0.35s',
                  }}
                />
              </th>
            )}
          </tr>
          {index === activeIndex && (
            <tr className={style.accordion}>
              <td colSpan={3}>{item.totalChargeValue}</td>
            </tr>
          )}
        </table>
      ))}
    </div>
  );
};

export default RenderTable3PreOrder;
