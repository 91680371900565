import style from './label-pill.module.scss';

const LabelPill = ({ label, fontSize = 16, background = '#E7EAFF', padding = '5px 10px' }) => {
  return (
    <div
      className={style.labelContainer}
      style={{ fontSize: `${fontSize}px`, background, padding }}
    >
      {label}
    </div>
  );
};

export default LabelPill;
