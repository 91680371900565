import { format } from 'date-fns';

// Email validation
export const validateEmail = email => {
  return email.match(
    // eslint-disable-next-line no-useless-escape
    /^\w+([\.\/+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  );
};

export const validNumber = /^[+0-9 ]{17}$/; // 15 digits = 10 digit number + 2 spaces in between + 1 "+" symbol + 4 country code

export const convertDate = inputFormat => {
  const d = new Date(inputFormat);
  function pad(s) {
    return s < 10 ? `0${s}` : s;
  }

  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
};

export const sortDateAsc = (date1, date2) => {
  if (date1 > date2) return 1;
  if (date1 < date2) return -1;
  return 0;
};

// Password validation
export const validatePassword = password => {
  const pwdRegex =
    // eslint-disable-next-line no-useless-escape
    /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,{}\(\)|\\":<>\?\.\_])[A-Za-z\d~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,{}\(\)|\\":<>\?\.\_]{8,}/;
  const spacesRegex = /\s/;

  if (spacesRegex.test(password)) {
    return 'Invalid use of spaces in password';
  }
  if (pwdRegex.test(password)) {
    return true;
  }
  return false;
};

export const capitalizeFirstLetter = string => {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};

export const onlyLettersNumbersSpaces = str => {
  return /^[a-zA-Z\s]*$/g.test(str);
};

export const onlyNumbersDecimal = str => {
  return /^[0-9]+([.][0-9]+)?$/.test(str);
};

export const replaceKey = (newKey, arr) => {
  return arr.map(s => ({ [newKey]: s.id }));
};

export const isNumber = value => {
  const reg = /^[0-9]+$/;
  return reg.test(value);
};

export const getFormattedDate = (d, dateFormat) => {
  const date = new Date(d);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  if (dateFormat === 'MM-DD-YYYY') return `${month}-${day}-${year}`;
  return `${day}-${month}-${year}`;
};

export const replaceAllDashesWithSlash = string => {
  return string?.replace(/-/g, '/');
};

export const convertToJsValidDateformat = date => {
  if (date) {
    // date must be in format dd-mm-yyyy or dd/mm/yyyy
    const splitDate = date.includes('-') ? date.split('-') : date.split('/');

    if (splitDate[2]?.length !== 4) {
      // if year is not the last element of the date string, it will return null
      return null;
    }

    // converting to yyyy-mm-dd
    const dateReverse = splitDate.reverse();
    return dateReverse.join('-');
  }
  return null;
};

export const convertToJsFormat = date => {
  // date must be in format dd-mm-yyyy or dd/mm/yyyy
  let dateArray;

  if (date.includes('/')) {
    dateArray = date.split('/');
  } else {
    dateArray = date.split('-');
  }

  return `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`;
};

export const convertToJsFormatWithDash = date => {
  // date must be in format dd-mm-yyyy or dd/mm/yyyy
  let dateArray;

  if (date.includes('/')) {
    dateArray = date.split('/');
  } else {
    dateArray = date.split('-');
  }

  return `${dateArray[1]}-${dateArray[0]}-${dateArray[2]}`;
};

export const convertToMonthNameFormat = date => {
  // date must be in format dd/mm/yyyy
  const dateArray = date.split('/');
  const newFormat = `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`;
  const monthName = format(new Date(newFormat), 'LLLL');
  return `${dateArray[0]}th ${monthName} ${dateArray[2]}`;
};

export const getStringDate = date => {
  // this function will return [dd]th [month] format "21st November"
  const tmpDate = new Date(date);
  return `${format(tmpDate, 'do')} ${format(tmpDate, 'LLLL')}`;
};

export const validateAge = date => {
  const currentDate = new Date();
  const birthDate = new Date(date);

  const maxAge = new Date();
  maxAge.setFullYear(birthDate.getFullYear() + 74, birthDate.getMonth() + 6);

  const minAge = new Date();
  minAge.setFullYear(birthDate.getFullYear() + 25);

  if (currentDate >= maxAge) return '>75';
  if (currentDate < minAge) return '<25';

  return currentDate >= minAge && currentDate <= maxAge;
};

export const setDashIfEmpty = value => {
  return value || '-';
};

export const isColorAvailable = (availableColors, colorName) => {
  return String(availableColors)
    ?.toLocaleLowerCase()
    .includes(String(colorName).toLocaleLowerCase());
};

export const parseAddress = ({ flat, address1, address2, country, postcode, city, county }) => {
  return (
    <>
      {flat && (
        <p>
          <span>{flat} </span>
        </p>
      )}
      {address1 && <p>{address1}</p>}
      {address2 && <p>{address2}</p>}
      {city && <p>{city}</p>}
      {postcode && <p>{postcode}</p>}
      {county && <p>{county}</p>}
      {country && <p>{country}</p>}
    </>
  );
};

export const convertToDashCase = str => {
  if (typeof str === 'string') return str.toLowerCase().split(' ').join('-');
  return str;
};

export const validateMultipleZeroes = (value, actualValue) => {
  const reg = /^(?!0\d)\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
  return (
    reg.test(value) ||
    (Number(value) > 0 && value?.toString()?.charAt(0) > 0) ||
    (Number(actualValue) === 0 && Number(value) > 0)
  );
};

export const validateMultipleZeroesAndInteger = (value, actualValue) => {
  const reg = /^(?!0\d)\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
  return (
    ((reg.test(value) ||
      (Number(value) > 0 && value?.toString()?.charAt(0) > 0) ||
      (Number(actualValue) === 0 && Number(value) > 0)) &&
      parseInt(value, 10) === Number(value) &&
      !value?.toString()?.includes('.')) ||
    value === ''
  );
};

export const getMonthName = monthId => {
  const monthData = [
    { id: '01', value: 'Jan' },
    { id: '02', value: 'Feb' },
    { id: '03', value: 'Mar' },
    { id: '04', value: 'Apr' },
    { id: '05', value: 'May' },
    { id: '06', value: 'Jun' },
    { id: '07', value: 'Jul' },
    { id: '08', value: 'Aug' },
    { id: '09', value: 'Sep' },
    { id: '10', value: 'Oct' },
    { id: '11', value: 'Nov' },
    { id: '12', value: 'Dec' },
  ];
  const name = monthData.find(x => x?.id === monthId);
  return name?.value;
};

export const subtractMonths = (d, months) => {
  const date = new Date(d);

  date.setMonth(date.getMonth() - months);
  return date;
};

export const addMonths = (d, months) => {
  const date = new Date(d);
  date.setMonth(date.getMonth() + months);
  return date;
};
