import { useEffect, useState } from 'react';
import Icon from '@/components/general/Icon';
import style from '@/components/general/SubscriptionAgreement/agreement.module.scss';

const RenderTable3 = props => {
  const { isMobile, data, handleClickScroll } = props;
  const [activeIndex, setActiveIndex] = useState(-1);
  const [listData, setListData] = useState([]);
  const handleClick = index => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  const dataArray = [
    {
      value1: 'Losses on breach',
      value2: 'Variable',
      value3: `  Any reasonably incurred, foreseeable losses, costs and charges resulting from the breach
  by the Driver of this Agreement (such losses being foreseeable where they are
  contemplated by Drive Fuze and the Driver at the time this Agreement is entered into
  including for the avoidance of doubt all charges in relation tore possession of the
  Vehicle including third party agents and legal proceedings).`,
      link: `See condition 6.2.4 onwards.`,
      id: '624',
    },
    {
      value1: 'Smoking Fee',
      value2: 'Fixed',
      value2_1: '£500',
      value3: `If the Driver and/or any Named Driver(s) or any person smoke(for the avoidance of doubt
        this includes the use of electronic smoking devices) inside the Vehicle in contravention
        of this Agreement, the Driver shall be liable to pay Drive Fuze a fixed sum as
        compensation of £500.`,
      link: ` See condition 6.6`,
      subText: `Please note additional charges from damage caused by smoking(burn in a seat for
        example) will also be incurred in addition to the £500 charge.`,
      id: '65',
    },
    {
      value1: 'Excess Mileage Charge',
      value2: 'Variable',
      value3: `This is a fee the Driver must pay if the Driver exceeds the Mileage Allowance for the
      Vehicle. The Driver agrees to the following Mileage Allowance, and to pay the Excess
      Mileage Charge for each mile in excess of the Mileage Allowance upon return of the
      Vehicle to Drive Fuze:`,
      value4: ` Mileage Allowance [per Month]:${data?.metadata?.mileagePackage}`,
      value5: `Miles Excess Mileage Charge: £0.59 per mile`,
      link: `See condition 2.10`,
      id: '29',
    },
    {
      value1: 'Servicing rescheduling costs',
      value2: 'Variable',
      value3: ` It is the Driver's responsibility to keep servicing or maintenance appointments
      arranged with Drive Fuze in accordance with this Agreement. The Driver will pay any
      rescheduling costs if an appointment is missed.`,
      link: `See Condition 9.1`,
      id: '91',
    },
    {
      value1: 'Overdue Fee',
      value2: 'Variable',
      value3: ` If the Driver fails to return the Vehicle to Drive Fuze by the last day of the
      Subscription Term or as required on earlier termination, the Driver may also be liable
      for additional charges in relation to the period following such failure until return of
      the Vehicle to Drive Fuze.`,
      link: `See Condition 11.9`,
      id: '119',
    },
    {
      value1: 'Refuelling Charge',
      value2: 'Variable',
      value3: ` If the Driver fails to return the Vehicle to Drive Fuze by the last day of the
      Subscription Term or as required on earlier termination, the Driver may also be liable
      for additional charges in relation to the period following such failure until return of
      the Vehicle to Drive Fuze.`,
      link: ` See Condition 2.1`,
      id: '21',
    },
    {
      value1: 'Key Replacement Charge',
      value2: 'Variable',
      value3: `The replacement cost of any lost keys on return of the Vehicle to Drive Fuze.`,
      link: ` See Condition 2.1`,
      id: '21',
    },
    {
      value1: 'Charges and Fines',
      value2: 'Variable',
      value3: ` All charges, fines, road traffic offences, speeding offences, tolls, congestion charges,
      parking or towing away or clamping fees and any other such charges including a £50
      administration fee for Drive Fuze's time in dealing with such Fine or Toll.`,
      link: `See conditions 6.2.2 and 6.2.3`,
      id: '622',
    },
    {
      value1: 'Total Loss',
      value2: 'Variable',
      value3: `If the Vehicle is a Total Loss the Driver will have to make up any short fall between
      the insurance monies received and the amount the Driver is due to pay Drive Fuze under
      this Agreement.`,
      link: `See condition 8.8`,
      id: '88',
    },
    {
      value1: 'Failed Payment',
      value2: 'Variable',
      value3: `If the Driver fails to make a Subscription Fee payment or any other payment for monies due under the terms of this Agreement, the Driver will be charged a late payment charge of £25`,
      link: `See Condition 6.10`,
      id: '610',
    },
    {
      value1: 'Valet',
      value2: 'Variable',
      value3: `If the Driver fails to return the Vehicle in a condition which prohibits a detailed inspection upon collection, a Valet will be applied to the Vehicle to enable a full BVRLA inspection and costs incurred will be charged to the Driver.`,
      link: `See Condition 2.7`,
      id: '27',
    },
  ];

  useEffect(() => {
    if (data?.version === 'v3') {
      dataArray.unshift({
        value1: 'Collection and Delivery',
        value2: 'Variable',
        value3:
          'Any vehicle collection or delivery charges, including waiting time. Charges will vary dependant on the date of collection, delivery and tenure of subscription.',
        id: '89',
      });
      setListData(dataArray);
    } else {
      setListData(dataArray);
    }
  }, [data?.version]);

  return (
    <div className={style.tableType_2}>
      <table className={style.tableHeader}>
        <tr>
          <th>Charges</th>
          <th>Fixed or Variable</th>
          {!isMobile && <th>Total Charge</th>}
        </tr>
      </table>
      {listData.map((item, index) => (
        <table key={index} className={style.tableBlock}>
          <tr>
            <th>{item.value1}</th>
            <th>
              {item.value2}

              {item.value2_1 && (
                <>
                  <br />
                  <br />
                  {item.value2_1}
                </>
              )}
            </th>
            {!isMobile ? (
              <th>
                {item.value3}
                {item.value4 && (
                  <>
                    <br />
                    <br />
                    {item.value4}
                  </>
                )}
                {item.value5 && (
                  <>
                    <br />
                    {item.value5}
                  </>
                )}
                <br />
                <br />
                <a onClick={() => handleClickScroll(item.id)} className={style.sectionLink}>
                  {' '}
                  {item.link}
                </a>
                {item.subText && (
                  <>
                    <br />
                    <br />
                    <p className={style.subText}>{item.subText}</p>
                  </>
                )}
              </th>
            ) : (
              <th onClick={() => handleClick(index)}>
                <Icon icon={'cancel-big'} size={20} color="#001E78" />
              </th>
            )}
          </tr>
          {index === activeIndex && (
            <tr className={style.accordion}>
              <td colSpan={3}>
                {item.value3}
                {item.value4 && (
                  <>
                    <br />
                    <br />
                    {item.value4}
                  </>
                )}
                {item.value5 && (
                  <>
                    <br />
                    {item.value5}
                  </>
                )}
                <br />
                <br />
                <a onClick={() => handleClickScroll(item.id)} className={style.sectionLink}>
                  {' '}
                  {item.link}
                </a>
                {item.subText && (
                  <>
                    <br />
                    <br />
                    <p className={style.subText}>{item.subText}</p>
                  </>
                )}
              </td>
            </tr>
          )}
        </table>
      ))}
    </div>
  );
};

export default RenderTable3;
