const ACTION_TYPE = {
  LISTING_ID: 'LISTING_ID',
  ON_CHANGE: 'ON_CHANGE',
  ON_CHANGE_ABOUT_YOU_STATUS: 'ON_CHANGE_ABOUT_YOU_STATUS',
  ON_CHANGE_DRIVER: 'ON_CHANGE_DRIVER',
  ON_CHANGE_DOCUMENTS_MAIN_DRIVER: 'ON_CHANGE_DOCUMENTS_MAIN_DRIVER',
  ON_CHANGE_DOCUMENTS_EXTRA_DRIVER: 'ON_CHANGE_DOCUMENTS_EXTRA_DRIVER',
  ON_BLUR: 'ON_BLUR',
  ON_FOCUS: 'ON_FOCUS',
  AUTOFILL: 'AUTOFILL',
  INITIALIZE: 'INITIALIZE',
  INITIALIZE_DRIVER: 'INITIALIZE_DRIVER',
  REMOVER_DRIVER: 'REMOVE_DRIVER',
  SWITCH_TAB: 'SWITCH_TAB',
  SAVE_DELIVERY_DATE: 'SAVE_DELIVERY_DATE',
  ADDITIONAL_SERVICES: 'ADDITIONAL_SERVICES',
  ADDITIONAL_DRIVER: 'ADDITIONAL_DRIVER',
  UPDATE_PRICING_LIST: 'UPDATE_PRICING_LIST',
  ONFIDO_TOKEN: 'ONFIDO_TOKEN',
  ONFIDO_GENERATE_CHECK: 'ONFIDO_GENERATE_CHECK',
  ON_TAB_COMPLETED: 'ON_TAB_COMPLETED',
  LISTING_COLOR_ID: 'LISTING_COLOR_ID',
  ON_AGREEMENT: 'ON_AGREEMENT',
  ON_GET_PRICING: 'ON_GET_PRICING',
  RESET: 'RESET',
  ON_GET_OVERVIEW_DETAILS: 'ON_GET_OVERVIEW_DETAILS',
  ON_CHANGE_EXTRA_DRIVER: 'ON_CHANGE_EXTRA_DRIVER',
  RESET_DOCUMENTS_MAIN_DRIVER: 'RESET_DOCUMENTS_MAIN_DRIVER',
  RESET_DOCUMENTS_EXTRA_DRIVER: 'RESET_DOCUMENTS_EXTRA_DRIVER',
  APPLY_PROMOTION: 'APPLY_PROMOTION',
  RESET_APPLY_PROMOTION: 'RESET_APPLY_PROMOTION',
  APPLY_PROMOTION_DISCOUNT_AMOUNT: 'APPLY_PROMOTION_DISCOUNT_AMOUNT',
  APPLY_PROMOTION_SUBMIT_COMPLETE: 'APPLY_PROMOTION_SUBMIT_COMPLETE',
  APPLY_PROMOTION_SWAP_VEHICLE: 'APPLY_PROMOTION_SWAP_VEHICLE',
  TRIGGER_GA_COMPLETE_ONBOARDING_EVENT: 'TRIGGER_GA_COMPLETE_ONBOARDING_EVENT',
  CONTRACT_LENGTH: 'CONTRACT_LENGTH',
};

export default ACTION_TYPE;
