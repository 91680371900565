import React from 'react';
import cx from 'classnames';
import style from './checkbox.module.scss';

/**
 * type i.e fill or hollow
 * size i.e size of the checkbox
 * color i.e color of the checked state
 */
function Checkbox(props) {
  const {
    id,
    checked,
    onChange,
    className,
    name,
    fillColor = 'grey_check',
    otherAttributes,
    label,
    error,
    lightTheme,
  } = props;
  return (
    <div>
      <div
        className={cx(style['checkbox-wrapper'], lightTheme && style.lightTheme, className)}
        onClick={onChange}
      >
        <input
          id={id}
          name={name}
          // className={cx(style['input-checkbox'], fillColor)}
          type="checkbox"
          checked={checked}
          // onChange={onChange}
          {...otherAttributes}
        />
        <span
          className={cx(style['span-checkbox'], lightTheme && style.lightTheme, fillColor)}
        ></span>
        {label && <label>{label}</label>}
      </div>
      <div className={cx(style['error-msg'], { [style.active]: !!error })}>{error}</div>
    </div>
  );
}

export default Checkbox;
