/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import style from './skeletonVechileItem.module.scss';

const SkeletonVehicleItem = () => {
  return (
    <>
      {Array(9)
        .fill()
        .map((el, index) => (
          <div key={index} className={style.item}>
            <SkeletonTheme baseColor="#E7EAFF" highlightColor="#FFF">
              <div className={style.wrapper}>
                <div className={style.tagsOnTop}>
                  <Skeleton borderRadius={24} width={72} height={24} />
                  <Skeleton borderRadius={24} width={72} height={24} />
                </div>
                <div className={style.thumbnail}>
                  <Skeleton
                    borderRadius={16}
                    width={'100%'}
                    height={150}
                    baseColor="#DDE3F9"
                    highlightColor="#FFF"
                  />
                </div>
                <div className={style.content}>
                  <div className={style.innerSection}>
                    <Skeleton borderRadius={4} width={'100%'} height={24} />
                    <Skeleton borderRadius={4} width={'calc(100% - 40px)'} height={24} />
                  </div>
                  <div>
                    <Skeleton borderRadius={4} width={'calc(100% - 40px)'} height={14} count={3} />
                    <Skeleton borderRadius={4} width={'calc(100% - 80px)'} height={14} />
                  </div>
                </div>
              </div>
            </SkeletonTheme>
            <SkeletonTheme highlightColor="#E7EAFF" baseColor="#FFF">
              <div className={style.footerContainer}>
                <Skeleton borderRadius={8} width={132} height={14} />
              </div>
            </SkeletonTheme>
          </div>
        ))}
    </>
  );
};

export default SkeletonVehicleItem;
