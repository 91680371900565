/* eslint-disable no-param-reassign */
/* eslint-disable default-param-last */
import ROUTE_NAME from '@/helpers/url-constants';
import LOGIN_TYPE from './action-type';

const initialState = {
  user: {},
  signUp: {
    redirect: false,
    route: ROUTE_NAME.SIGN_UP,
    render: '',
    data: null,
  },
  tempUserData: {},
  loginStatus: null,
  status: false,
  loginCheckDone: false,
  message: '',
  featureVehicle: null,
  featureVehicleList: [],
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_TYPE.LOGIN: {
      if (action?.payload?.data?.status) {
        state = {
          ...state,
          user: action.payload.data.data,
          signUp: {
            redirect: false,
            route: ROUTE_NAME.SIGN_UP,
            render: '',
            data: null,
          },
          tempUserData: {},
          loginStatus: true,
          status: action?.payload?.data?.status,
          message: action?.payload?.data?.message,
        };
      } else {
        state = {
          ...state,
          user: action?.payload,
          signUp: {
            redirect: false,
            route: ROUTE_NAME.SIGN_UP,
            render: '',
            data: null,
          },
          tempUserData: {},
          loginStatus: false,
          status: action?.payload?.data?.status,
          message: action?.payload?.data?.message,
        };
      }
      return state;
    }
    case LOGIN_TYPE.LOGIN_PENDING_REGISTRATION: {
      if (action?.payload?.data?.status) {
        state = {
          ...state,
          signUp: {
            redirect: true,
            route: action?.payload?.route,
            render: action?.payload?.render,
            data: action?.payload?.data?.data,
          },
        };
      }
      return state;
    }
    case LOGIN_TYPE.VERIFY_OTP: {
      if (action?.payload?.data?.status) {
        state = {
          ...state,
          tempUserData: action.payload.data.data,
          loginStatus: false,
          status: action?.payload?.data?.status,
          message: action?.payload?.data?.message,
        };
      }
      return state;
    }
    case LOGIN_TYPE.LOGIN_WITH_OTP: {
      if (action?.payload?.data?.status) {
        state = {
          ...state,
          user: action?.payload?.data?.data,
          tempUserData: {},
          loginStatus: true,
          status: action?.payload?.data?.status,
          message: action?.payload?.data?.message,
        };
      } else {
        state = {
          ...state,
          user: action?.payload,
          tempUserData: {},
          loginStatus: false,
          status: action?.payload?.data?.status,
          message: action?.payload?.data?.message,
        };
      }
      return state;
    }
    case LOGIN_TYPE.USER_DETAILS:
      state = {
        ...state,
        user: {
          ...state.user,
          ...action.payload.data,
        },
      };
      return state;
    case LOGIN_TYPE.TEMP_USER_DATA: {
      if (action.payload.status) {
        state = {
          ...state,
          tempUserData: action?.payload?.data,
          signUp: {
            data: action?.payload?.data,
          },
          loginStatus: false,
          status: action?.payload?.data?.status,
          message: action?.payload?.data?.message,
        };
      }

      return state;
    }
    case LOGIN_TYPE.LOGIN_WITH_COOKIE_SUCCESS:
      state = {
        ...state,
        user: action.payload.data,
        loginStatus: true,
      };
      return state;
    case LOGIN_TYPE.LOGIN_WITH_COOKIE_FAILURE:
      state = {
        ...state,
        user: { loginStatus: false },
        loginStatus: false,
      };
      return state;
    case LOGIN_TYPE.LOGIN_CHECK:
      state = {
        ...state,
        loginCheckDone: action.payload,
      };
      return state;
    case LOGIN_TYPE.CLEAR_SIGN_UP_FORM:
      state = {
        ...state,
        tempUserData: {},
        signUp: {
          redirect: false,
          route: ROUTE_NAME.SIGN_UP,
          render: '',
          data: null,
        },
      };
      return state;
    case LOGIN_TYPE.FEATURE_VEHICLE:
      state = {
        ...state,
        featureVehicle: action.payload,
      };
      return state;
    case LOGIN_TYPE.FEATURE_VEHICLE_LIST:
      state = {
        ...state,
        featureVehicleList: action.payload,
      };
      return state;
    case LOGIN_TYPE.TOKEN:
      state = {
        ...state,
        GLOBAL_TOKEN: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default loginReducer;
