/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lonely-if */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, Slide } from 'react-toastify';
import cx from 'classnames';
import { Player } from '@lottiefiles/react-lottie-player';
import { userDetails, loginWithCookie } from '@/flow/Login/action';
import ROUTE_NAME from '@/helpers/url-constants';
import { load } from '@/helpers/cookies';
import CONFIG from '@/helpers/config';
import checkAppVersion from '@/helpers/checkAppVersion';
import Header from '../Header';
import Footer from '../Footer';
import CloseToastBtn from '../general/ToastNotifications/CloseToastBtn';
import CookieConsent from '../CookieConsent';
import style from './layout.module.scss';

export default function Layout({ children }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { loginStatus, loginCheckDone, user } = useSelector(state => state?.loginReducer);
  const { loading } = useSelector(state => state?.loadingReducer);

  // const { slug } = router.query;

  const checkRoute = () => {
    return [
      ROUTE_NAME.LOGIN,
      ROUTE_NAME.FORGOT_PASSWORD,
      ROUTE_NAME.SIGN_UP,
      `${ROUTE_NAME.RESET_PASSWORD}/[slug]`,
      ROUTE_NAME.TWO_FACTOR_AUTHENTICATE,
    ].includes(router.pathname);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      checkAppVersion();
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    dispatch(loginWithCookie());
  }, []);

  useEffect(() => {
    if (loginStatus) {
      dispatch(userDetails());
    }
  }, [loginStatus]);

  useEffect(() => {
    if (loginCheckDone) {
      if (loginStatus) {
        if (
          [
            ROUTE_NAME.LOGIN,
            ROUTE_NAME.FORGOT_PASSWORD,
            ROUTE_NAME.SIGN_UP,
            ROUTE_NAME.TWO_FACTOR_AUTHENTICATE,
            `${ROUTE_NAME.RESET_PASSWORD}/[slug]`,
          ].includes(router.pathname)
        ) {
          const booking = load(CONFIG.COOKIE_BOOKING);
          // TODO: Removing this for now as we don't need it. We might need this in future.
          // if (booking?.vehicleId && booking.prePath) {
          //   router.push(`${ROUTE_NAME.BROWSE_VEHICLES}/${booking?.vehicleId}`);
          // } else
          if (booking?.vehicleId) {
            router.push(ROUTE_NAME.ORDER);
          } else {
            router.push(ROUTE_NAME.MY_BOOKINGS);
          }
        } else if (router.pathname === '/') {
          router.push(ROUTE_NAME.MY_BOOKINGS);
        } else if (router.isReady) {
          router.push(router.asPath);
        }
      } else {
        if (router.pathname === ROUTE_NAME.FORGOT_PASSWORD) {
          router.push(ROUTE_NAME.FORGOT_PASSWORD);
        } else if (router.pathname === ROUTE_NAME.TWO_FACTOR_AUTHENTICATE) {
          router.push(ROUTE_NAME.LOGIN);
        } else if (router.isReady) {
          router.push(router.asPath);
        }
      }
    }
  }, [loginStatus, loginCheckDone]);

  return (
    <div className="page">
      <div className={cx(style.loaderContainer, !loading && style.hideLoader)}>
        <div className={style.lottieAnimation}>
          <Player src="/lottie/brand-logo-animation.json" autoplay loop className={style.player} />
        </div>
      </div>
      <ToastContainer
        closeButton={CloseToastBtn}
        transition={Slide}
        hideProgressBar
        newestOnTop
        closeOnClick={false}
        className={style.toastContainer}
        bodyClassName={({ type }) => cx(style.toasterBody, style[type])}
      />
      {!checkRoute() && <Header loginStatus={loginStatus} user={user} />}
      <main className={style.page_main}>{children}</main>
      {!checkRoute() && <Footer />}
      <CookieConsent />
    </div>
  );
}
