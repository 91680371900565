import classNames from 'classnames/bind';
import Icon from '../Icon';
import style from './link-icon.module.scss';

function LinkIcon(props) {
  const cx = classNames.bind(style);
  const { children, icon, iconside, customClass } = props;

  return (
    <a {...props} className={cx(style.link, { secondary: props.secondary }, customClass)}>
      {icon && iconside === 'left' && <Icon icon={icon} size={20} />}
      {children}
      {icon && iconside === 'right' && <Icon icon={icon} size={20} />}
    </a>
  );
}

export default LinkIcon;
