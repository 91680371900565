import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getTooltipPosition } from '@/helpers/utils';
import useBreakpoint from '@/helpers/customHooks/useBreakpoint';
import Icon from '../Icon';
import style from './tooltip.module.scss';

const Tooltip = ({ message, iconSize, position, icon, color, element }) => {
  const targetRef = useRef();
  const tooltipRef = useRef();
  const isMobile = useBreakpoint('tablet');
  const [isVisible, setIsVisible] = useState(false);
  const [arrowPosition, setArrowPosition] = useState({});

  useEffect(() => {
    const el = targetRef.current;
    if (!el) return;

    const handleMouseEnter = () => {
      setIsVisible(true);
      const tooltip = tooltipRef.current;
      if (!tooltip) return;
      const { left, top, arrow } = getTooltipPosition(el, tooltip, position);
      tooltip.style.left = `${left}px`;
      tooltip.style.top = `${top}px`;
      setArrowPosition(arrow);
    };

    const handleMouseLeave = () => {
      setIsVisible(false);
    };
    if (isMobile) {
      el.addEventListener('touchstart', handleMouseEnter);
      el.addEventListener('touchleave', handleMouseLeave);
    }
    el.addEventListener('mouseenter', handleMouseEnter);
    el.addEventListener('mouseleave', handleMouseLeave);
    // eslint-disable-next-line consistent-return
    return () => {
      if (isMobile) {
        el.removeEventListener('touchstart', handleMouseEnter);
        el.removeEventListener('touchleave', handleMouseLeave);
      }
      el.removeEventListener('mouseenter', handleMouseEnter);
      el.removeEventListener('mouseleave', handleMouseLeave);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, isVisible]);

  useEffect(() => {
    function onScroll() {
      if (isVisible && tooltipRef.current) {
        setIsVisible(false);
      }
    }
    if (isMobile) {
      window.addEventListener('scroll', onScroll);
    }
    window.addEventListener('wheel', onScroll);
    return function unMount() {
      window.removeEventListener('wheel', onScroll);
      window.removeEventListener('scroll', onScroll);
    };
  });

  return (
    <>
      {icon && (
        <div ref={targetRef} className={style['info-tooltip-wrp']}>
          <Icon icon={icon} width={iconSize} color={color} />
        </div>
      )}
      {element && <div ref={targetRef}>{element}</div>}
      {createPortal(
        <div
          ref={tooltipRef}
          className={cx(
            { [style.isVisible]: isVisible },
            style['info-tooltip'],
            style.arrow,
            style[arrowPosition?.direction],
            style[arrowPosition?.place],
          )}
        >
          {message}
        </div>,
        document.getElementsByTagName('body')[0],
      )}
    </>
  );
};

Tooltip.propTypes = {
  message: PropTypes.string,
  iconSize: PropTypes.number,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  icon: PropTypes.string,
  color: PropTypes.string,
};

Tooltip.defaultProps = {
  position: 'top',
  iconSize: 24,
};

export default Tooltip;
