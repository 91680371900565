const ACTION_TYPE = {
  GET_MILEAGE_BAND_INFO: 'GET_MILEAGE_BAND_INFO',
  UPDATE_MILEAGE_BAND_INFO: 'UPDATE_MILEAGE_BAND_INFO',
  GET_PRICE_INFO: 'GET_PRICE_INFO',
  SWITCH_TAB_ADDITIONAL_SERVICES: 'SWITCH_TAB_ADDITIONAL_SERVICES',
  UPDATE_PRICING: 'UPDATE_PRICING',
  SELECTED_MILEAGE_BAND_DATA: 'SELECTED_MILEAGE_BAND_DATA',
  RESET_INITIAL_STATE: 'RESET_INITIAL_STATE',
};

export default ACTION_TYPE;
