import cx from 'classnames';
import { convertToDashCase } from '@/helpers/common';
import LoadingSpinner from '../LoadingSpinner';
import style from './button.module.scss';

function Button(props) {
  const { children, secondary, grow, disabled, white, loading, id } = props;
  const customProps = {
    ...props,
    secondary: secondary?.toString(),
    white: white?.toString(),
    disabled: loading || disabled,
    loading: loading?.toString(),
    grow: grow?.toString(),
  };

  return (
    <button
      {...customProps}
      className={cx(style.button, {
        [style.secondary]: secondary,
        [style.grow]: grow,
        [style.disabled]: loading || disabled,
        [style.white]: white,
      })}
      id={id || `${convertToDashCase(children)}-button`}
    >
      {children}
      {loading && <LoadingSpinner />}
    </button>
  );
}

export default Button;
