/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import Select, { components } from 'react-select';
import Icon from '@/components/general/Icon';
import style from './dropdown.module.scss';

const cx = classNames.bind(style);

const DropDown = props => {
  const {
    label,
    name,
    selectedValue,
    wrapperClassName = '',
    className = '',
    placeholder,
    isSearchable = false,
    isClearable = false,
    isMulti = false,
    defaultValue,
    isDisabled = false,
    onChange,
    onFocus,
    onBlur,
    options,
    menuListTitle = '',
    menusListView,
    error = '',
    requiredField = false,
    // use this prop for defining menu height instead of using CSS
    maxMenuHeight = 190,
    menuPlacement = 'auto',
    noOptionsMessage,
  } = props;

  const colourStyles = {
    control: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: 'transparent',
      borderRadius: '20px',
      border: error ? '1px solid #FF6969' : '1px solid #ACC1C8',
      borderColor: isFocused || isSelected ? '#001E78' : '#ACC1C8',
      boxShadow: 'none',
      fontWeight: 300,
      fontSize: '15px',
      '&:hover': {
        borderColor: '#001E78',
      },
    }),
    menu: styles => {
      return {
        ...styles,
        boxShadow: 'none',
        border: '1px solid #ACC1C8',
        padding: '2px 6px',
      };
    },
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        background: 'transparent',
        color: '#000000',
        fontWeight: 300,
        fontSize: '15px',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        padding: '8px 10px',
        '&:hover': {
          background: '#F4F7F8',
          borderRadius: '4px',
        },
      };
    },
    input: styles => ({
      ...styles,
    }),
  };

  const commonProps = {
    defaultValue,
    classNamePrefix: 'dd',
    value: selectedValue,
    name,
    onChange,
    onBlur,
    onFocus,
    options,
    styles: colourStyles,
    placeholder: placeholder || '',
    isSearchable,
    isClearable,
    isMulti,
    isDisabled,
    hideSelectedOptions: false,
  };

  if (noOptionsMessage) {
    commonProps.noOptionsMessage = noOptionsMessage;
  }

  const [menuOpen, setMenuOpen] = useState(undefined);

  // eslint-disable-next-line react/no-unstable-nested-components
  const DropdownIndicator = dropdownProps => {
    return (
      <components.DropdownIndicator {...dropdownProps}>
        <Icon className={style['dropdown-indicator']} icon="arrow-down" size={24} color="#1E1E1E" />
        <div className={style['dropdown-indicator']} />
      </components.DropdownIndicator>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const Menu = menuProps => {
    return (
      <components.Menu {...menuProps}>
        <div
          className={cx(style['dd__menu-wrapper'], {
            [style['g-menu-list-view-wrapper']]: menusListView,
          })}
        >
          {menuListTitle && <h5 className={style['dd__menu-title']}>{menuListTitle}</h5>}
          {menuProps.children}
        </div>
      </components.Menu>
    );
  };

  const customComponents = {
    Menu,
    DropdownIndicator,
    IndicatorSeparator: () => null,
  };

  const onMenuOpenHandler = () => {
    if (menuOpen !== undefined) setMenuOpen(undefined);
  };
  return (
    <div className={cx(wrapperClassName, { [style['disabled-wrapper']]: isDisabled })}>
      {label && (
        <label className={style.label}>
          {label}
          {requiredField && <sup>*</sup>}
        </label>
      )}
      <div id={name || label}>
        <Select
          className={cx(style['g-dropdown'], className)}
          components={customComponents}
          {...commonProps}
          menuIsOpen={menuOpen}
          onMenuOpen={onMenuOpenHandler}
          closeMenuOnSelect={!isMulti}
          maxMenuHeight={maxMenuHeight}
          menuPlacement={menuPlacement}
        />
      </div>

      <div className={cx(style['error-msg'], { active: error })}>{error}</div>
    </div>
  );
};

export default DropDown;
