import React from 'react';
import cx from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NumericFormat } from 'react-number-format';
import { validateMultipleZeroes, validateMultipleZeroesAndInteger } from '@/helpers/common';
import Tooltip from '../Tooltip';
import style from './input-number-field.module.scss';

const InputNumberField = ({
  className,
  label,
  placeholder,
  value,
  name,
  id = '',
  error,
  readOnly = false,
  requiredField = false,
  maxlength,
  autoComplete = false,
  tooltipMessage = '',
  currency = '',
  disabled = false,
  thousandSeparator = ',',
  decimalScale = 2,
  suffix = '',
  pattern,
  onChangeHandler = () => {},
  onBlurHandler = () => {},
  isAllowed = false,
}) => {
  const handleChangeValue = e => {
    e.target.value = e.target.value.replace(/,/g, '');
    onChangeHandler(e);
  };
  return (
    <div className={cx(className, style['form-group'])}>
      {label && (
        <label className={style.label} htmlFor={id || name}>
          <span>
            {label}
            {requiredField && <sup>*</sup>}
          </span>

          {tooltipMessage && (
            <Tooltip
              message={tooltipMessage}
              color={'#ACC1C8'}
              icon={'information'}
              iconSize={18}
            />
          )}
        </label>
      )}
      <div className={cx(style['input-field-wrapper'])}>
        {currency && <div className={style['currency-symbol']}>{currency}</div>}
        <NumericFormat
          className={cx(
            style['input-field'],
            { [style.errorField]: !!error },
            { [style.currency]: currency !== '' },
          )}
          id={id || name}
          placeholder={placeholder}
          value={Number(value) > 0 ? Number(value) : value}
          required={requiredField}
          onChange={handleChangeValue}
          thousandSeparator={thousandSeparator}
          name={name}
          maxLength={maxlength}
          readOnly={readOnly}
          disabled={disabled}
          onBlur={onBlurHandler}
          onWheel={e => e.target.blur()}
          allowLeadingZeros={true}
          autoComplete={autoComplete ? 'on' : 'off'}
          decimalScale={decimalScale}
          suffix={suffix}
          pattern={pattern}
          isAllowed={values => {
            return isAllowed
              ? validateMultipleZeroesAndInteger(values?.value, value)
              : validateMultipleZeroes(values?.value, value);
          }}
        />
      </div>
      <div className={cx(style['error-msg'], { [style.active]: !!error })}>{error}</div>
    </div>
  );
};

export default InputNumberField;
