import { useEffect, useState } from 'react';
import cx from 'classnames';
import useBreakpoint from '@/helpers/customHooks/useBreakpoint';
import RenderTable from '../Components/DesktopComponents/RenderTable';
import { SignatureCard, SignatureCard2 } from '../Components/DesktopComponents/signatureUI';
import RenderTable2PreOrder from './RenderTable2PreOrder';
import RenderTable3PreOrder from './RenderTable3PreOrder';
import style from './pre-order-form.module.scss';

const PreOrderForm = props => {
  const { data } = props;
  const isMobile = useBreakpoint('mobile');

  const [driverAddress, setDriverAddress] = useState('');

  useEffect(() => {
    if (data?.metadata?.fullDriverAddress) {
      const { flat, address1, address2, city, county, postcode } = data.metadata.fullDriverAddress;
      let address = '';
      if (flat) address += ` ${flat}`;
      if (address1) address += ` ${address1}`;
      if (address2) address += ` ${address2}`;
      if (city) address += ` ${city}`;
      if (county) address += ` ${county}`;
      if (postcode) address += ` ${postcode}`;

      setDriverAddress(address);
    }
  }, [data]);

  return (
    <div className={style.container}>
      <section className={style.headerSection}>
        <h1 className={style.heading}>
          Pre Hire Agreement Regulated
          <br />
          by the Consumer Credit Act 1974
        </h1>
      </section>
      <section className={style.infoSection}>
        <div className={style.outlinedInfoWrapper}>
          <RenderTable
            title={`Name and Address of the Owner: (referred to as "DriveFuze") `}
            value={` Drive Fuze Limited, Building 101, Thurleigh, Bedford,Bedfordshire. MK442YP.`}
          />
          <RenderTable
            title={`Name and Address of the Driver `}
            value={`${data?.metadata?.customerFullName}, ${driverAddress}`}
          />
        </div>
      </section>
      <section className={style.infoSection}>
        <div>
          <h3 className={style.sectionTitle}>Key Financial Information</h3>
          <p className={cx(style.text, style['mt-16'])}>Vehicle details (the “vehicle”)</p>
        </div>
        <div className={style.outlinedInfoWrapper}>
          <RenderTable
            title={`Vehicle make and model`}
            value={` ${data?.metadata?.vehicleMake}, ${data?.metadata?.vehicleModel}`}
          />
          <RenderTable
            title={`Period of Subscription (the “Subscription Term”)`}
            value={
              <>
                <p>Subscription Start Date: To be confirmed</p>
                <p>One month rolling contract</p>
              </>
            }
          />
          <RenderTable
            title={`Initial Payment`}
            value={`£${data?.metadata?.initialPaymentAmount}`}
          />
          <RenderTable
            title={`Subscription Fee (the subscriptions)`}
            value={
              <div className={style.cardWrapper}>
                <div className={style.multiCardData}>
                  <div className={style.multiCardInfo}>
                    <div className={style.dataTitle}>No. of Payments</div>
                    <div className={style.dataValue}>One Month Rolling Payments</div>
                  </div>

                  <div className={style.multiCardInfo}>
                    <div className={style.dataTitle}>Amount Payable Including VAT</div>
                    <div className={style.dataValue}>
                      £{data?.metadata?.monthlySubscriptionCost} (“the monthly subscription rate”)
                      <br />£{data?.metadata?.dailySubscriptionCost} (“the daily subscription rate”)
                    </div>
                  </div>

                  <div className={style.multiCardInfo}>
                    <div className={style.dataTitle}>Payment Dates</div>
                    <div className={style.dataValue}>
                      To be confirmed and on the same date thereafter.
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <p className={cx(style.text, style['mt-32'])}>
          The Subscription Fee is inclusive of VAT. Drive Fuze may vary the amount of the
          Subscription Fee by giving the Driver not less than 7 days&apos; notice if there is a
          change in the law which increases Drive Fuze&apos;s liability when we account for VAT. If
          that happens Drive Fuze shall increase the next Subscription Fee to be paid by the amount
          we reasonably believe necessary to make good the extra expense the change causes Drive
          Fuze.
        </p>
        <p className={cx(style.text, style['mt-22'])}>
          Drive Fuze may also vary payments in accordance with below.
        </p>
      </section>
      <section className={style.infoSection}>
        <h3 className={style.sectionTitle}>Key Information</h3>
        <h4 className={cx(style.subtitle, style['mt-24'])}>Vehicle confirmation</h4>
        <p className={cx(style.text, style['mt-16'])}>
          Once the ordered vehicle becomes available, Drive Fuze will contact the Driver to agree a
          delivery date. Drive Fuze will provide a delivery date and allow up to 10 working days
          past the available delivery date for the Driver to select. The Driver will be sent a full
          subscription agreement which will need to be signed prior to vehicle delivery.
        </p>
        <p className={cx(style.text, style['mt-16'])}>
          If the Driver will not agree a delivery date within 10 working days of the available
          delivery date or, sign the full subscription agreement, the Driver will be deemed to have
          cancelled the order and cancellation fees will apply as set out in the payment and fees
          table below.
        </p>
        <h4 className={cx(style.subtitle, style['mt-16'])}>Payments and Fees</h4>
        <p className={cx(style.text, style['mt-16'])}>
          The following payments may be due from the Driver to Drive Fuze in addition to the Initial
          Payment and the Subscription Fee:
        </p>

        <div className={cx(style['mt-16'], style['mb-40'])}>
          <RenderTable2PreOrder isMobile={isMobile} />
          <p className={cx(style.text, style['mt-40'], style['mb-24'])}>
            The following charges may be payable by the Driver to Drive Fuze upon the failure of the
            Driver to do or refrain from doing anything which they are required to do or refrain
            from doing, as the case may be, under this Agreement:
          </p>
          <RenderTable3PreOrder isMobile={isMobile} mileage={data?.metadata?.mileagePackage} />
          <h4 className={cx(style.subtitle, style['mt-24'])}>
            IMPORTANT - READ THIS CAREFULLY TO FIND OUT ABOUT YOUR RIGHTS
          </h4>
          <p className={cx(style.text, style['mt-16'])}>
            The Consumer Credit Act 1974 covers this agreement and lays down certain requirements
            for your protection which should have been complied with when this agreement was made.
            If they were not, we cannot enforce this agreement against you without getting a court
            order.
          </p>
          <p className={cx(style.text, style['mt-16'])}>
            If you would like to know more about your rights under the Act, contact either your
            local Trading Standards Department or your nearest Citizens&apos; Advice Bureau.
          </p>
          <h4 className={cx(style.subtitle, style['mt-24'])}>MISSING PAYMENTS</h4>
          <p className={cx(style.text, style['mt-16'])}>
            Missing payments could have severe consequences and may make obtaining credit more
            difficult.
          </p>
          <p className={style.text}>This agreement is not cancellable.</p>
        </div>
        <SignatureCard customerData={data?.metadata} isPreOrder={true} />
        <div className={cx(style['mt-40'], style['mb-40'])}>
          <h4 className={style.subtitle}>
            By signing the Agreement the Driver(s) also confirms that:
          </h4>
          <ul>
            <li>
              By electronically ticking, the Driver intends to authenticate the Agreement, and they
              will become legally bound by its terms on confirmation. The Driver&apos;s name will
              appear above and constitute as a valid signature;
            </li>
            <li>
              The Driver requires Drive Fuze to commence its obligations under the Agreement and to
              arrange for the delivery of the Vehicle; the Driver has chosen the Vehicle as suitable
              for their purposes;
            </li>
            <li>
              The Driver will examine the Vehicle and satisfy themselves of its condition before
              accepting delivery;
            </li>
            <li>
              The Driver warrants that the particulars given by them in this Agreement are true and
              that this form was complete when it was signed;
            </li>
            <li>
              The Driver understands that the Subscription Fees and Excess Mileage Charges set out
              above include VAT at the rate in force at the date you sign this Agreement. These
              amounts will vary if there is a change in the applicable VAT rate.
            </li>
          </ul>
        </div>

        <SignatureCard2 customerData={data?.metadata} version={data?.version} isPreOrder={true} />
      </section>
      <section className={style.infoSection}>
        <div className={style.sectionTitle}>Insurance</div>
        <p className={cx(style.text, style['mt-24'])}>
          The Drive Fuze Subscription agreement includes insurance to the Driver and Named Drivers
          as indicated below. The Insurance Product Information Document (IPID) can be found within
          your account.
        </p>
        <p className={cx(style.text, style['mt-22'])}>
          Only the Driver and the following individual (the Named Driver) can drive the Vehicle
          during the Subscription Term:
        </p>
        <p className={style.text}>
          {data?.metadata?.mainDriverDetails?.name}, {data?.metadata?.mainDriverDetails?.dob},{' '}
          {data?.metadata?.mainDriverDetails?.licenceNumber},{' '}
          {data?.metadata?.mainDriverDetails?.expiryDate} (Driver)
        </p>

        {data?.metadata?.secondDriverDetails?.name && (
          <p className={style.text}>
            {data?.metadata?.secondDriverDetails?.name}, {data?.metadata?.secondDriverDetails?.dob},{' '}
            {data?.metadata?.secondDriverDetails?.licenceNumber},{' '}
            {data?.metadata?.secondDriverDetails?.expiryDate} (Named Driver)
          </p>
        )}
      </section>
    </div>
  );
};

export default PreOrderForm;
