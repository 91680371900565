const LISTINGS_TYPE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  META_DATA: 'META_DATA',
  CREATE_CAR_MAKE: 'CREATE_CAR_MAKE',
  GET_CAR_MAKE: 'GET_CAR_MAKE',
  CREATE_CAR_MODEL: 'CREATE_CAR_MODEL',
  GET_CAR_MODEL: 'GET_CAR_MODEL',
  COLOR: 'COLOR',
};
export default LISTINGS_TYPE;
