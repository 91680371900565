const API_ENDPOINTS = {
  LOGIN: 'login',
  RESET_PASSWORD: 'reset-password',
  UPDATE_PASSWORD: 'update-password',

  META_DATA: 'metadata',

  LISTING_GET_CAR_MAKE: 'listing/get-car-make',
  LISTING_GET_CAR_MODEL: 'listing/get-car-model',
  LISTING_BROWSE_VEHICLE: 'listing/browse-vehicle',
  LISTING_SUGGESTED_LISTING: 'listing/suggested-listing',
  LISTING_RECOMMENDAD_VEHICLES: 'listing/recommended-vehicles',

  LIFESTYLE_TAG_SLUG_DETAILS: 'lifestyle-tag/get-slug-details',

  /* Customer Registration */
  CUSTOMER_EMAIL_REGISTER: 'register',
  CUSTOMER_DETAILS_REGISTER: 'users/registration',
  CUSTOMER_ABOUT_YOU: 'users/about-you',
  CUSTOMER_VERIFY_EMAIL: 'verify-email',
  CUSTOMER_RESEND_EMAIL_VERIFICATION: 'resend-verification-email',
  CUSTOMER_OTP_ENABLED: 'users/two-step-verification',
  GET_OTP: 'get-otp',
  UPDATE_OTP: 'update-otp',
  DELIVERY_GET: 'delivery/get',
  USER_DETAILS: 'users/get-users-profile',
  ADD_DRIVER: 'users/add-driver',
  USER_DRIVER: 'driver',

  // Booking
  DELIVERY_PAYMENT: 'booking/delivery-payment',
  ADDITIONAL_SERVICES: 'booking/get-mileage',

  /* Payment */
  SETUP_CARD: 'payment/setup-card',
  CARD_DETAILS: 'payment/card',

  /* Bookings */
  BOOKINGS_DETAILS: 'booking',
  BOOKINGS_ACTIVE: 'booking/active',
  BOOKINGS_HISTORY: 'booking/history',
  LISTING_COLOR: 'listing/color',
  BOOKING_CREATE: 'booking/create',
  BOOKING_AGREEMENT: 'booking/generate-booking-aggreement',
  PRE_ORDER_BOOKING_AGREEMENT: 'booking/generate-pre-order-booking-aggreement',
  ADD_DRIVER_BOOKING_AGREEMENT: 'booking/add-driver/generate-booking-agreement',
  MILEAGE_DETAILS: 'users/mileage',
  PRE_BOOKING_CHECKS: 'booking/prebooking-checks',
  WAITING_LIST: 'listing/user-interest',
  FINES_PCNS_LIST: 'users/fine-pcn',
  ALL_BOOKINGS_FINES_PCNS: 'users/all-bookings',
  MILEAGE_GRAPH: 'users/mileage/graph',
  DRIVER_PRICING_CHANGES: 'booking/driver-pricing-component',

  /* ONFIDO */
  ONFIDO_CHECK_VALIDITY: 'onfido/checkLastOnfidoValidity',
  ONFIDO_CREATE_TOKEN: 'onfido/create-token',
  ONFIDO_RETRY_TOKEN: 'onfido/retry',
  ONFIDO_GENERATE_CHECK: 'onfido/generate-check',
  ONFIDO_DOCUMENT_RESPONSE: 'onfido/document-response',
  ONFIDO_CHECK_RESULT: 'onfido/check-result',
  ONFIDO_UPDATE_RESULT: 'onfido/update-check-response',
  ONFIDO_DRIVER: 'driver',

  /* Return vehicle */
  INITIATE_RETURN: 'booking/initiate-return',

  /* Swap Vehicle */
  SWAP_FLOW: 'customer/booking',
  SWAP_PRICING: 'customer/booking/swap/pricing-details',
  SWAP_EXISTS: 'customer/booking/swap-exists',

  /* My Account */
  RENEW_TERMS_CHECK: 'booking/renew-terms',
  UPDATE_PHONE: 'users/phone-number',
  DRIVERS: 'driver?page=1&status=active&getMainDriver=true',
  USER_BILLING: 'users/billing',
  USER_BILLING_HISTORY: 'users/invoice',
  CHANGE_PASSWORD: 'users/password',
  PAST_DRIVERS: 'driver?page=1&status=past',
  PAST_DRIVER_DATES: 'driver',
  INVOICE_HISTORY_DETAIL: 'booking/invoice',
  DOWNLOAD_ONFIDO_DOCUMENT: 'driver/document',
  DELETE_ACCOUNT: 'users/delete',
  MARKETING_PREFERENCES: 'users/marketing-preferences',
  IS_ACCOUNT_DELETABLE: 'users/is-account-deletable',

  /* DVLA */
  DVLA_CONSENT_GET_USER_DETAILS: 'dvla/user-dvla-consent-details',
  DVLA_CONSENT_USER_DETAILS_APPROVE: 'dvla/user-dvla-consent-approve',

  /* ContactForm */
  CONTACT_US: 'contact-us',
  POSTCODES_IO: 'https://postcodes.io/postcodes/',

  /* PROMOTION */
  APPLY_PROMOTION: 'customer/valid-voucher',

  /* PRE-ORDER */
  PRE_ORDER: 'customer/pre-order',

  /* CONTRACT-OPTIONS */
  GET_CONTRACT_LENGTH_OPTIONS: 'get-contract-lengths',
  GET_VEHICLE_PRICING: 'get-listing-price',
};
export default API_ENDPOINTS;
