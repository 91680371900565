/* eslint-disable consistent-return */

import API_ENDPOINTS from '@/helpers/apiEndpoint';
import REQUEST from '@/helpers/http.service';
import { save } from '@/helpers/cookies';
import LOGIN_CONSTANTS from '@/helpers/variable-constants';
import ROUTE_NAME from '@/helpers/url-constants';
import CONFIG from '@/helpers/config';
import { loadCookie, saveCookie } from '@/apis/Auth';
import REGISTRATION_CONSTANTS from '../CustomerRegistration/message-constant';
import LOGIN_TYPE from './action-type';

export const resetLoginCheck = status => {
  return {
    type: LOGIN_TYPE.LOGIN_CHECK,
    payload: status,
  };
};

export const userDetails = () => async dispatch => {
  const user = await loadCookie();
  if (user?.token || user?.data?.token) {
    const userDetailsRes = await REQUEST({
      method: 'GET',
      url: `${API_ENDPOINTS.USER_DETAILS}`,
      accessToken: user?.token || user?.data?.token,
    });
    if (userDetailsRes?.data?.status) {
      dispatch({
        type: LOGIN_TYPE.USER_DETAILS,
        payload: {
          data: userDetailsRes?.data.data,
        },
      });
    }

    return userDetailsRes?.data;
  }
};

export const conditionalLogin = res => async dispatch => {
  const status = res?.data?.data?.status;
  const otp = res?.data?.data?.otpEnabled;
  const token = res?.data?.data?.token;

  if (res?.data?.data?.status) {
    if (status === LOGIN_CONSTANTS.INITIATED) {
      dispatch({
        type: LOGIN_TYPE.LOGIN_PENDING_REGISTRATION,
        payload: {
          route: ROUTE_NAME.SIGN_UP,
          render: REGISTRATION_CONSTANTS.PENDING_VERIFICATION,
          data: res?.data,
        },
      });
    }
    if (status === LOGIN_CONSTANTS.EMAIL_VERIFIED) {
      if (!otp && token) {
        dispatch({
          type: LOGIN_TYPE.LOGIN_PENDING_REGISTRATION,
          payload: {
            redirect: false,
            route: ROUTE_NAME.SIGN_UP,
            render: REGISTRATION_CONSTANTS.ONBOARD,
            data: res?.data,
          },
        });
      }
    }
    if (status === LOGIN_CONSTANTS.RESTRICTED_POSTCODE) {
      dispatch({
        type: LOGIN_TYPE.LOGIN_PENDING_REGISTRATION,
        payload: {
          redirect: false,
          route: ROUTE_NAME.SIGN_UP,
          render: REGISTRATION_CONSTANTS.ONBOARD,
          data: res?.data,
        },
      });
    }
    if (status === LOGIN_CONSTANTS.ONBOARD) {
      if (!otp && token) {
        // httpOnly cookie
        const cookieObj = {
          cookieName: CONFIG.COOKIE_USER,
          data: {
            loginStatus: true,
            token,
            data: res?.data?.data,
          },
        };
        save(CONFIG.COOKIE_FUZE_USERNAME, {
          firstName: res?.data?.data?.firstName,
          lastName: res?.data?.data?.lastName,
        });
        save(CONFIG.COOKIE_APP_VERSION, {
          appVersion: CONFIG.APP_VERSION,
        });
        saveCookie(cookieObj);
        dispatch({
          type: LOGIN_TYPE.TOKEN,
          payload: res.data?.data?.token,
        });
        dispatch({
          type: LOGIN_TYPE.LOGIN,
          payload: {
            data: res?.data,
            loginStatus: true,
          },
        });

        // push userId to dataLayer on Login for GTM/GA
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'login',
          userId: res.data.data.id,
        });
      } else {
        dispatch({
          type: LOGIN_TYPE.VERIFY_OTP,
          payload: {
            data: res?.data,
            loginStatus: false,
          },
        });
      }
    }
  } else if (otp) {
    dispatch({
      type: LOGIN_TYPE.VERIFY_OTP,
      payload: {
        data: res?.data,
        loginStatus: false,
      },
    });
  }
};

export const getOTP = id => async () => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.GET_OTP}/${id}`,
    auth: false,
  });

  return res?.data;
};

export const updateOTP = data => async dispatch => {
  const res = await REQUEST({
    method: 'POST',
    url: API_ENDPOINTS.UPDATE_OTP,
    data,
    auth: false,
  });
  if (res?.data?.status) {
    // httpOnly cookie
    const cookieObj = {
      cookieName: CONFIG.COOKIE_USER,
      data: {
        loginStatus: true,
        token: res?.data?.data?.token,
        data: res?.data?.data,
      },
    };
    save(CONFIG.COOKIE_FUZE_USERNAME, {
      firstName: res?.data?.data?.firstName,
      lastName: res?.data?.data?.lastName,
    });
    save(CONFIG.COOKIE_APP_VERSION, {
      appVersion: CONFIG.APP_VERSION,
    });
    saveCookie(cookieObj);
    dispatch({
      type: LOGIN_TYPE.TOKEN,
      payload: res.data?.data?.token,
    });

    dispatch({
      type: LOGIN_TYPE.LOGIN_WITH_OTP,
      payload: {
        data: res?.data,
        loginStatus: true,
      },
    });

    // push userId to dataLayer on Login with OTP for GTM/GA
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'login_with_otp',
      userId: res?.data?.data?.id,
    });

    dispatch(resetLoginCheck(true));
    return res?.data;
  }
  return res?.data;
};

export const userLogin = data => async dispatch => {
  const res = await REQUEST({
    method: 'POST',
    url: API_ENDPOINTS.LOGIN,
    data,
    auth: false,
  });

  if (res) {
    if (res?.data?.status) {
      dispatch(conditionalLogin(res));
      dispatch(resetLoginCheck(true));
      return res?.data;
    }
    dispatch({
      type: LOGIN_TYPE.LOGIN,
      payload: {
        data: res.data,
        loginStatus: false,
        token: '',
      },
    });
    return res?.data;
  }
};

export const loginWithCookie = () => async dispatch => {
  // commented out code to check if issue exists on production - if it exists check domain for httponly user cookie
  // const username = load(CONFIG.COOKIE_FUZE_USERNAME); // This check fixes issue when logging out from marketing page. DO NOT REMOVE

  //  if (username) {
  const user = await loadCookie();
  if (user?.token || user?.data?.token) {
    dispatch({
      type: LOGIN_TYPE.LOGIN_WITH_COOKIE_SUCCESS,
      payload: user,
    });

    // push userId to dataLayer on Login for GTM/GA
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'login',
      userId: user.data.id,
    });
  } else {
    dispatch({
      type: LOGIN_TYPE.LOGIN_WITH_COOKIE_FAILURE,
      payload: { loginStatus: false },
    });
  }
  dispatch(resetLoginCheck(true));
  //  }
};

export const userLogout = () => {
  return {
    type: LOGIN_TYPE.USER_LOGOUT,
  };
};

export const getFeatureVehicle = () => async dispatch => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.LISTING_BROWSE_VEHICLE}?headlineVehicle=1`,
  });
  if (res?.data?.status) {
    dispatch({
      type: LOGIN_TYPE.FEATURE_VEHICLE,
      payload: res?.data,
    });
    return res?.data;
  }
};
export const getFeatureVehicleList = () => async dispatch => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.LISTING_BROWSE_VEHICLE}?featuredVehicle=1`,
  });
  if (res) {
    dispatch({
      type: LOGIN_TYPE.FEATURE_VEHICLE_LIST,
      payload: res?.data,
    });
    return res?.data;
  }
};
