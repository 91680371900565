import classNames from 'classnames/bind';
import style from './bubble-listing.module.scss';

function BubbleListing(props) {
  const cx = classNames.bind(style);
  const { data, selectedId, onSelected, small = false, cursorType = 'pointer' } = props;

  const isSelected = id => {
    if (Array.isArray(selectedId)) {
      return selectedId.includes(id);
    }
    return selectedId === id;
  };

  return (
    <ul
      className={cx(style['bubbles-listing'], {
        [style.small]: small,
      })}
    >
      {data.map(item => (
        <li
          key={item?.id}
          className={style['bubble-switch']}
          onClick={() => onSelected && onSelected(item.id)}
        >
          <span
            id={`type${item.id}`}
            className={cx(style['bubble-wrapper'], {
              [style['selected-bubble']]: isSelected(item?.id),
              [style.small]: small,
            })}
            style={{ cursor: `${cursorType}` }}
          >
            {item?.name}
          </span>
        </li>
      ))}
    </ul>
  );
}

export default BubbleListing;
