import classNames from 'classnames/bind';
import style from './modal.module.scss';

const cx = classNames.bind(style);

function Modal(props) {
  const { children, open, onClose, innerModal = false } = props;
  return (
    <div className={cx(style.overlay, { open, [style.inner]: innerModal })}>
      <div className={style['overlay-left']} onClick={onClose}></div>
      <div className={style['overlay-right']}>
        <div className={style['overlay-content']}>{children}</div>
      </div>
    </div>
  );
}

export default Modal;
