const checkEnv = env => {
  if (String(env).includes('uat')) {
    return '-uat';
  }
  if (String(env).includes('qa')) {
    return '-qa';
  }
  if (String(env).includes('dev')) {
    return '-dev';
  }
  return '';
};

const config = {
  APP_VERSION: `1.3${checkEnv(process.env.NEXT_PUBLIC_API_BASE_URL)}`,
  API: {
    url: process.env.NEXT_PUBLIC_API_BASE_URL,
    sentry: process.env.NEXT_PUBLIC_SENTRY_DSN,
    google_map_api: process.env.NEXT_PUBLIC_MAPS_API,
    aws_url: process.env.NEXT_PUBLIC_AWS_S3_URL
      ? process.env.NEXT_PUBLIC_AWS_S3_URL
      : 'https://drivefuze-dev-media-files.s3.eu-west-2.amazonaws.com/',
    partner_id: process.env.NEXT_PUBLIC_PARTNER_ID
      ? process.env.NEXT_PUBLIC_PARTNER_ID
      : 'REVIEW_PARTNER_ID',
  },
  MODE: process.env.MODE,
  COOKIE_USER: `fuze-user${checkEnv(process.env.NEXT_PUBLIC_API_BASE_URL)}`,
  COOKIE_BOOKING: `fuze-booking${checkEnv(process.env.NEXT_PUBLIC_API_BASE_URL)}`,
  COOKIE_BROWSE_FILTERS: `fuze-filters${checkEnv(process.env.NEXT_PUBLIC_API_BASE_URL)}`,
  COOKIE_FUZE_USERNAME: 'fuze-username',
  COOKIE_APP_VERSION: 'fuze-app-version',
  BROWSE_VEHICLE_DEFAULT_LIMIT: 21,
};

module.exports = config;
