import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Router, { useRouter } from 'next/router';
import classNames from 'classnames/bind';
import Image from 'next/image';
import { Icon, Button } from '@/components/general';
import {
  userLogout,
  resetLoginCheck,
  getFeatureVehicle,
  getFeatureVehicleList,
} from '@/flow/Login/action';
import useBreakpoint from '@/helpers/customHooks/useBreakpoint';
import { save } from '@/helpers/cookies';
import ROUTE_NAME from '@/helpers/url-constants';
import CONFIG from '@/helpers/config';
import { deleteCookie } from '@/apis/Auth';
import { toastError } from '../general/ToastNotifications/toastType';
import BrowseVehiclesSubMenu from './BrowseVehicles';
import WhatIsCarSubscriptionSubMenu from './WhatIsCarSubscription';
import style from './header.module.scss';

const cx = classNames.bind(style);

export default function Header({ loginStatus, user }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const isTablet = useBreakpoint('tablet');
  const [open, setOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openSubMenuCarSubscription, setOpenSubMenuCarSubscription] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const firstName = user?.firstName?.split(' ');
  const lastNameSplit = user?.lastName?.split(' ');
  const lastName =
    lastNameSplit &&
    (!lastNameSplit[lastNameSplit.length - 1]
      ? lastNameSplit[lastNameSplit.length - 2]
      : lastNameSplit[lastNameSplit.length - 1]);

  const linkInfo = [
    {
      title: 'Browse vehicles',
      href: ROUTE_NAME.BROWSE_VEHICLES,
      activeClass: openSubMenu || router.pathname.startsWith(ROUTE_NAME.BROWSE_VEHICLES),
    },
    // TODO hiding below for v1
    {
      title: 'What is car subscription?',
      href: '#',
      activeClass: openSubMenuCarSubscription,
    },
    {
      title: 'Business Solutions',
      href: ROUTE_NAME.BUSINESS_SOLUTIONS,
      activeClass: router.pathname.startsWith(ROUTE_NAME.BUSINESS_SOLUTIONS),
    },
    {
      title: 'Support',
      href: ROUTE_NAME.SUPPORT,
      activeClass: router.pathname.startsWith(ROUTE_NAME.SUPPORT),
    },
    // {
    //   id: 'blog',
    //   title: 'Blog',
    //   href: ROUTE_NAME.BLOG,
    //   activeClass: router.pathname.startsWith(ROUTE_NAME.BLOG),
    // },
    // TODO suggestion to change Contact us with Support for v1
    // {
    //   title: 'Contact Us',
    //   href: ROUTE_NAME.CONTACT_US,
    //   activeClass: router.pathname.startsWith(ROUTE_NAME.CONTACT_US),
    // },
  ];
  const linkInfoLogged = [
    // {
    //   id: 'dashboard',
    //   title: 'Dashboard',
    //   href: ROUTE_NAME.DASHBOARD,
    //   activeClass: router.pathname.length < 2,
    // },
    {
      id: 'my-bookings',
      title: 'My bookings',
      href: ROUTE_NAME.MY_BOOKINGS,
      activeClass: router.pathname.startsWith(ROUTE_NAME.MY_BOOKINGS),
    },
    // TODO hiding below for v1
    // {
    //   id: 'messages',
    //   title: 'Messages',
    //   href: ROUTE_NAME.MESSAGES,
    //   activeClass: router.pathname.startsWith(ROUTE_NAME.MESSAGES),
    // },
    {
      id: 'support',
      title: 'Support',
      href: ROUTE_NAME.SUPPORT,
      activeClass: router.pathname.startsWith(ROUTE_NAME.SUPPORT),
    },
    {
      id: 'blog',
      title: 'Blog',
      href: ROUTE_NAME.BLOG,
      activeClass: router.pathname.startsWith(ROUTE_NAME.BLOG),
    },
    {
      id: 'my-account',
      title: 'My Account',
      href: ROUTE_NAME.MY_ACCOUNT,
      activeClass: router.pathname.startsWith(ROUTE_NAME.MY_ACCOUNT),
      mobileOnly: true,
    },
  ];

  const logoutFn = async () => {
    save(CONFIG.COOKIE_BOOKING, {});
    save(CONFIG.COOKIE_BROWSE_FILTERS, {});
    save(CONFIG.COOKIE_FUZE_USERNAME, {});
    save(CONFIG.COOKIE_APP_VERSION, {});
    sessionStorage.clear();
    localStorage.removeItem('selectedVehicle');
    dispatch(resetLoginCheck(false));
    const { status } = await deleteCookie({ cookieName: CONFIG.COOKIE_USER });
    if (status) {
      dispatch(userLogout());
      Router.push(ROUTE_NAME.LOGIN);
    } else {
      toastError({ title: 'Something went wrong' });
    }
  };

  useEffect(() => {
    dispatch(getFeatureVehicle());
    dispatch(getFeatureVehicleList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // show/hide navbar when scrolling Y
  useEffect(() => {
    const handleNavbar = () => {
      if (typeof window !== 'undefined') {
        const currentScrollPos = window.scrollY;
        setVisible(currentScrollPos < 50 ? true : prevScrollPos > currentScrollPos);
        setPrevScrollPos(currentScrollPos);
      }
    };

    if (isTablet) window.addEventListener('scroll', handleNavbar);
    return () => window.removeEventListener('scroll', handleNavbar);
  }, [prevScrollPos, isTablet]);

  // Remove overflow hidden from body
  useEffect(() => {
    return () => {
      if (document.body.classList.contains('overflow-hidden')) {
        document.body.classList.remove('overflow-hidden');
      }
    };
  }, [isTablet]);

  // Manage overflow hidden from body to open side bar in mobile
  useEffect(() => {
    if (isTablet) {
      if (open) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onClickHandler = (route, title) => {
    if (title === 'What is car subscription?') {
      setOpenSubMenuCarSubscription(!openSubMenuCarSubscription);
      setOpenSubMenu(false);
    } else if (title === 'Browse vehicles') {
      setOpenSubMenuCarSubscription(false);
      setOpenSubMenu(!openSubMenu);
    } else {
      setOpenSubMenu(false);
      setOpenSubMenuCarSubscription(false);
      if (open) setOpen(false);
      router.push(route);
    }
  };

  return (
    // inline styling to show/hide navbar on visible state
    <div className={style.navbarContainer} style={{ top: visible ? 0 : '-70px' }}>
      <div
        className={cx(style.background, {
          [style.open]: open,
        })}
      ></div>
      <header
        className={cx(style.header, {
          [style.open]: open,
        })}
      >
        <div className={style.content}>
          <div className={style.logoContainer}>
            <a href={ROUTE_NAME.LANDING_PAGE}>
              {/* <object type="image/svg+xml" data="/assets/logo/fuze-logo.svg">
                DriveFuze
              </object> */}
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <Image src="/assets/logo/fuze-logo.svg" alt="Fuze logo" width={166} height={28} />
            </a>
          </div>
          <div
            className={cx(style.toggle, {
              [style.active]: open,
            })}
            onClick={() => setOpen(!open)}
          >
            <div className={style.line1}></div>
            <div className={style.line2}></div>
            <div className={style.line3}></div>
          </div>
        </div>
        <div className={style.navigation}>
          <div className={style['section-left']}>
            <div className={style.nav}>
              {loginStatus && Object.keys(user)?.length
                ? linkInfoLogged.map((item, index) => (
                    <div key={index} className={cx(style.navItem, { mobile: item.mobileOnly })}>
                      <a
                        onClick={() => onClickHandler(item.href, item.title)}
                        className={cx(
                          { active: item.activeClass },
                          { activeMobile: openSubMenu && index === 0 },
                        )}
                      >
                        {item.title}
                      </a>
                      {item.title === 'Messages' && (
                        <div className={style.msgItem}>{/* {props.filter().length} */}2</div>
                      )}
                    </div>
                  ))
                : loginStatus === false &&
                  linkInfo.map((item, index) => (
                    <div key={index} className={cx(style.navItem)}>
                      <a
                        style={index === 0 || index === 1 ? { paddingRight: '18px' } : {}}
                        onClick={() => onClickHandler(item.href, item.title)}
                        className={cx(
                          { active: item.activeClass },
                          {
                            activeMobile:
                              (openSubMenu && index === 0) ||
                              (openSubMenuCarSubscription && index === 1),
                          },
                        )}
                      >
                        {item.title}{' '}
                        {index === 0 || index === 1 ? (
                          <Icon icon={'arrow-down'} size={24} color="#001E78" />
                        ) : (
                          ''
                        )}
                      </a>

                      {index === 0 ? (
                        <BrowseVehiclesSubMenu
                          openSubMenu={openSubMenu}
                          setOpenSubMenu={setOpenSubMenu}
                          setOpen={setOpen}
                          open={open}
                        />
                      ) : (
                        ''
                      )}
                      {index === 1 ? (
                        <WhatIsCarSubscriptionSubMenu
                          openSubMenu={openSubMenuCarSubscription}
                          setOpenSubMenu={setOpenSubMenuCarSubscription}
                          setOpen={setOpen}
                          open={open}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  ))}
            </div>
          </div>
          {/* {loginStatus !== null && (
            <div className={style.phoneNavigation}>
              <div
                className={cx(style.phoneNumber, {
                  [style.phoneNumberInMobile]: open,
                })}
              >
                <a href="tel:0330 118 0947">
                  <Icon icon="communication" size={16} color="#fff" />
                  <span className={cx(style.number)}>0330 118 0947</span>
                </a>
              </div>
            </div>
          )} */}
          {loginStatus !== null && // loginStatus must have a value (true or false), otherwise nothing is displayed
          loginStatus ? (
            <div className={style['section-right']}>
              <div className={style['user-profile-dropdown']}>
                <div className={style['profile-wrapper']}>
                  <div className={style.profile}>
                    <span className={style['profile-text']}>
                      <Icon className={style['dropdown-indicator']} icon="user" size={18} />
                    </span>
                    <p className={style.name} style={{ textTransform: 'capitalize' }}>
                      {(firstName && firstName[0]) || ''} {lastName || ''}
                    </p>
                    <Icon
                      className={style['dropdown-indicator']}
                      icon="arrow-down"
                      size={24}
                      color={style.fluorescentBlue}
                    />
                    <ul className={style.menu}>
                      <li>
                        <a onClick={() => onClickHandler(ROUTE_NAME.MY_ACCOUNT)}>
                          <Icon icon="user-in-circle" size={30} color={style.white} />
                          <span>My account</span>
                        </a>
                      </li>
                      <li>
                        <a onClick={logoutFn} href="#">
                          <Icon icon="logout" size={30} color={style.white} />
                          <span>Logout</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={style['header-btn']}>
                  <Button secondary="true" onClick={logoutFn} id={style.uniqueHeaderBtn}>
                    Logout
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            loginStatus === false && (
              <div className={style['section-right']}>
                <div className={style['header-btn']}>
                  <a onClick={() => onClickHandler(ROUTE_NAME.LOGIN)}>
                    <Button secondary id={style.uniqueHeaderBtn}>
                      Login
                    </Button>
                  </a>
                  <a onClick={() => onClickHandler(ROUTE_NAME.SIGN_UP)}>
                    <Button white>Sign Up</Button>
                  </a>
                </div>
              </div>
            )
          )}
        </div>
      </header>
    </div>
  );
}
