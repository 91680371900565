import classNames from 'classnames/bind';
import style from './link-button.module.scss';

const LinkButton = props => {
  const { text, onClick = () => {}, className, disabled } = props;
  const cx = classNames.bind(style);
  return (
    <div
      className={cx(style.link_button, className, { [style.link_disabled]: disabled })}
      role="presentation"
      onClick={disabled ? () => {} : onClick}
    >
      {text}
    </div>
  );
};

export default LinkButton;
