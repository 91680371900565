/* eslint-disable no-plusplus */
import React from 'react';
// import classNames from 'classnames/bind';
import CreatableSelect from 'react-select/creatable';
import style from './select-create.module.scss';
// import { ActionMeta, OnChangeValue } from 'react-select';

const colourStyles = {
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    borderRadius: '20px',
    border: '1px solid #ACC1C8',
    borderColor: isFocused || isSelected ? '#001E78' : '#ACC1C8',
    boxShadow: 'none',
    fontWeigth: 300,
    fontSize: '15px',
    '&:hover': {
      borderColor: '#001E78',
    },
  }),
  menu: styles => {
    return {
      ...styles,
      boxShadow: 'none',
      border: '1px solid #ACC1C8',
      padding: '2px 6px',
    };
  },
  option: (styles, props) => {
    const { isDisabled, selectProps } = props;
    return {
      ...styles,
      background: 'transparent',
      color: '#000000',
      fontWeigth: 300,
      fontSize: '15px',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      padding: '8px 10px',
      '&:hover': {
        background: '#F4F7F8',
        borderRadius: '4px',
      },
      '&:last-child': {
        color: '#273137',
        fontSize: '13px',
        fontWeight: 500,
        position: 'relative',
        paddingLeft: selectProps.inputValue ? '40px' : '10px',
        transition: 'padding-left 175ms ease-in-out',
        '&:after': {
          content: '"+"',
          lineHeight: '140%',
          fontSize: '18px',
          position: 'absolute',
          top: '4px',
          left: '18px',
          opacity: selectProps.inputValue ? '1' : '0',
          transition: 'opacity 105ms ease-linear',
        },
        '&:before': {
          content: '""',
          border: '2px solid #273137',
          borderRadius: '50%',
          position: 'absolute',
          top: '6px',
          left: '12px',
          width: '18px',
          height: '18px',
          opacity: selectProps.inputValue ? '1' : '0',
          transition: 'opacity 105ms ease-linear',
        },
      },
    };
  },
};

const SelectCreate = props => {
  // const cx = classNames.bind(style);

  const {
    label,
    value,
    options,
    onSelect,
    onCreate,
    placeholder,
    requiredField,
    isDisabled = false,
  } = props;

  const handleChange = newValue => {
    // console.group('Value Changed');
    // console.log(newValue);
    // console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    onSelect(newValue);
  };

  const handleCreate = inputValue => {
    onCreate(inputValue);
  };

  // const handleInputChange = (inputValue, actionMeta) => {
  //   console.group('Input Changed');
  //   console.log(inputValue);
  //   console.log(`action: ${actionMeta.action}`);
  //   console.groupEnd();
  // };

  return (
    <div>
      {label && (
        <label className={style.label}>
          {label}
          {requiredField && <sup>*</sup>}
        </label>
      )}
      <CreatableSelect
        styles={colourStyles}
        isClearable
        onChange={handleChange}
        // onInputChange={handleInputChange}
        onCreateOption={handleCreate}
        options={options}
        value={value}
        isValidNewOption={() => true}
        placeholder={placeholder}
        formatCreateLabel={inputValue =>
          inputValue ? `Add new: ${inputValue}` : `Start typing to add new option`
        }
        // maxMenuHeight={100}
        // menuIsOpen={true}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default SelectCreate;
