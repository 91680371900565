/* eslint-disable default-param-last */
import ACTION_TYPE from './action-type';

const initialState = {
  tempPhoneNumber: null,
  user: {},
};

const accountReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTION_TYPE.USER_INFO:
      state = {
        ...state,
        user: {
          ...state.user,
          ...action.payload.data,
        },
      };
      return state;
    case ACTION_TYPE.TEMP_PHONE_UPDATE:
      return { ...state, tempPhoneNumber: action.payload };
    case ACTION_TYPE.CLEAN_TEMP_DATA:
      return { ...state, tempPhoneNumber: null };
    default:
      return state;
  }
};

export default accountReducer;
