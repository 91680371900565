import React, { useState, useEffect } from 'react';
import { useTable, usePagination, useFilters } from 'react-table';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Icon from '@/components/general/Icon';
import EmptyInfoCard from '@/components/general/EmptyInfoCard';
import { isNumber } from '@/helpers/common';
import style from './table.module.scss';

const ASC = 'ASC';
const DESC = 'DESC';

const Table = props => {
  const cx = classNames.bind(style);
  const {
    // --- check Table.propTypes at the bottom of this file for more details on how to use
    columns, // required
    data, // required
    customClass, // adds custom class to <table> tag
    isSelectedRow, // adds activerow styling { index: 0, status: false }
    sortedColumn, // example: { column: 'carMake', order:DESC } // it will be used for sorting order highlight
    defaultPageSize, // default: 10
    pageNo, // default: 0 // pageNo is used for pagination and it must start with 0
    totalCount, // required // If api pagination, count property is sent in object - that value should be passed here. Otherwise just pass down data.length in this prop, to prevent unexpected bugs
    handlePagination,
    handleSorting,
    handleRowDetails, // handles row onClick
    emptyDataText, // default: 'No data to present' // if no data exists, then EmptyInfoCard component will be rendered with message passed in this prop
    small, // adds a smaller fontsize and rows height for some views. Bool value
  } = props;

  const [sortBy, setSortBy] = useState({ column: '', order: '' });

  useEffect(() => {
    if (sortedColumn?.column !== sortBy?.column || sortedColumn?.order !== sortBy?.order)
      setSortBy(sortedColumn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumn]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: pageNo,
        pageSize: defaultPageSize,
      },
      manualPagination: true,
      pageCount: Math.ceil((totalCount || data.length) / defaultPageSize),
    },
    useFilters,
    usePagination,
  );

  const getPureName = str => {
    const reg = /^[a-zA-Z]+_[0-9]+$/;
    if (str?.includes('_') && reg.test(str)) {
      return str.replace(/_\d/, '');
    }
    return str;
  };
  const toUpperCaseString = str => {
    if (str && typeof str === 'string') {
      return str?.toUpperCase();
    }
    return str;
  };

  const handleSortBy = column => {
    if (!column?.disableSortBy && data?.length !== 0 && column?.id !== '') {
      let columnId = getPureName(column?.id);
      let order = sortedColumn?.firstSort || DESC;
      if (Object.prototype.hasOwnProperty.call(column, 'columns')) {
        const columnIndex = columnId.split('_').slice(-1).pop();
        if (isNumber(columnIndex)) columnId = getPureName(column.columns[0]?.id);
      }
      if (columnId === getPureName(sortBy?.column)) {
        order = toUpperCaseString(sortBy.order) === ASC ? DESC : ASC;
      }
      setSortBy({
        column: columnId,
        order,
      });
      handleSorting({ column, columnId, order });
    }
  };

  const handleRow = ({ e, cellIndex, row }) => {
    if (handleRowDetails) handleRowDetails({ e, cellIndex, row });
  };

  const handlePageChange = pageData => {
    if (handlePagination) handlePagination(pageData);
  };

  if (!data?.length) {
    return <EmptyInfoCard message={emptyDataText} smallerHeight />;
  }

  return (
    <>
      <div className={cx(style['table-container'], customClass)}>
        <table {...getTableProps()} className={cx(style['table-layout'])}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={`header-${i}`}
                className={style['table-header-row']}
              >
                {headerGroup.headers.map(column => {
                  return (
                    <th
                      {...column.getHeaderProps()}
                      key={`header-${column?.id}`}
                      onClick={() => !!handleSorting && handleSortBy(column)}
                    >
                      <div
                        className={cx(
                          style['table-headers'],
                          column?.className,
                          column?.headerClassName,
                        )}
                      >
                        <span>{column.render('Header')}</span>
                        {/* Sorting icons */}
                        {!!handleSorting &&
                          data?.length !== 0 &&
                          column.Header &&
                          column.disableSortBy !== 'undefined' &&
                          !column.disableSortBy && (
                            <span className={style['table-sort-icon-wrp']}>
                              <Icon icon="direction" size={14} color="#ACC1C8" />
                            </span>
                          )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={`body-row-${i}`}
                  className={cx(
                    { 'parent-row': row.depth === 0 },
                    { [style.activerow]: isSelectedRow?.index === i && isSelectedRow?.status },
                    data?.length ? '' : 'empty-row',
                    { [style.small]: small },
                  )}
                >
                  {row.cells.map((cell, j) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={`row-cell-${i}${j}`}
                        className={cx(`table-cell ${cell.column?.className || ''}`)}
                        onClick={e =>
                          data?.length !== 0 && handleRow({ e, cellIndex: j, row: cell.row })
                        }
                        aria-hidden="true"
                      >
                        <span className={cx({ [style['text-ellipsis']]: !cell.column?.actionBtn })}>
                          {cell.render('Cell')}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!!handlePagination && (
        <div className={style['pagination-container']}>
          {data?.length ? (
            <>
              <div className={style['pagination-page-wrapper']}>
                <button
                  onClick={() => {
                    previousPage();
                    handlePageChange(pageIndex - 1);
                  }}
                  disabled={!canPreviousPage}
                  className={cx(style['paginate-box'])}
                >
                  <Icon
                    icon="arrow-left"
                    color={canPreviousPage ? style.brandColor : style.grey400}
                  />
                  {/* // check it */}
                </button>
                <span>
                  <span>
                    {pageIndex + 1} /{pageOptions?.length}
                  </span>
                </span>
                <button
                  onClick={() => {
                    nextPage();
                    handlePageChange(pageIndex + 1);
                  }}
                  disabled={!canNextPage}
                  className={cx(style['paginate-box'])}
                >
                  <Icon icon="arrow-right" color={canNextPage ? style.brandColor : style.grey400} />
                  {/* // check it */}
                </button>
              </div>
            </>
          ) : null}
        </div>
      )}
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.exact({
      Header: PropTypes.string,
      accessor: PropTypes.string.isRequired,
      className: PropTypes.string,
      actionBtn: PropTypes.string,
      Cell: PropTypes.func,
      disableSortBy: PropTypes.bool,
      // add more properties here if needed from react-table to keep docs up-to-date
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  customClass: PropTypes.string,
  isSelectedRow: PropTypes.exact({
    index: PropTypes.number,
    status: PropTypes.bool,
  }),
  sortedColumn: PropTypes.exact({
    column: PropTypes.string,
    order: PropTypes.string,
  }),
  defaultPageSize: PropTypes.number,
  pageNo: PropTypes.number,
  totalCount: PropTypes.number.isRequired,
  handlePagination: PropTypes.func,
  handleSorting: PropTypes.func,
  handleRowDetails: PropTypes.func,
  emptyDataText: PropTypes.string,
  small: PropTypes.bool,
};

Table.defaultProps = {
  defaultPageSize: 10,
  pageNo: 0,
  emptyDataText: 'No data to show you at this moment',
};

export default Table;
