import classNames from 'classnames/bind';
import styles from './loading-spinner.module.scss';

const LoadingSpinner = props => {
  const { customClass } = props;
  const cx = classNames.bind(styles);

  return (
    <div className={cx(styles.loadingRing, customClass)} {...props}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingSpinner;
