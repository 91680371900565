const REMOVE_DRIVER_ACTION_TYPE = {
  GET_REMOVE_DRIVER_PRICE_INFO: 'GET_REMOVE_DRIVER_PRICE_INFO',
  SWITCH_TAB_REMOVE_DRIVER: 'SWITCH_TAB_REMOVE_DRIVER',
  RESET_INITIAL_STATE: 'RESET_INITIAL_STATE',
  REMOVE_ADDITIONAL_DRIVER_ID: 'REMOVE_ADDITIONAL_DRIVER_ID',
  ADDITIONAL_DRIVER_DATE: 'ADDITIONAL_DRIVER_DATE',
  REMOVE_DRIVER_ID_DATE: 'REMOVE_DRIVER_ID_DATE',
  AGREEMENT_CHECK: 'AGREEMENT_CHECK',
};

export default REMOVE_DRIVER_ACTION_TYPE;
