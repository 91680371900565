/* eslint-disable default-param-last */
import ACTION_TYPE from './action-type';

const initialState = {
  selectedTab: 'DATE',
  addDrivers: [],
  driverPriceUpdate: null,
  driverId: null,
  type: 'driver_remove',
  changeDate: null,
  agreement: false,
};

const removeDriverReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTION_TYPE.RESET_INITIAL_STATE:
      return initialState;

    case ACTION_TYPE.REMOVE_ADDITIONAL_DRIVER_ID:
      return {
        ...state,
        driverId: action.payload,
      };

    case ACTION_TYPE.GET_REMOVE_DRIVER_PRICE_INFO:
      return {
        ...state,
        driverPriceUpdate: action.payload,
      };

    case ACTION_TYPE.SWITCH_TAB_REMOVE_DRIVER:
      return { ...state, selectedTab: action.payload.value };

    case ACTION_TYPE.ADDITIONAL_DRIVER_DATE:
      return {
        ...state,
        changeDate: action.payload,
      };

    case ACTION_TYPE.REMOVE_DRIVER_ID_DATE:
      return {
        ...state,
        driverId: null,
        changeDate: null,
      };

    case ACTION_TYPE.AGREEMENT_CHECK:
      return {
        ...state,
        agreement: action.payload,
      };

    default:
      return state;
  }
};

export default removeDriverReducer;
