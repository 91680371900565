const ROUTE_NAME = {
  // Unauthorized Routes
  ABOUT_US: 'https://www.drivefuze.com/information/about-us',
  BLOG: 'https://www.drivefuze.com/blog',
  BOOK: '/book',
  BROWSE_VEHICLES: '/browse',
  DASHBOARD: '/',
  FORGOT_PASSWORD: '/forgot-password',
  FAQS: 'https://www.drivefuze.com/faqs',
  HOW_IT_WORKS: '/how-it-works',
  LANDING_PAGE: 'https://www.drivefuze.com',
  LOGIN: '/login',
  MESSAGES: '/messages',
  MY_ACCOUNT: '/account',
  MY_BOOKINGS: '/bookings',
  ORDER: '/order',
  PRIVACY_POLICY: 'https://drivefuze.com/privacy-policy',
  RESET_PASSWORD: '/reset-password',
  SUPPORT: '/support',
  TWO_FACTOR_AUTHENTICATE: '/two-factor-authentication',
  TERMS_AND_CONDITIONS: 'https://drivefuze.com/terms-and-conditions',
  SWAP_BROWSE: '/swap/browse',
  FAQ: 'https://www.drivefuze.com/faqs',
  COMPLAINT: 'https://www.drivefuze.com/complaints',
  FINANCIAL_CERTIFICATION: 'https://www.drivefuze.com/information/financial-certification',
  BUSINESS_SOLUTIONS: 'https://www.drivefuze.com/b2b',

  // SignUp Routes
  SIGN_UP: '/signup',
  EMAIL_VERIFICATION: '/signup/verify-email',
  USER_DETAILS: '/signup/user-details',

  // Error Route
  ERROR_500: '/500',
  PAGE_NOT_FOUND_404: '/404',

  PDF_INSURANCE_PRODUCT_INFO: '/pdf/ipid_drive_fuze.pdf',
  SHARE_IMAGE:
    'https://uploads-ssl.webflow.com/636b7947cfaef2af8800cdaa/6388a8bd330617976970b5e1_MM16081_DF_OpenGraph_Images_1200x630-01.jpg',

  // What is car subscription dropdown routes
  PCP_ALTERNATIVE: 'https://www.drivefuze.com/car-subscription',
  HOW_WE_COMPARE:
    'https://www.drivefuze.com/blog/all-inclusive-car-subscription-see-how-we-compare',
  USERSHIP:
    'https://www.drivefuze.com/blog/why-car-subscription-services-are-the-future-of-car-ownership',
  FCA_REGULATED: 'https://www.drivefuze.com/information/financial-certification',
};
export default ROUTE_NAME;
