import classNames from 'classnames/bind';
import Button from '../Button';
import Icon from '../Icon';
import style from './modal-popup.module.scss';

const cx = classNames.bind(style);

function ModalPopup(props) {
  const {
    children,
    open,
    title,
    icon,
    rightChildren,
    buttonLeft,
    buttonRight,
    iconSize = 22,
  } = props;
  return (
    <div className={cx(style.overlay, { open })}>
      <div className={style['overlay-content']}>
        {title ? (
          <>
            <div className={cx(style.columns, { content: true })}>
              <div>
                <div className={style.title}>{title}</div>
              </div>
              <div>
                {icon && (
                  <div className={style.icon}>
                    <Icon icon={icon} size={iconSize} color={style.fluorescentBlue} />
                  </div>
                )}
                <div className={style.text}>{rightChildren}</div>
              </div>
            </div>
            <div className={cx(style.columns, style.btnContainer)}>
              <div>
                {buttonLeft && (
                  <div>
                    <Button
                      onClick={buttonLeft.onClickHandler}
                      secondary={true}
                      white={true}
                      disabled={buttonLeft.disabled}
                    >
                      {buttonLeft.label}
                    </Button>
                  </div>
                )}
              </div>
              <div>
                {buttonRight && (
                  <div>
                    <Button
                      onClick={buttonRight.onClickHandler}
                      secondary={buttonRight.secondary}
                      white={true}
                      disabled={buttonRight.disabled}
                      loading={buttonRight.loading}
                    >
                      {buttonRight.label}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          children
        )}
      </div>
    </div>
  );
}

export default ModalPopup;
