import REQUEST from './http.service';

// eslint-disable-next-line import/prefer-default-export
export const AppConfig = {
  site_name: 'Fuze',
  title: 'Drive Fuze',
  description: 'Admin Portal of Drive Fuze',
  locale: 'en-US',
};

export const fetcherFunction = fetchConfig => async dispatch => {
  const res = await REQUEST({
    method: fetchConfig.method,
    url: fetchConfig.url,
    auth: fetchConfig.auth,
    data: fetchConfig.data,
  });
  if (res.data.status) {
    dispatch({
      type: fetchConfig.reducerType,
      payload: res.data.data,
    });
  }
  return res.data;
};
