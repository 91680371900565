import { useState } from 'react';
import Image from 'next/legacy/image';
import Skeleton from 'react-loading-skeleton';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import useBreakpoint from '@/helpers/customHooks/useBreakpoint';
import CONFIG from '@/helpers/config';
import style from './vehicle-image.module.scss';

const VehicleImage = ({ data, quality = false, scrollPosition }) => {
  const breakpointQuality = useBreakpoint('tablet') ? 80 : 60;
  const [isLoaded, setIsLoaded] = useState(false);

  if (!data?.imageUrl) {
    return null;
  }

  if (data?.imageSource === 1) {
    return (
      <div className={style.imageContainer}>
        <LazyLoadImage
          alt={`${data?.carMake} ${data?.carModel} vehicle picture`}
          src={`${data?.imageUrl}&width=400`}
          width={'100%'}
          threshold={200}
          scrollPosition={scrollPosition}
          placeholder={
            <div className={style.skeletonLoadingContainer}>
              <Skeleton
                borderRadius={16}
                width={'100%'}
                height={150}
                baseColor="#DDE3F9"
                highlightColor="#FFF"
              />
            </div>
          }
        />
      </div>
    );
  }

  return (
    <>
      {!isLoaded && (
        <div className={style.skeletonLoadingContainer}>
          <Skeleton
            borderRadius={16}
            width={'100%'}
            height={150}
            baseColor="#DDE3F9"
            highlightColor="#FFF"
          />
        </div>
      )}
      <Image
        src={`${CONFIG.API.aws_url}${data?.imageUrl}`}
        alt={`${data?.carMake} ${data?.carModel} vehicle picture`}
        layout="fill"
        objectFit="contain"
        objectPosition="center"
        sizes="(max-width: 767px) 90vw, (max-width: 1024px) 80vw, 25vw"
        quality={quality ? breakpointQuality : null}
        onLoadingComplete={() => setIsLoaded(true)}
      />
    </>
  );
};

export default trackWindowScroll(VehicleImage);
