import Router from 'next/router';
import { deleteAllCookie } from '@/apis/Auth';
import CONFIG from './config';
import { loadAll } from './cookies';

export default function checkAppVersion() {
  const allCookies = loadAll();
  const appVersionCookie = allCookies?.[CONFIG.COOKIE_APP_VERSION]
    ? JSON.parse(allCookies?.[CONFIG.COOKIE_APP_VERSION])
    : {};

  const userCookie = allCookies?.[CONFIG.COOKIE_FUZE_USERNAME]
    ? JSON.parse(allCookies?.[CONFIG.COOKIE_FUZE_USERNAME])
    : {};

  const appVersionCookieExists = Object?.keys(appVersionCookie)?.length > 0;
  const isUserLoggedIn = Object?.keys(userCookie)?.length > 0;

  const autoLogout = async () => {
    sessionStorage.clear();
    const { status } = await deleteAllCookie();
    if (status) {
      Router.push('/login');
    }
  };

  if (!appVersionCookieExists && isUserLoggedIn) {
    autoLogout();
    return;
  }

  if (appVersionCookie.appVersion !== CONFIG.APP_VERSION && isUserLoggedIn) {
    autoLogout();
  }
}
