import LISTINGS_TYPE from './action-type';

const initialState = {
  metaData: {},
};

const browseVehiclesReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case LISTINGS_TYPE.META_DATA:
      state = {
        ...state,
        metaData: payload,
      };
      return state;
    case LISTINGS_TYPE.COLOR:
      state = {
        ...state,
        colors: payload,
      };
      return state;
    default:
      return state;
  }
};

export default browseVehiclesReducer;
