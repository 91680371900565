/* eslint-disable consistent-return */

import axios from 'axios';
import { v4 as uuIdv4 } from 'uuid';
import CONFIG from '@/helpers/config';
import { deleteAllCookie, loadCookie } from '@/apis/Auth';

const REQUEST = async config => {
  const createObject = {
    baseURL: CONFIG.API.url,
  };
  if (config?.responseType) createObject.responseType = config?.responseType;

  const HTTP = axios.create(createObject);

  // for REQUEST
  const userCookie = await loadCookie();
  const token = userCookie?.data?.token || userCookie?.token;

  HTTP.interceptors.request.use(async request => {
    request.url = {};
    request.headers = {};
    // URL
    request.url = request.baseURL + config.url;

    // Request HEADERs

    if (config.accessToken) {
      request.headers['x-auth-token'] = config.accessToken;
    }

    if (config.auth && token) {
      request.headers['x-auth-token'] = token;
    }

    if (config.guestMode) {
      request.headers['x-auth-token'] = config.guestMode;
    }

    request.headers['x-request-id'] = uuIdv4();

    // HTTP security headers
    request.headers['Cache-control'] = 'no-store';
    request.headers['Content-Security-Policy'] =
      "default-src 'self'; style-src 'self'; script-src 'self'; font-src 'self'; form-action 'self'; frame-ancestors 'self'; img-src 'self' *.s3.eu-west-2.amazonaws.com *.imagin.studio *.cloudfront.net https: data:; object-src 'none'; upgrade-insecure-requests";
    request.headers.Pragma = 'no-cache';
    request.headers['Permissions-Policy'] =
      'accelerometer=(), ambient-light-sensor=(), autoplay=(), battery=(), camera=(), cross-origin-isolated=(), display-capture=(), document-domain=(), encrypted-media=(), execution-while-not-rendered=(), execution-while-out-of-viewport=(), fullscreen=(), geolocation=(), gyroscope=(), keyboard-map=(), magnetometer=(), microphone=(), midi=(), navigation-override=(), payment=(self), picture-in-picture=(), publickey-credentials-get=(), screen-wake-lock=(), sync-xhr=(), usb=(), web-share=(), xr-spatial-tracking=()';
    request.headers['Referrer-Policy'] = 'no-referrer';
    request.headers['Strict-Transport-Security'] = 'max-age=15552000; includeSubDomains';
    request.headers['X-Content-Security-Policy'] =
      "default-src 'self'; style-src 'self'; script-src 'self'; font-src 'self'; form-action 'self'; frame-ancestors 'self'; img-src 'self' *.s3.eu-west-2.amazonaws.com *.imagin.studio *.cloudfront.net https: data:; object-src 'none'; upgrade-insecure-requests";
    request.headers['X-Content-Type-Options'] = 'nosniff';
    request.headers['X-Frame-Options'] = 'SAMEORIGIN';
    // --- END of security headers

    request.method = config.method;
    request.data = config.data;

    return request;
  });

  // for RESPONSE
  HTTP.interceptors.response.use(
    async response => {
      if (response.status === 200 || response.status === 201) {
        return response;
      }
      if (config.responseType) {
        response.type = config.responseType;
        return response;
      }
    },
    async error => {
      if (error?.response?.status === 401 || error?.response?.status === 302) {
        const res = await deleteAllCookie();
        if (res.status) {
          window.location.href = '/login';
          return;
        }
        return {
          data: { status: false, message: 'Unauthorized request', statusCode: 401 },
        };
      }

      if (error?.response?.status === 500) {
        if (
          typeof error.response.data?.message === 'string' &&
          error.response.data?.message?.includes('jwt')
        ) {
          const res = await deleteAllCookie();
          if (res.status) window.location.href = '/login';
        }
      }

      if (
        error?.response?.status === 400 ||
        error?.response?.status === 404 ||
        error?.response?.status === 412 ||
        error?.response?.status === 500 ||
        error?.response?.status === 422 ||
        error?.response?.status === 428 ||
        error?.response?.status === 403 ||
        error?.response?.status === 406 ||
        error?.response?.status.toString().charAt(0) === '5'
      ) {
        return (
          error?.response || {
            data: { status: false, message: 'Something went wrong', data: {} },
          }
        );
      }
    },
  );

  return HTTP();
};

export default REQUEST;
