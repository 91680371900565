import SWAP_ACTION_TYPE from './action-type';

const initialState = {
  currentSubscription: {
    additionalOffer: null,
    additionalOfferStatus: false,
    bookingId: '',
    bookingStatus: '',
    carMake: '',
    carModel: '',
    carVariant: '',
    createdAt: '',
    doorType: '',
    featuresBenefits: [],
    featuresBenefitsStatus: false,
    fkDriverId: '',
    fkPartnerId: '',
    fleetioLink: 1,
    fuelType: '',
    gearBoxType: '',
    id: '',
    imageId: '',
    imageSource: 0,
    imageTags: '',
    imageUrl: '',
    offer: null,
    offerStatus: false,
    optional: null,
    pricing: '',
    status: '',
    driverFirstName: '',
    driverLastName: '',
  },
  initialAboutYou: {
    jobTitle: null,
    salary: null,
    address: null,
  },
  showDocuments: false,
  showAboutYou: false,
  deliveryDate: null,
  tabs: {
    drivers: false,
    aboutYou: false,
    documents: false,
    additionalServices: true,
    delivery: true,
    agreement: true,
    payment: true,
  },
  isSameAddress: null,
  isSameJobSalary: null,
  listingColorId: null,
  swapExists: false,
  nextPaymentDate: null,
};

// eslint-disable-next-line default-param-last
const swapReducer = (state = initialState, action) => {
  switch (action.type) {
    case SWAP_ACTION_TYPE.SWAP_RESET:
      return initialState;
    case SWAP_ACTION_TYPE.SWAP_INITIAL_ABOUT_YOU:
      return {
        ...state,
        initialAboutYou: action.payload,
      };
    case SWAP_ACTION_TYPE.SWAP_INITIALIZE_SUBSCRIPTION:
      return {
        ...state,
        currentSubscription: {
          ...state.currentSubscription,
          ...action.payload,
        },
      };
    case SWAP_ACTION_TYPE.SWAP_SHOW_DOCUMENTS:
      return {
        ...state,
        showDocuments: action.payload.value,
      };
    case SWAP_ACTION_TYPE.SWAP_DELIVERY:
      return {
        ...state,
        deliveryDate: action.payload,
      };
    case SWAP_ACTION_TYPE.SWAP_SHOW_ABOUT_YOU:
      return {
        ...state,
        showAboutYou: action.payload.value,
      };
    case SWAP_ACTION_TYPE.SWAP_TABS_ENABLE_DISABLE:
      return {
        ...state,
        tabs: { ...state.tabs, ...action.payload },
      };
    case SWAP_ACTION_TYPE.SWAP_IS_SAME_ADDRESS:
      return {
        ...state,
        isSameAddress: action.payload.value,
      };
    case SWAP_ACTION_TYPE.SWAP_IS_SAME_JOB_SALARY:
      return {
        ...state,
        isSameJobSalary: action.payload.value,
      };
    case SWAP_ACTION_TYPE.UPDATE_VEHICLE_COLOUR:
      return {
        ...state,
        listingColorId: action.payload.value,
      };
    case SWAP_ACTION_TYPE.SWAP_EXISTS:
      return {
        ...state,
        swapExists: action?.payload?.value?.isSwapExists,
      };
    case SWAP_ACTION_TYPE.NEXT_PAYMENT_DATE:
      return {
        ...state,
        nextPaymentDate: action?.payload?.value,
      };
    default:
      return state;
  }
};

export default swapReducer;
