import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { SWRConfig } from 'swr';
import Head from 'next/head';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Layout from '@/components/Layout';
import { fetcherFunction } from '@/helpers/AppConfig';
import { store } from '@/store/store';

import 'react-date-picker/dist/DatePicker.css';
import 'react-toastify/dist/ReactToastify.css';
import '@/styles/globals.scss';

const globalConfig = {
  refreshInterval: 3000,
  fetcher: fetcherFunction(),
};

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_APIKEY);

function MyApp({ Component, pageProps }) {
  const [stripeIsLoaded, setStripeIsLoaded] = useState(false);

  const stripePromiseResponse = async () => {
    const stripe = await stripePromise;
    setStripeIsLoaded(stripe);
  };

  useEffect(() => {
    if (!stripeIsLoaded) stripePromiseResponse();
  }, [stripeIsLoaded]);

  return (
    <Provider store={store}>
      <SWRConfig value={globalConfig}>
        <Layout>
          <Head>
            <title>Drive Fuze</title>
          </Head>
          {stripeIsLoaded ? (
            <Elements stripe={stripePromise}>
              <Component {...pageProps} />
            </Elements>
          ) : (
            <Component {...pageProps} />
          )}
        </Layout>
      </SWRConfig>
    </Provider>
  );
}

export default MyApp;
