/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react';
import cx from 'classnames';
import Icon from '@/components/general/Icon';
import useBreakpoint from '@/helpers/customHooks/useBreakpoint';
import Tooltip from '../Tooltip';
import style from './input-field.module.scss';

function InputField({
  className,
  type = 'text',
  label,
  placeholder,
  value,
  name,
  id = '',
  error,
  readOnly = false,
  requiredField = false,
  maxlength,
  otherAttributes = {},
  autoComplete = false,
  handleShowHidePassword = false,
  tooltipMessage = '',
  disabled = false,
  upperCaseText = false,
  onChangeHandler = () => {},
  onFocusHandler = () => {},
  onBlurHandler = () => {},
  onKeyDownHandler = () => {},
  handleSelectHandler = () => {},
  endIcon,
  caption,
  biggerLabel = false,
  errorIcon = false,
}) {
  const [showPassword, handleShowPwd] = useState(false);
  const isMobile = useBreakpoint('mobile');
  const commonFields = {
    ...otherAttributes,
    id: id || name,
    placeholder,
    value,
    required: requiredField,
    onChange: onChangeHandler,
    onFocus: onFocusHandler,
    onBlur: onBlurHandler,
    onKeyDown: onKeyDownHandler,
    onSelect: handleSelectHandler,
  };

  if (!autoComplete) {
    /**
     * If autoComplete is turned of
     *
     */
    commonFields.autoComplete = 'off';
    // commonFields.autoComplete = 'new-password';
  }

  if (type === 'number') {
    commonFields.onKeyDown = function onKeyDown(event) {
      /**
       * keyCode === 8: backspace / delete
       * keyCode === 46: delete
       * event.keyCode === 190: period
       */

      if (
        !(
          event.keyCode === 8 ||
          event.keyCode === 46 ||
          event.keyCode === 190 ||
          // eslint-disable-next-line no-restricted-globals
          !isNaN(Number(event.key))
        )
      ) {
        event.preventDefault();
        event.stopPropagation();
      }
    };
  }

  if (type === 'tel') {
    commonFields.onKeyDown = function onKeyDown(event) {
      if (
        !(
          (event.keyCode >= 48 && event.keyCode <= 57) ||
          (event.keyCode >= 96 && event.keyCode <= 105) ||
          event.keyCode === 8
        )
      ) {
        event.preventDefault();
        event.stopPropagation();
      }
    };
  }

  function renderPwdVisibilityIcon() {
    return (
      <span
        data-level-up={true}
        className={style['visibility-icon']}
        onClick={() => handleShowPwd(!showPassword)}
        aria-hidden={true}
      >
        <Icon icon={showPassword ? 'hide-eye' : 'eye'} width={24} color="#BCBCBC" />
      </span>
    );
  }

  let inputType = type;
  if (handleShowHidePassword) {
    inputType = showPassword ? 'text' : 'password';
  }

  if (error) {
    const input = document?.querySelector(`[name=${name}]`);
    input?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  return (
    <div className={cx(className, style['form-group'])}>
      {label && (
        <label
          className={cx(style.label, {
            [style.biggerLabel]: biggerLabel,
          })}
          htmlFor={id || name}
        >
          <span>
            {label}
            {requiredField && <sup>*</sup>}
          </span>

          {tooltipMessage && (
            <Tooltip
              message={tooltipMessage}
              color={'#ACC1C8'}
              icon={'information'}
              iconSize={18}
              position={isMobile ? 'left' : 'top'}
            />
          )}
        </label>
      )}
      <div
        className={cx(style['input-field-wrapper'], {
          [style['show-hide-password-wrapper']]: handleShowHidePassword,
        })}
      >
        <input
          className={cx(
            style['input-field'],
            { [style.errorField]: !!error },
            { [style.passwordField]: handleShowHidePassword },
            { [style.endIcon]: endIcon },
            { [style.upperCaseText]: upperCaseText },
          )}
          name={name}
          type={inputType}
          maxLength={maxlength}
          {...commonFields}
          readOnly={readOnly}
          disabled={disabled}
          onWheel={e => e.target.blur()}
        />
        {handleShowHidePassword && renderPwdVisibilityIcon()}
        {endIcon && (
          <div className={cx(style['icon-symbol'], style['icon-position-right'])}>
            <Icon icon={endIcon} size={20} />
          </div>
        )}
      </div>
      {caption && <div className={style.caption}>{caption}</div>}
      {error && typeof error === 'string' && (
        <div className={style['error-msg']}>
          {errorIcon && <Icon icon={errorIcon} size={19} color={style.redError} />}
          {error}
        </div>
      )}
    </div>
  );
}

export default InputField;
