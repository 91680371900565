/* eslint-disable import/prefer-default-export */
export const getTooltipPosition = (el, tooltip, position) => {
  const { top: elTop, left: elLeft, height: elHeight, width: elWidth } = el.getBoundingClientRect();
  const { width: tooltipWidth, height: tooltipHeight } = tooltip.getBoundingClientRect();
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
  const gap = 15;

  // window boundaries
  const offTop = elTop - gap - tooltipHeight < 70;
  const offBottom = elTop + elHeight + gap + tooltipHeight > windowHeight;
  const offLeft = elLeft - gap - tooltipWidth < 0;
  const offRight = elLeft + elWidth + gap + tooltipWidth > windowWidth;

  let correctedLeft = elLeft;
  let correctedTop = elTop;
  let correctedArrow = '';
  let correctedArrowPlace = '';

  const setNewPosition = (newPosLeft, newPosTop, arrowPlace = '') => {
    correctedLeft = newPosLeft;
    correctedTop = newPosTop;
    correctedArrowPlace = arrowPlace;
  };

  switch (position) {
    case 'top': {
      setNewPosition(elLeft + elWidth / 2 - tooltipWidth / 2, elTop - gap - tooltipHeight);
      break;
    }
    case 'left': {
      setNewPosition(elLeft - gap - 6 - tooltipWidth, elTop + elHeight / 2 - tooltipHeight / 2);
      break;
    }
    case 'right': {
      setNewPosition(elLeft + elWidth + gap, elTop + elHeight / 2 - tooltipHeight / 2);
      break;
    }
    case 'bottom':
    default:
      setNewPosition(elLeft + elWidth / 2 - tooltipWidth / 2, elTop + elHeight + gap);
      break;
  }

  if (offTop && position !== 'bottom') {
    if (position === 'top') {
      setNewPosition(correctedLeft, elTop + elHeight + gap);
    } else {
      setNewPosition(correctedLeft, elTop - elHeight / 2 - 6, 'end');
    }
  }

  if (offRight && position !== 'left') {
    if (position === 'right') {
      setNewPosition(elLeft - gap - tooltipWidth - 6, correctedTop);
    } else if (position === 'top') {
      setNewPosition(elLeft + elWidth - tooltipWidth, correctedTop, 'end');
    } else if (position === 'bottom') {
      setNewPosition(elLeft + elWidth - tooltipWidth, correctedTop, 'end');
    }
  }

  if (offLeft && position !== 'right') {
    if (position === 'left') {
      setNewPosition(elLeft + elWidth + gap, correctedTop);
    } else if (position === 'top') {
      setNewPosition(elLeft - 11, correctedTop, 'start');
    } else if (position === 'bottom') {
      setNewPosition(elLeft - 11, correctedTop, 'start');
    }
  }

  if (offBottom && position !== 'top') {
    if (position === 'bottom') {
      setNewPosition(correctedLeft, correctedTop - 6 - gap * 2 - elHeight - tooltipHeight);
    } else {
      setNewPosition(correctedLeft, correctedTop + elHeight / 2 - tooltipHeight / 2, 'start');
    }
  }

  // Fix the arrow direction:
  if (elTop > correctedTop) {
    correctedArrow = 'arrow-down';
  }
  if (elTop < correctedTop) {
    correctedArrow = 'arrow-up';
  }
  if (elLeft > correctedLeft && position !== 'top' && position !== 'bottom') {
    correctedArrow = 'arrow-to-right';
  }
  if (elLeft < correctedLeft && position !== 'top' && position !== 'bottom') {
    correctedArrow = 'arrow-to-left';
  }

  return {
    left: correctedLeft,
    top: correctedTop,
    arrow: { direction: correctedArrow, place: correctedArrowPlace },
  };
};
