import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import VehicleImage from '@/components/general/VehicleItem/VehicleImage';
import ROUTE_NAME from '@/helpers/url-constants';
import style from './browse-vehicles-submenu.module.scss';

function BrowseVehiclesSubMenu(props) {
  const { setOpenSubMenu, openSubMenu, setOpen, open } = props;
  const router = useRouter();
  const { vehicleId } = router.query;
  const { featureVehicle, featureVehicleList } = useSelector(state => state?.loginReducer);

  const onViewDetail = id => {
    setOpenSubMenu(!openSubMenu);
    if (open) setOpen(false);
    if (id === 'ALL') {
      router.push(`${ROUTE_NAME.BROWSE_VEHICLES}`);
    } else {
      localStorage.setItem('selectedVehicle', id);
      router.push(`${ROUTE_NAME.BROWSE_VEHICLES}/${id}`);
    }
  };

  return (
    <div
      className={cx(style['browse-vehicles-container'], { [style['active-menu']]: openSubMenu })}
    >
      <div className={style.arrow}></div>
      <div className={style['vehicles-list']}>
        <div className={style.tab}>Our vehicles</div>
        <div className={style.viewButton} onClick={() => onViewDetail('ALL')}>
          View all vehicles
        </div>
        <div className={style['vehicles-list-container']}>
          {featureVehicleList?.data?.map((el, index) => (
            <div
              style={el.listingId === vehicleId ? { color: 'rgba(0, 255, 255, 0.5)' } : {}}
              className={style.listItem}
              key={index}
              onClick={() => onViewDetail(el?.listingId)}
            >
              {el?.featuredVehicleName}
            </div>
          ))}
        </div>
      </div>
      {featureVehicle?.data?.headlineVehicleName ? (
        <div className={style['vehicles-container']}>
          <div className={style.image}>
            <div className={style.thumbnail}>
              <VehicleImage data={featureVehicle?.data?.image} />
            </div>
          </div>
          <div className={style.content}>
            <div className={style['content-wrapper']}>
              <div className={style.heading}>{featureVehicle?.data?.headlineVehicleName}</div>
              <div className={style['sub-Heading']}>{featureVehicle?.data?.carVariant} </div>
              <div className={style.date}> From £{featureVehicle?.data?.listingPrice}/month</div>
              <div
                className={style.button}
                onClick={() => onViewDetail(featureVehicle?.data?.listingId)}
              >
                {' '}
                View vehicle{' '}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={style['vehicles-container']}>
          <div className={style.emptyScreen}>No data found for headline vehicle</div>
        </div>
      )}
    </div>
  );
}

export default BrowseVehiclesSubMenu;
