import { useRouter } from 'next/router';
import cx from 'classnames';
import ROUTE_NAME from '@/helpers/url-constants';
import style from './car-subscription-submenu.module.scss';

function WhatIsCarSubscriptionSubMenu(props) {
  const { setOpenSubMenu, openSubMenu, setOpen, open } = props;
  const router = useRouter();

  const dropdownData = [
    { title: 'The alternate to PCP', link: ROUTE_NAME.PCP_ALTERNATIVE },
    { title: 'See how we compare', link: ROUTE_NAME.HOW_WE_COMPARE },
    { title: 'Car usership not ownership', link: ROUTE_NAME.USERSHIP },
    { title: 'About Drive Fuze', link: ROUTE_NAME.ABOUT_US },
    { title: 'Regulated by the FCA', link: ROUTE_NAME.FCA_REGULATED },
    { title: 'FAQ', link: ROUTE_NAME.FAQ },
    { title: 'Blog', link: ROUTE_NAME.BLOG },
  ];

  const onViewDetail = link => {
    router.push(link);
    setOpenSubMenu(!openSubMenu);
    if (open) setOpen(false);
  };

  return (
    <div
      className={cx(style['browse-vehicles-container'], { [style['active-menu']]: openSubMenu })}
    >
      <div className={style.arrow}></div>
      <div className={style['vehicles-list']}>
        <div className={style.tab}>What is car subscription?</div>
        <div className={style.viewButton} />
        <div className={style['vehicles-list-container']}>
          {dropdownData?.map((el, index) => (
            <div className={style.listItem} key={index} onClick={() => onViewDetail(el?.link)}>
              {el?.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WhatIsCarSubscriptionSubMenu;
