import { useState } from 'react';
import Icon from '@/components/general/Icon';
import style from '@/components/general/SubscriptionAgreement/agreement.module.scss';

const RenderTable2 = props => {
  const { isMobile, handleClickScroll, version } = props;
  const [activeIndex, setActiveIndex] = useState(-1);
  const handleClick = index => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };
  const dataArray = [
    {
      value1: 'Modification Charge',
      value2: 'Variable',
      value3: ` The Driver shall be responsible for any costs in returning the Vehicle to its
  pre-modified condition upon return of the Vehicle to Drive Fuze where any
  modifications have been carried out on, or anything has been attached or affixed to,
  the Vehicle without Drive Fuze's written permission.`,
      link: ` See condition 2.4`,
      id: '24',
    },
    {
      value1: 'Repairs',
      value2: 'Variable',
      value3: ` The Driver will be liable for repairs (which must be carried out by our repair centre
        approved by Drive Fuze) and all charges if the vehicle has been damaged inside or
        outside (whether or not it’s the Driver’s fault) at any time when such damage is
        discovered or reported. This includes but is not limited to:`,
      value4: `i. after the discovery of
        damage following re-inspection of the Vehicle when returned by the Driver to Drive
        Fuze;`,
      value5: `ii. if the Vehicle needs more than Drive Fuze’s standard valeting (cleaning)
        upon return of the Vehicle to Drive Fuze.`,
      link: 'See conditions 2.7, 2.8 and 6.2.1',
      id: '27',
    },
    {
      value1: 'Insurance Excess and premiums',
      value2: 'Variable',
      value3: `The Driver is responsible for paying any insurance excess charges to the repair centre
      which repairs the Vehicle.`,
    },
    {
      value1: 'Cancellation Fee',
      value2: 'Variable',
      value3: `If the Driver cancels the Subscription (but only where the Vehicle has been delivered to
        the Driver) within 14 days of this agreement being confirmed, a pro-rata fee will be
        applied at the Daily Subscription Rate for the numbers of days the Vehicle has been on
        Subscription. If the Driver cancels the Subscription (where by no Vehicle has been
        delivered to the Driver) within 14 days of this agreement being confirmed, the
        Subscription Fees and Initial Payment paid in advance by the Driver will be refunded.
        Please refer to your Subscription Agreement when booking for further details.`,
      link: 'See Condition 11.2 onwards',
      id: '112',
    },
    {
      value1: 'Early Termination Fee',
      value2: 'Fixed',
      value3: `If the Driver cancels the Subscription after the Vehicle has been delivered and accepted and before the end of the Minimum Subscription Term, the Driver will be required to pay the Early Termination fee as detailed above in this Agreement.`,
    },
    {
      value1: 'Termination charges',
      value2: 'Variable',
      value3: `Upon termination of this Agreement, the Driver shall also be liable for:`,
      value4: `i. any arrears of Subscription Fees and other amounts due and payable at the time
      (including for the avoidance of doubt any Fines, Tolls and other charges payable under
      Condition 6);`,
      value5: `ii. any other amounts which may become due or payable under this Agreement;`,
      value6: `iii. Any early termination fees which may become due or payable under this agreement`,
      // link: ' See condition 2.4',
      // id: '24',
    },
  ];
  return (
    <div className={style.tableType_2}>
      <table className={style.tableHeader}>
        <tr>
          <th>Other Payments</th>
          <th>Fixed or Variable</th>
          {!isMobile && <th>Total Charge</th>}
        </tr>
      </table>
      {dataArray.map((item, index) => (
        <table key={index} className={style.tableBlock}>
          <tr>
            <th>{item.value1}</th>
            <th>{item.value2}</th>
            {!isMobile ? (
              <th>
                {item.value3}
                {item.value4 && (
                  <>
                    <br />
                    <br />
                    {item.value4}
                  </>
                )}
                {item.value5 && (
                  <>
                    <br />
                    {item.value5}
                  </>
                )}
                {version === 'v4' && item.value6 && (
                  <>
                    <br />
                    {item.value6}
                  </>
                )}
                <br />
                <br />
                <span>
                  <a onClick={() => handleClickScroll(item?.id)} className={style.sectionLink}>
                    {item?.link}
                  </a>
                </span>
              </th>
            ) : (
              <th onClick={() => handleClick(index)}>
                <Icon
                  icon={'cancel-big'}
                  size={20}
                  color="#001E78"
                  style={{
                    rotate: index !== activeIndex ? '135deg' : '',
                    transition: 'ease 0.35s',
                  }}
                />
              </th>
            )}
          </tr>
          {index === activeIndex && (
            <tr className={style.accordion}>
              <td colSpan={3}>
                {item.value3}
                {item.value4 && (
                  <>
                    <br />
                    <br />
                    {item.value4}
                  </>
                )}
                {item.value5 && (
                  <>
                    <br />
                    {item.value5}
                  </>
                )}
                {version === 'v4' && item.value6 && (
                  <>
                    <br />
                    {item.value6}
                  </>
                )}
                <br />
                <br />
                <span>
                  <a onClick={() => handleClickScroll(item.id)} className={style.sectionLink}>
                    {item.link}
                  </a>
                </span>
              </td>
            </tr>
          )}
        </table>
      ))}
    </div>
  );
};
export default RenderTable2;
