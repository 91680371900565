/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';

// cookie functions

export const saveCookie = async data => {
  await axios({
    method: 'POST',
    url: '/api/cookies/save',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
};

export const loadCookie = async () => {
  const res = await axios({
    method: 'GET',
    url: '/api/cookies/load',
  });
  if (res?.data) {
    const parsedData = JSON.parse(res?.data);
    return parsedData;
  }
};

export const deleteCookie = async data => {
  const res = await axios({
    method: 'POST',
    url: '/api/cookies/delete',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
  return res;
};
export const deleteAllCookie = async () => {
  const res = await axios({
    method: 'POST',
    url: '/api/cookies/deleteAll',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res;
};
