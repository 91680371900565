/* eslint-disable default-param-last */
import ACTION_TYPE from './action-type';

const initialState = {
  selectedTab: 'COLLECTION',
  collectionDate: null,
  pricingData: null,
  bookingDetails: null,
  fourteenDaysLogic: null,
};

const returnVehicleReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTION_TYPE.SWITCH_RETURN_TAB:
      return { ...state, selectedTab: action.payload.value };

    case ACTION_TYPE.INITIALIZE_RETURN:
      return {
        ...state,
        bookingDetails: action.payload.bookingDetails,
      };

    case ACTION_TYPE.SAVE_COLLECTION_DATE:
      return {
        ...state,
        collectionDate: action.payload.value,
      };

    case ACTION_TYPE.UPDATE_RETURN_PRICING_DATA:
      return {
        ...state,
        pricingData: action.payload,
      };

    case ACTION_TYPE.FOURTEEN_DAYS_LOGIC:
      return {
        ...state,
        fourteenDaysLogic: action.payload,
      };

    default:
      return state;
  }
};

export default returnVehicleReducer;
