import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import style from './switchbutton.module.scss';

const SwitchButton = ({
  id,
  name,
  status,
  value,
  onChangeHandler,
  label,
  caption,
  notAllowed,
  cursorType = 'pointer',
}) => {
  const [isOn, setValue] = useState(status);

  useEffect(() => {
    setValue(status);
  }, [status]);

  return (
    <div className={style.container}>
      {label && <label className={style.label}>{label}</label>}
      <div className={style.switchContainer}>
        <span className={style['switch-wrapper']}>
          <input
            className={style['switch-checkbox']}
            id={id}
            name={name}
            type="checkbox"
            checked={isOn}
            value={value}
            onChange={onChangeHandler}
          />
          <label
            className={cx(
              style['switch-label'],
              { [style['switch-label--on']]: isOn },
              { [style.disabled]: notAllowed },
            )}
            style={{ cursor: `${cursorType}` }}
            htmlFor={id}
          >
            <span className={style['switch-button']} />
          </label>
        </span>
        {caption && <div className={style.caption}>{caption}</div>}
      </div>
    </div>
  );
};

export default SwitchButton;
