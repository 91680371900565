import { useState } from 'react';
import cx from 'classnames';
import Icon from '@/components/general/Icon';
import style from './pre-order-form.module.scss';

const RenderTable2PreOrder = props => {
  const { isMobile } = props;
  const [activeIndex, setActiveIndex] = useState(-1);
  const handleClick = index => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  const tableData = [
    {
      otherPaymentsValue: 'Modification Charge',
      fixedOrVarValue: 'Variable',
      totalChargeValue: (
        <p className={style.tableText}>
          The Driver shall be responsible for any costs in returning the Vehicle to its pre-modified
          condition upon return of the Vehicle to Drive Fuze where any modifications have been
          carried out on, or anything has been attached or affixed to, the Vehicle without Drive
          Fuze&apos;s written permission.
        </p>
      ),
    },
    {
      otherPaymentsValue: 'Repairs',
      fixedOrVarValue: 'Variable',
      totalChargeValue: (
        <>
          <p className={style.tableText}>
            The Driver will be liable for repairs (which must be carried out by a repair centre
            approved by Drive Fuze) and all charges if the vehicle has been damaged inside or
            outside (whether or not it&apos;s the Driver&apos;s fault) at any time when such damage
            is discovered or reported. This includes but is not limited to:
          </p>
          <p className={cx(style.tableText, style['mt-16'])}>
            i. after the discovery of damage following re-inspection of the Vehicle when returned by
            the Driver to Drive Fuze;
          </p>
          <p className={style.tableText}>
            ii. if the Vehicle needs more than Drive Fuze&apos;s standard valeting (cleaning) upon
            return of the Vehicle to Drive Fuze.
          </p>
        </>
      ),
    },
    {
      otherPaymentsValue: 'Insurance Excess and  premiums',
      fixedOrVarValue: 'Variable',
      totalChargeValue: (
        <p className={style.tableText}>
          The Driver is responsible for paying any insurance excess charges to the repair centre
          which repairs the Vehicle.
        </p>
      ),
    },
    {
      otherPaymentsValue: 'Cancellation Fee',
      fixedOrVarValue: 'Variable',
      totalChargeValue: (
        <>
          <p className={style.tableText}>
            If the Driver cancels the Subscription (but only where the Vehicle has been delivered to
            the Driver) within 14 days of this agreement being conﬁrmed, a pro-rata fee will be
            applied at the Daily Subscription Rate for the numbers of days the Vehicle has been on
            Subscription.
          </p>

          <p className={cx(style.tableText, style['mt-16'])}>
            If the Driver cancels the Subscription (whereby no Vehicle has been delivered to the
            Driver) within 14 days of this agreement being conﬁrmed, the Subscription Fees and
            Initial Payment paid in advance by the Driver will be refunded. Please refer to your
            Subscription Agreement when booking for further details.
          </p>

          <p className={cx(style.tableText, style['mt-16'])}>
            At any point before the Subscription Start Date, the Driver can cancel the subscription.
            The Initial Payment paid in advance by the Driver will be refunded (refunds can take up
            to 10 working days to appear in the Driver&apos;s bank account). We shall retain £100 as
            a cancellation charge if cancelled within 10 days of order or £500 as a cancellation
            charge if longer to cover the vehicle administration and holding cost.
          </p>
        </>
      ),
    },
    {
      otherPaymentsValue: 'Termination charges',
      fixedOrVarValue: 'Variable',
      totalChargeValue: (
        <>
          <p className={style.tableText}>
            Upon termination of this Agreement, the Driver shall also be liable for:
          </p>
          <p className={cx(style.tableText, style['mt-16'])}>
            i. any arrears of Subscription Fees and other amounts due and payable at the time
            (including for the avoidance of doubt any Fines, Tolls and other charges);
          </p>
          <p className={style.tableText}>
            ii. any other amounts which may become due or payable under this Agreement;
          </p>
        </>
      ),
    },
  ];

  return (
    <div className={style.tableType_2}>
      <table className={style.tableHeader}>
        <tr>
          <th>Other Payments</th>
          <th>Fixed or Variable</th>
          {!isMobile && <th>Total Charge</th>}
        </tr>
      </table>
      {tableData.map((item, index) => (
        <table key={index} className={style.tableBlock}>
          <tr>
            <th>{item.otherPaymentsValue}</th>
            <th>{item.fixedOrVarValue}</th>
            {!isMobile ? (
              <th>{item.totalChargeValue}</th>
            ) : (
              <th onClick={() => handleClick(index)}>
                <Icon
                  icon={'cancel-big'}
                  size={20}
                  color="#001E78"
                  style={{
                    rotate: index !== activeIndex ? '135deg' : '',
                    transition: 'ease 0.35s',
                  }}
                />
              </th>
            )}
          </tr>
          {index === activeIndex && (
            <tr className={style.accordion}>
              <td colSpan={3}>{item.totalChargeValue}</td>
            </tr>
          )}
        </table>
      ))}
    </div>
  );
};
export default RenderTable2PreOrder;
