import React from 'react';
import { Icon } from '@/components/general';
import style from './socialMediaIcons.module.scss';

const socialMediaArr = [
  { id: 1, title: 'Facebook', iconName: 'facebook', link: 'https://www.facebook.com/DriveFuze' },
  {
    id: 2,
    title: 'Instagram',
    iconName: 'instagram',
    link: 'https://www.instagram.com/drivefuze_/',
  },
  {
    id: 3,
    title: 'LinkedIn',
    iconName: 'linkedin',
    link: 'https://www.linkedin.com/company/drive-fuze-ltd/',
  },
  { id: 4, title: 'Twitter', iconName: 'twitter', link: 'https://twitter.com/DriveFuze_' },
];
const SocialMediaIcons = () => {
  return (
    <div className={style.container}>
      {socialMediaArr.map(item => (
        <div key={item.id}>
          <a className={style.wrapper} href={item.link} rel="noopener noreferrer" target="_blank">
            <Icon icon={item.iconName} size={20} color="#001E78" />
          </a>
        </div>
      ))}
    </div>
  );
};

export default SocialMediaIcons;
