import { combineReducers } from 'redux';
import loadingReducer from '@/components/Layout/LoaderAnimation/reducer';
import loginReducer from '@/flow/Login/reducer';
import browseVehiclesReducer from '@/flow/BrowseVehicles/reducer';
import bookingReducer from '@/flow/Order/BookingFlow/reducer';
import signupReducer from '@/flow/CustomerRegistration/reducer';
import accountReducer from '@/flow/MyAccount/reducer';
import returnVehicleReducer from '@/flow/Bookings/BookingDetails/ReturnVehicle/reducer';
import additionalServiceReducer from '@/flow/Bookings/BookingDetails/AdditionalServices/reducer';
import addDriverReducer from '@/flow/Bookings/BookingDetails/AddAdditionalDriver/reducer';
import removeDriverReducer from '@/flow/Bookings/BookingDetails/RemoveAdditionalDriver/reducer';
import swapReducer from '@/flow/SwapVehicle/reducer';

const appReducer = combineReducers({
  loadingReducer,
  loginReducer,
  browseVehiclesReducer,
  bookingReducer,
  signupReducer,
  accountReducer,
  returnVehicleReducer,
  additionalServiceReducer,
  addDriverReducer,
  removeDriverReducer,
  swapReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
