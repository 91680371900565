/* eslint-disable default-param-last */
import ACTION_TYPE from './action-type';

const initialState = {
  selectedTab: 'COLLECTION',
  additionalServices: [],
  milePrice: {},
  mileageBandPrice: null,
  mileageBand: null,
  selectedMileage: null,
};

const additionalServiceReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTION_TYPE.GET_MILEAGE_BAND_INFO:
      return {
        ...state,
        additionalServices: action.payload,
      };
    case ACTION_TYPE.GET_PRICE_INFO:
      return {
        ...state,
        milePrice: action.payload,
      };
    case ACTION_TYPE.UPDATE_PRICING:
      return {
        ...state,
        mileageBandPrice: action.payload.newPrice,
        mileageBand: action.payload.newValue,
      };
    case ACTION_TYPE.SELECTED_MILEAGE_BAND_DATA:
      return { ...state, selectedMileage: action.payload.value };

    case ACTION_TYPE.SWITCH_TAB_ADDITIONAL_SERVICES:
      return { ...state, selectedTab: action.payload.value };

    case ACTION_TYPE.RESET_INITIAL_STATE:
      return {
        ...state,
        selectedTab: 'COLLECTION',
        additionalServices: [],
        milePrice: {},
        mileageBandPrice: null,
        mileageBand: null,
        selectedMileage: null,
      };
    default:
      return state;
  }
};

export default additionalServiceReducer;
