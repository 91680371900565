const LOGIN_TYPE = {
  LOGIN: 'LOGIN',
  LOGIN_WITH_COOKIE_SUCCESS: 'LOGIN_WITH_COOKIE_SUCCESS',
  LOGIN_WITH_COOKIE_FAILURE: 'LOGIN_WITH_COOKIE_FAILURE',
  LOGIN_CHECK: 'LOGIN_CHECK',
  VERIFY_OTP: 'VERIFY_OTP',
  LOGIN_WITH_OTP: 'LOGIN_WITH_OTP',
  LOGIN_PENDING_REGISTRATION: 'LOGIN_PENDING_REGISTRATION',
  USER_DETAILS: 'USER_DETAILS',
  CLEAR_SIGN_UP_FORM: 'CLEAR_SIGN_UP_FORM',
  TEMP_USER_DATA: 'TEMP_USER_DATA',
  USER_LOGOUT: 'USER_LOGOUT',
  TOKEN: 'TOKEN',
  FEATURE_VEHICLE: 'FEATURE_VEHICLE',
  FEATURE_VEHICLE_LIST: 'FEATURE_VEHICLE_LIST',
};
export default LOGIN_TYPE;
