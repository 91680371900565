export const subscriptionList = [
  {
    entry: {
      mainText: `The Subscription Agreement made between Drive Fuze Limited (Drive Fuze) and the Driver is constituted by the front pages (which we refer to as the "Subscription Agreement") and these Terms of Subscription which form part of and are incorporated within the Subscription Agreement made between Drive Fuze and the Driver. Drive Fuze reserve the right to make favourable changes to the Terms of Subscription without notice and without incurring any liability to the Driver. If material amendments are made to the Subscription Agree mentor Charges, then the Driver shall receive not less than one month's notice by e-mail.`,
    },
  },
  {
    entry: {
      mainText: `The Driver agrees to Subscribe the Vehicle referred to in the Subscription Agreement for the Subscription Term and any further period as provided for in this Agreement. The Vehicle is provided by Drive Fuze at the relevant Subscription Fee stated in the Subscription Agreement. If the Driver does not return the Vehicle to Drive Fuze by the Minimum Subscription Term end date (or such Extension Period as provided for in this Agreement), the Driver will be in breach of the conditions of this Agreement. For the avoidance of doubt, the Driver will not own the Vehicle.`,
    },
  },
  {
    entry: {
      mainText: `Drive Fuze may substitute the Vehicle for another vehicle (of a similar age, quality and specification) on at least 14 days' notice at any point during this Agreement. Drive Fuze may also substitute the Vehicle for another vehicle in the event of a manufacturer's recall, or for other legitimate mechanical, health or safety purposes. The Driver will cooperate with the reasonable requirements of Drive Fuze as to substitution of the Vehicle and, following substitution, the new Vehicle will be the “Vehicle” for the purpose of this Agreement.`,
    },
  },
];

export const responsibilitiesList = [
  {
    entry: {
      mainText: (
        <span>
          Drive Fuze, or a third party on behalf of Drive Fuze, will deliver the Vehicle to an
          address specified by the Driver on the Subscription Start Date and collect the Vehicle
          upon Termination (unless alternative arrangements are agreed by Drive Fuze and the Driver
          in writing). The Driver must inspect the Vehicle before the start of the Subscription
          Term. The Driver will make Drive Fuze aware of any defects in the Vehicle at the time when
          the Vehicle is delivered. In the absence of the Driver notifying Drive Fuze of any issues
          at the time when the Vehicle is received by the Driver, it shall be deemed that the Driver
          received the Vehicle in perfect working order (save for any existing damage recorded in
          the Vehicle Condition Report which the Driver will sign on delivery). The Driver will
          return the Vehicle to Drive Fuze in the same condition in which the Driver received it,
          save for the normal wear and tear in relation to distance travelled, including(but not
          limited to) tyres, fittings, documents, and complete equipment outfit, in line with BVRLA
          Fair Wear& Tear standards{' '}
          <a
            href="https://issuu.com/bfwsn67/docs/drive_fuze?fr=sYjI3OTYzODk0NTI"
            target="_blank"
            rel="noreferrer noopener"
            style={{ textDecoration: 'underline' }}
          >
            here
          </a>
          . The Driver must take care of, and keep secure, the Vehicle and its keys. The Driver must
          always lock the Vehicle when not using it (and will be charged for the replacement cost of
          any lost keys). The Driver must make sure that they use the correct fuel. The Driver is
          also responsible for tyre punctures. The Driver shall return the vehicle with no less than
          1/4 tank of fuel or charge (if it is an Electric Vehicle) or pay the shortfall and any
          collection costs incurred such as waiting time or vehicle transporter costs.
        </span>
      ),
    },
  },
  {
    entry: {
      mainText: `Drive Fuze has the right to request and conduct a Vehicle inspection during the Subscription Term, subject to giving the Driver 7 days' prior notice. The Driver shall act in good faith and reasonably comply with such a request.`,
    },
  },
  {
    entry: {
      mainText: `The Driver must not sell, rent, sub-hire or dispose of the Vehicle or any of its parts. The Driver must not give anyone other than Drive Fuze any legal rights over the Vehicle.`,
    },
  },
  {
    entry: {
      mainText: `The Driver must not (and must not let anyone else) modify or work on or attach or affix anything to the Vehicle without Drive Fuze's written permission. Any additions, alterations or modified parts fitted without such permission shall become part of the Vehicle and shall belong to Drive Fuze, and the Driver shall be responsible for any costs in returning the Vehicle to its pre-modified condition.`,
    },
  },
  {
    entry: {
      mainText: `The Driver must let Drive Fuze know as soon as they become aware of any defect(s) in or damage to the Vehicle, including punctures, at any point if or when the Driver discovers any issues or defects during the Subscription Term. If the Driver fails to let Drive Fuze know of any defect(s) in or damage to the Vehicle, and the defects or damages are discovered at the end of the Subscription Term or following the return and inspection of the Vehicle, the Driver may be liable for charges.`,
    },
  },
  {
    entry: {
      mainText: `The Driver shall be liable for any damage to, and theft of, the Vehicle from delivery of the Vehicle until Drive Fuze has collected the Vehicle from the Driver. The Driver must meet with the collection agent at point of collection (the Subscription end date or such Extension Period as provided for in this Agreement or as otherwise agreed between the Parties in writing and in accordance with this Agreement). Drive Fuze, or a third party on behalf of Drive Fuze may inspect the Vehicle. Where the Driver has requested a collection of the Vehicle by Drive Fuze at a specified location, outside of the hours of 09:00 to 18:00 Monday to Friday and 09:00 to 13:00 Saturday , the Subscription Term (and the Driver’s liability for damage, theft and parking violations) shall extend to the earlier of: midday of the next available collection day following the requested collection time or the time of re-inspection by Drive Fuze; or of a third party on behalf of Drive Fuze.`,
    },
  },
  {
    entry: {
      mainText: `The Driver shall be required to return the Vehicle, as detailed in 2.1 above, in a clean and valeted “Valeted” condition,  including both the interior and exterior of the Vehicle. In the event that a Vehicle has not been Valeted, this may prohibit a BVRLA Fair Wear & Tear standards Vehicle inspection.  The Vehicle will be Valeted by Drive Fuze upon its return and re-inspected. The Driver acknowledges that damage which was not captured due to a Vehicle not being Valeted will be recharged. The Driver may also be charged for a Valet.`,
    },
  },
  {
    entry: {
      mainText: `The Driver will be liable for repairs (which must be carried out by our repair centre approved by Drive Fuze) if the Vehicle needs more than Drive Fuze's standard valeting (cleaning), or if the Vehicle has been damaged either inside or outside (whether or not it is the Driver's fault). Any insurance coverage may cover such repairs and charges but in the event it does not, the Driver will be separately charged by Drive Fuze for any such repairs and charges.`,
    },
  },
  {
    entry: {
      mainText: `The Driver must check before they return the Vehicle that they have not left any belongings in the Vehicle. Drive Fuze is not liable for any personal belongings left in the Vehicle after the Vehicle has been returned.`,
    },
  },
  {
    entry: {
      mainText: `The Driver will be liable for any Excess Mileage Charge as set out in the Subscription Agreement if the Driver exceeds the Mileage Allowance during the Subscription Term.`,
    },
  },
  {
    entry: {
      mainText: `The Driver shall comply with its insurance obligations, as set out in condition 7 below.`,
    },
  },
  {
    entry: {
      mainText: `If the Driver wishes to alter the collection or drop-off arrangements, they must obtain prior written consent from Drive Fuze for such alternative arrangements and this may incur additional costs for the Driver.`,
    },
  },
];

export const driveFuzeResponsibilitiesList = [
  {
    entry: {
      mainText: `Drive Fuze, or a third party on behalf of Drive Fuze, will maintain the Vehicle to at least the manufacturer's recommended standard and the Driver must ensure that all servicing and repairs are carried out by a party approved by Drive Fuze.`,
    },
  },
  {
    entry: {
      mainText: `Drive Fuze confirms that the Vehicle is roadworthy and suitable for driving at the Subscription Start Date. Drive Fuze, or a third party on behalf of Drive Fuze, will identify any existing damage to the Vehicle on the Vehicle Condition Report which will be signed by Drive Fuze in accordance with condition 2.1 above.`,
    },
  },
  {
    entry: {
      mainText: `Drive Fuze will deliver the vehicle to the Driver with at least 1/4 tank of fuel or equivalent electric charge.`,
    },
  },
];

export const liabilityList = [
  {
    entry: {
      mainText: `Subject to condition 4.2 below, Drive Fuze's maximum aggregate liability to the Driver for any and all claims arising out of or in connection with this Agreement shall in no event exceed £10,000.`,
    },
  },
  {
    entry: {
      mainText: `Nothing in this Agreement excludes or restricts Drive Fuze's liability to the Driver for death or personal injury caused by Drive Fuze's negligence, for fraud and fraudulent misrepresentation, or for any other liability that cannot be excluded or limited under applicable law.`,
    },
  },
  {
    entry: {
      mainText: `Notwithstanding condition 4.1 above, Drive Fuze shall not be liable for:`,
      subText: [
        {
          subList: `indirect losses which happen as a side effect of the main loss or damage and which are not reasonably foreseeable by Drive Fuze and the Driver at the time of entering into this Agreement (such as loss of profits, income or loss of opportunity);`,
        },
        { subList: `losses not caused by Drive Fuze's breach; or ` },
        {
          subList: `failure or delay in performing any or all of its obligations under this Agreement, where such failure is caused, directly or indirectly, by events beyond Drive Fuze's reasonable control (including, but not limited to, network failure, fire, flood, earthquake, acts of God, acts of war, terrorism, riots, civil disorders, blockades, insurrections, pandemics, epidemics, any law or action taken by a government or public authority, any labour or trade disputes, strikes, industrial action or lockouts, or non-performance by suppliers or subcontractors).`,
        },
      ],
    },
  },
  {
    entry: {
      mainText: `Restriction of Liability`,
      subText: [
        {
          subList: `Where the Driver is dealing as a consumer or (in Scotland) where this Agreement is a consumer contract as defined respectively in Sections 12 and 15 of the Unfair Contract Terms Act 1977, but not otherwise, the Vehicle is hired subject to the conditions and warranties implied by the Consumer Rights Act 2015.`,
        },
        {
          subList: `Save aforesaid, since the Vehicle has been selected by the Driver, Drive Fuze does not make or give any representation, condition, warranty or undertaking as to the state, quality, performance, fitness for any particular purpose or correspondence with description or sample of the Vehicle and all representations, conditions, warranties or undertakings as to such matters implied by statute, law or otherwise are expressly excluded by Drive Fuze to the fullest extent permitted by law, and Drive Fuze shall have no liability to the Driver in respect of the Vehicle in contract or in tort save for liability for death or personal injury caused by the negligence of Drive Fuze.
`,
        },
      ],
    },
  },
];

export const vehicleConditionList = [
  {
    entry: {
      mainText: `The Vehicle must only be driven by the Driver (plus any Named Driver in the Subscription Agreement), must be driven in a careful manner at all times and must be driven in compliance with all applicable road traffic laws (and any other laws or regulations applicable to drivers). If a Named Driver is allowed to drive the Vehicle under this Agreement, the Driver must ensure that the Named Driver complies with all the obligations of the Driver under this Agreement, as if that Named Driver were a Driver. The Driver will remain responsible for the use of the Vehicle by any Named Drivers and any breaches of this Agreement by a Named Driver will be deemed a breach of this Agreement by the Driver.`,
    },
  },
  {
    entry: {
      mainText: `The Driver and any Named Driver of the Vehicle must have held a full current UK driving licence for at least one year, be at least 25 years old, no older than 74 years and 6 months and have no endorsements or convictions on their driving licence during the Subscription Term which are unacceptable to Drive Fuze. The Driver and any Named Drivers must not have any criminal convictions which are unacceptable to Drive Fuze. Unacceptable endorsements or convictions may include, but are not limited to, any convictions that result in the Driver being disqualified from driving, or any major driving convictions, such as failing to report an accident and being sentenced or associated with a fraud conviction. If the Driver or a Named Driver receives any endorsements or convictions during the Subscription Term the Driver and/or Named Driver must inform Drive Fuze, and Drive Fuze may terminate this Subscription Agreement in accordance with condition 11. The Driver must also inform Drive Fuze of such endorsements or convictions, as this may affect the validity of the insurance.`,
    },
  },
  {
    entry: {
      mainText: `The Driver and any Named Driver must not:`,
      subText: [
        {
          subList: `use the Vehicle in connection with your business other than commuting to a single place of work;`,
        },
        {
          subList: `use the Vehicle to transport any hazardous, toxic, flammable, corrosive, radioactive, harmful, dangerous or illegal materials; `,
        },
        {
          subList: `use the Vehicle for any illegal purpose or any purpose or any manner which would not be covered by the insurance and/or would invalidate the insurance;`,
        },
        {
          subList: `use the vehicle for any illegal purpose, or any purpose or any manner that could be deemed harmful to the business or reputation of Drive Fuze. This includes the Vehicle or Driver being part of a police investigation. Drive Fuze reserve the right to terminate the subscription until the investigation is over, and depending on the verdict, remove the driver from the Drive Fuze platform;`,
        },
        {
          subList: `use the Vehicle off-road, or for racing, pace making, testing the Vehicle's reliability and speed, or teaching someone to drive;`,
        },
        {
          subList: `use the Vehicle under the influence of alcohol or drugs. This will result in the Driver's insurance becoming invalid;`,
        },
        {
          subList: `smoke inside the vehicle. This will result in an additional charge of at least £500, see condition 6.5 below;`,
        },
        {
          subList: `carry a number of passengers and/or baggage which would cause the Vehicle to be overloaded (and operate the Vehicle otherwise than in accordance with the owner's manual for the Vehicle); or - in the case of a commercial vehicle - carry a carrier payload which exceeds the maximum payload and individual axle plated weights, or for a purpose which requires an operator's licence where the Driver (or Named Driver as applicable) does not have one;`,
        },
        {
          subList: `drive the Vehicle outside the United Kingdom unless Drive Fuze has given the Driver written permission in advance; or`,
        },
        { subList: `use the Vehicle for hire and reward.` },
      ],
    },
  },
];

export const dataProtectionList = [
  {
    entry: {
      mainText: `Drive Fuze shall comply at all times with all Data Protection Legislation. In respect of processing the Driver's Personal Data, Drive Fuze warrants, represents and undertakes that it shall take all appropriate technical and organisational measures against unauthorised or unlawful processing of any Driver Personal Data and against accidental loss or destruction of, or damage to Driver's Personal Data, including (without limitation) by:`,
      subText: [
        {
          subList: `taking reasonable steps to ensure the reliability of any Staff who have access to Driver's Personal Data, and ensuring that only those Staff that need to have access to the Driver's Personal Data are given access to the extent reasonably necessary and that such Staff are subject to appropriate confidentiality obligations;`,
        },
        {
          subList: `ensuring a level of security appropriate to the nature of the Driver's Personal Data and the risks that are presented by its processing;`,
        },
      ],
    },
  },
  {
    entry: {
      mainText: `Drive Fuze shall ensure that any authorised third party to whom it discloses any of the Driver's Personal Data is:`,
      subText: [
        {
          subList: `subject to appropriate confidentiality and data security obligations; and`,
        },
        {
          subList: `required to comply with the Data Protection Legislation in respect of its processing of the Driver's Personal Data.`,
        },
      ],
    },
  },
];

export const serviceList = [
  {
    entry: {
      mainText: `Drive Fuze is responsible for the organisation of all routine servicing and maintenance from the Subscription Start Date to the Subscription End Date (which includes tyres, maintenance and arranging MOTs). Drive Fuze, or a third party operating on behalf of Drive Fuze, will provide fair and reasonable notice of no less than 7 days to the Driver when arranging servicing or maintenance appointments, advise the Driver of these and coordinate schedules with the Driver. It is the Driver's responsibility to keep these agreed appointments and otherwise bear any rescheduling costs if an appointment is missed.`,
    },
  },
  {
    entry: {
      mainText: `Breakdown cover is included in the Subscription Fee and provided by Multifleet Vehicle Management Limited, Trading as RunYourFleet, where the fault is determined mechanical. It is the Driver's responsibility to keep agreed breakdown callouts and otherwise bear any cancellation costs if a callout is missed. Drivers must refer to and follow the breakdown information as part of Drivers subscription confirmation. For an additional cost, breakdown cover can be provided by RunYoyurFleet (or such other reputable motor organisation chosen by Drive Fuze) for Drivers taking the Vehicle into the EU. The additional cost will be cost dependent on the length of time outside the UK. Drivers should contact subscriptions@drivefuze.com if there are any questions in relation to the breakdown policy.`,
    },
  },
];

export const telematicsList = [
  {
    entry: {
      mainText: `Drive Fuze may, at its sole discretion, install a telematics solution ("Telematics") in the Vehicle at Drive Fuze's own cost. Telematics allows Drive Fuze to GPS track Vehicles as well as extract mileage, fuel level, acceleration, maintenance, carbon footprint and braking data, as well as remotely lock / unlock or immobilise Vehicles. By using the Vehicle, the Driver consents to the collection of such data and should refer to the Telematics Terms and Conditions for more information.`,
    },
  },
];

export const governingLawList = [
  {
    entry: {
      mainText: `These Terms of Subscription and any Agreement shall be governed by and construed in accordance with English law. Both Drive Fuze and the Driver agree to submit to the non-exclusive jurisdiction of the English courts.`,
    },
  },
];

export const generalList = [
  {
    entry: {
      mainText: `Any notice or other communication given to the Driver in connection with this Agreement may be sent by email to the latest electronic address held by Drive Fuze or by post to such address as Drive Fuze holds for the Driver. Any notice or other communication given to Drive Fuze in connection with this Agreement should be sent by email to subscriptions@drivefuze.com or by post to Drive Fuze Limited, Denbigh House, Denbigh Road, Milton Keynes MK1 2DF.`,
    },
  },
  {
    entry: {
      mainText: `Except as otherwise agreed in this Agreement and required by law, each party agrees with the other to keep secret and not share (except with its employees, contractors, other relevant third parties and advisers (where relevant)) any confidential information it receives from the other party through this Agreement.`,
    },
  },
  {
    entry: {
      mainText: `If any part of these terms is disallowed or found to be ineffective by a court or regulator, the other provisions shall continue to apply.`,
    },
  },
  {
    entry: {
      mainText: `If either party does not take action against the other party, the party who chose not to take action is still entitled to use its rights and remedies in any other situation when this Agreement is breached.`,
    },
  },
  {
    entry: {
      mainText: `This Agreement is for the benefit of the Driver and Drive Fuze where stated in this Agreement, and no term of this Agreement will be enforceable by any other person that is not a party to it including any enforcement through the Contracts (Rights of Third Parties) Act 1999.`,
    },
  },
  {
    entry: {
      mainText: `Drive Fuze expressly reserves the right to sub-contract any of its obligations under this Agreement to a third party, provided that Drive Fuze shall remain primarily responsible for, and liable to the Driver for, the performance of such obligations.`,
    },
  },
  {
    entry: {
      mainText: `We can transfer our rights under this Agreement. We can also in certain circumstances transfer our obligations. You may not transfer your rights or obligations under this Agreement.`,
    },
  },
  {
    entry: {
      mainText: `The Driver agrees this Agreement may be entered into and signed by way of electronic signature or advanced electronic signature as defined in the Electronic Communications Act 2000. Any signature made by the Driver in a way which complies with the Electronic Communications Act 2000 will be effective and binding on the Driver.`,
    },
  },
];

export const insuranceList = [
  {
    entry: {
      mainText: `Drive Fuze has arranged its motor insurance for consumer vehicles through Churchill Expert which is a trading name of UK Insurance Business Solutions Limited**, an insurance intermediary who introduces customers to NIG policies underwritten by UK Insurance.`,
      newParagraphNoPoint: {
        text: `The policy provides coverage for accidental damage, fire & theft and third-party liability claims. The insurance cover will be stated in the Subscription Agreement and the details of the policy and insurance product information document (IPID) will be sent to the Driver on booking confirmation and can also be found in the Drivers Drive Fuze account.”`,
        className: `mainText`,
      },
      caption: `**UK Insurance Business Solutions Limited is authorised and regulated by the Financial Conduct Authority Registration number: 313783. Registered office: Churchill Court, Westmoreland Road, Bromley, Kent, BR 1DP Registered in England and Wales No: 05196274. NIG insurance policies are underwritten by UK Insurance Limited. Registered office: The Wharf, Neville Street, Leeds LS1 4AZ Registered in England and Wales No.1179980. UK Insurance Limited is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority. Registration number 202810`,
    },
  },
  {
    entry: {
      mainText: `For an additional cost, insurance cover can be provided by NIG for Drivers taking the Vehicle into the EU. The additional cost will be cost dependent on the length of time outside the UK. Drivers should contact subscriptions@drivefuze.com if there are any questions in relation to the insurance policy.`,
    },
  },
];

export const continuousPaymentList = [
  {
    entry: {
      mainText: `The Driver hereby authorises Drive Fuze to collect any amounts for which the Driver is liable under this Agreement by charging a credit or debit card, the details of which have been provided to Drive Fuze by the Driver. Making regular collections from the Driver's credit or debit card is called a continuous payment authority. The Driver shall only provide Drive Fuze with debit or credit card details for an account which belongs to the Driver and from which the Driver is authorised to make payments. The Driver acknowledges and agrees that Drive Fuze uses continuous payment authority to collect Subscription Fees on the Payment Dates and all other sums which are due and payable by the Driver under this Agreement including all the charges and fees set out in the Subscription Agreement.`,
    },
  },
  {
    entry: {
      mainText: `The Driver may amend their continuous payment authority by emailing subscriptions@drivefuze.com subject to the agreement of Drive Fuze.`,
    },
  },
  {
    entry: {
      mainText: `If Drive Fuze makes reasonable attempts to contact the Driver following the payment failures, but these attempts are still unsuccessful, Drive Fuze will retry the payment on the 6th day following the date on which the first payment fails (the Subsequent Payment Attempt). If the Subsequent Payment Attempt fails, Drive Fuze will suspend payment attempts again whilst continuing to try and make contact with the Driver to understand their position and the circumstances surrounding the non-payment.`,
    },
  },
  {
    entry: {
      mainText: `If Drive Fuze are unable to make contact with the Driver and the Driver does not engage with Drive Fuze by responding to correspondence Drive Fuze will arrange for the collection of the vehicle. Drive Fuze may immobilise the vehicle and will notify the Driver that Drive Fuze may initiate legal action to recover the outstanding debt, including a County Court Judgement being taken out against the Driver.`,
    },
  },
];

export const tollsList = [
  {
    entry: {
      mainText: `The Driver must pay the Initial Payment and the Subscription Fees at the rate and on the dates specified in the Subscription Agreement. The Driver shall be liable for the following charges and pay them on demand during and/or following the Subscription Term:`,
      subText: [
        {
          subList: `all charges which are payable after the discovery of damage following re-Inspection of the Vehicle when returned by the Driver to Drive Fuze;`,
        },
        {
          subList: `all charges, fines and court costs including congestion charges, parking, traffic, speeding or other offences, and any civil penalty payable relating to the Vehicle (“Fines”) and any tolls, towing away or clamping fees or charges including toll road fees, and Congestion Charges (“Tolls”). Drive Fuze will pay all charges and fines and pass on the costs to the Driver including the administration charge set out in 6.1.3. Drive Fuze will not pay any fines or charges directly in the instance of a speeding or custodial fine. In these Instances Drive Fuze is obligated by law to pass on the primary driver details to the relevant police authority.`,
        },
        {
          subList: `any value added tax and all other taxes and levies on any of the Fines, Tolls and charges, as appropriate including a £50 administration fee for each Fine, Toll or charge that the Driver incurs during the term of their subscription;`,
        },
        {
          subList: `any reasonably incurred, foreseeable losses, costs and charges resulting from the breach by the Driver of this Agreement (such losses being foreseeable where they are contemplated by Drive Fuze and the Driver at the time this Agreement is entered into);`,
        },
        {
          subList: `any other charges arising under this Agreement, including Vehicle delivery and collection fees; and`,
        },
        {
          subList: `any charges in relation to the repossession of the Vehicle, including, without limitation, third party agent costs, transportation required for the repossession of the Vehicle, legal proceedings in relation to the repossession of the Vehicle and any other costs, charges and expenses in relation to or in connection with a breach of this Agreement by the Driver.`,
        },
      ],
    },
  },
  {
    entry: {
      mainText: `For the avoidance of doubt, the Driver shall be liable for any Fines, Tolls and other charges, issued by public authorities or private parking companies, incurred during the Subscription Term even if such Fines, Tolls and other charges are not discovered until after the end of the Subscription Term.`,
    },
  },
  {
    entry: {
      mainText: `It is the responsibility of the Driver to pay the relevant authorities directly for any speeding or custodial Fines. In the case of any speeding or custodial Fine, the Driver acknowledges and agrees that Drive Fuze may pass on the Driver's details to the police or relevant authority, who may then contact the Driver directly. Drive Fuze is not liable for any escalation in value of a Fine as a result of it being delivered to an out of-date address. It is the Driver's responsibility to inform Drive Fuze of any change of address so that Fines may be delivered to them in sufficient time to prevent escalation.`,
    },
  },
  {
    entry: {
      mainText: `The Driver shall keep the Vehicle in good and roadworthy condition (fair wear and tear excepted) throughout the Subscription Term and return the Vehicle in good and roadworthy condition to Drive Fuze at the end of the Subscription Term.`,
    },
  },
  {
    entry: {
      mainText: `If the Driver and/or any Named Driver or any other person smoke inside the Vehicle (for the avoidance of doubt this includes the use of electronic smoking devices), the Driver shall be liable to pay Drive Fuze a fixed sum as compensation of £500. Please note additional charges from damage caused by smoking (cigarette burn in a seat for example) will also be incurred in addition to the £500 charge.`,
    },
  },
  {
    entry: {
      mainText: `Drive Fuze credit applied to the Driver's account during their Subscription has no cash redemption value and cannot be exchanged for cash. Applied credit can only be used on a Drive Fuze Subscription.`,
    },
  },
  {
    entry: {
      mainText: `The Initial Payment will be returned to the Driver 30 days after the termination of this Agreement less:`,
      newParagraphNoPoint: {
        text: `(a) any sums then due to Drive Fuze from the Driver under this Agreement.`,
        className: `subText`,
        padding: `16px`,
      },
    },
  },
];

export const damageList = [
  {
    entry: {
      mainText: `In the event of an accident involving the Vehicle, the Driver or Named Driver must not admit responsibility or attempt to negotiate with third parties. The Driver or Named Driver should note the registration numbers of other vehicles, take photographs and/or video of the scene and vehicles involved and obtain the names and addresses of everyone involved, including witnesses and should also:`,
      subText: [
        {
          subList: `make the Vehicle secure`,
        },
        {
          subList: `tell the police without delay if anyone is injured or if the Driver or Named Driver was unable to exchange details with other drivers or property owners;`,
        },
        {
          subList: `report the incident to the Vehicle insurer NIG by contacting 0345 300 4644 and Drive Fuze 0330 118 0947 as soon as is practically possible and no later than 24 hours after the incident;`,
        },
        {
          subList: `note the insurance details of other involved third parties; and`,
        },
        {
          subList: `comply with all other applicable road traffic and other laws`,
        },
      ],
    },
  },
  {
    entry: {
      mainText: `In the event of damage to the vehicle, the Driver must comply with the instructions of Drive Fuze (and/or the insurers of the Vehicle). The Driver (and Named Driver(s) if relevant) must provide all reasonable co-operation and assistance to the insurer and Drive Fuze as to the conduct of any claim. If the Vehicle is stolen, the Driver must inform the police and Drive Fuze as soon as becoming aware of the theft.`,
    },
  },
  {
    entry: {
      mainText: `If a warning light appears in the Vehicle or the Vehicle develops any fault during the Subscription Term, the Driver must contact Drive Fuze as soon as practicable to provide details of the fault.`,
    },
  },
  {
    entry: {
      mainText: `In the event of any claim under the insurance set out in under condition 7, the Driver (and where applicable, Named Drivers) must:`,
      subText: [
        {
          subList: `comply with the instructions of Drive Fuze (and/or insurer) as to the Vehicle, including payment of excess; and`,
        },
        {
          subList: `provide all reasonable co-operation and assistance to Drive Fuze (and/or insurer) as to the conduct of any claim.`,
        },
      ],
    },
  },
  {
    entry: {
      mainText: `In the event of any accident involving the Driver which is likely to have been wholly or mainly the fault of a party other than the Driver (“Non-Fault Accident”), the Driver (and where applicable any Named Driver):`,
      subText: [
        {
          subList: `shall comply with the instructions of Drive Fuze (and/or insurer) as to the Vehicle; and`,
        },
        {
          subList: `is obliged to follow Drive Fuze's instructions as to having the Vehicle repaired, either by making it available to Drive Fuze at a time of its choosing or by making arrangements at Drive Fuze's direction for the Vehicle to the repaired; and`,
        },
        {
          subList: `must provide all reasonable co-operation and assistance to Drive Fuze as to the conduct of any claim;`,
        },
        {
          subList: `must continue to pay the Subscription Fees under this Agreement during the Subscription Term (and for any further period while the Vehicle is in fact under repair and/or otherwise not available for use); and`,
        },
        {
          subList: `will have no right under this Agreement to receive a replacement vehicle from Drive Fuze while the Vehicle is under repair or otherwise unavailable to the Driver.`,
        },
      ],
    },
  },
  {
    entry: {
      mainText: `In the event of a Non-Fault Accident;`,
      subText: [
        {
          subList: `NIG will inform the Driver of the names and contact details of one or more vehicle hire providers who might assist (however, Drive Fuze will not itself provide a replacement vehicle under this Agreement until the Vehicle has been fully repaired or replaced); and`,
        },
        {
          subList: `the Driver has authorised Drive Fuze to correspond with the insurer on its behalf and the Driver agrees to comply with condition 8.5 above.`,
        },
      ],
    },
  },
  {
    entry: {
      mainText: `In the event that the accident is not considered by Drive Fuze (or its agent) to be the fault of another person who is insured, Drive Fuze may in its discretion agree to subscribe a replacement vehicle to the Driver under a new Subscription Agreement. The Driver must also continue to pay Subscription fees under this Agreement.`,
    },
  },
  {
    entry: {
      mainText: `If any Vehicle is declared a total loss and Drive Fuze does not receive any insurance monies or if the insurance monies received are in any way reduced because of any failure by the Driver to observe the terms and conditions of this Agreement, the Driver shall pay Drive Fuze on demand the difference between the insurance monies Drive Fuze do receive and the replacement value of the Vehicle.`,
    },
  },
];

export const cancellationList = [
  {
    entry: {
      mainText: `For the avoidance of doubt this Agreement and the Subscription Term ends only with the Driver's return of the Vehicle to Drive Fuze or a third party specified by Drive Fuze.`,
    },
  },
  {
    entry: {
      mainText: `Driver may elect to cancel their subscription and the following applies unless otherwise stated:`,
      subText: [
        {
          subList: `at any point before the Subscription Start Date, the Driver can cancel the subscription. The Initial Payment paid in advance by the Driver will be refunded (refunds can take up to 10 working days to appear in the Driver's bank account). We shall retain £100 as a cancellation charge to cover the vehicle administration cost.`,
        },
        {
          subList: `if the Driver cancels the subscription after 09.00 on the day before the Start Date and Drive Fuze are unable to cancel the delivery of the Vehicle, we shall retain 50% of the Delivery Charge paid.]`,
        },
        {
          subList: `if the Subscription Start Date has begun and the Driver has accepted the vehicle upon delivery by signing the check-in sheet a refund of the Monthly Subscription Fee and Initial Payment will not apply.`,
        },
        {
          subList: `If the Subscription Start Date has begun and the Driver rejects the Vehicle by not signing the Vehicle Condition Report and does not choose to subscribe then a refund of the Initial Payment under 6.7 will apply`,
        },
        {
          subList: `After payment of the first Subscription fee, when the Vehicle has been accepted and delivered the Driver will be required to pay the Subscription Fees for the Minimum Subscription Term. Should the Driver elect to cancel or change the vehicle before the end of the Minimum Subscription Term, an amount equal to two months Subscription Fee will be charged as an Early Termination Fee as detailed above in this Agreement.`,
        },
        {
          subList: `On the final month of the Minimum Subscription Term,  a 14-day notice period shall apply should the Driver elect to cancel or change the vehicle before the next rolling contract renewal date. If such notice is not given to Drive Fuze, the Driver will be liable to pay for the full Subscription Fee  whether or not the Vehicle is returned either within or at the end of the new month.`,
        },
        {
          subList: `After all the payments during the Minimum Subscription Term, a 14-day notice period shall apply should the Driver elect to cancel or change the vehicle before the next rolling contract renewal date. If such notice is not given to Drive Fuze, the Driver will be liable to pay for the full Subscription Fee  whether or not the Vehicle is returned either within or at the end of the new month.`,
        },
      ],
    },
  },
  {
    entry: {
      mainText: `Drive Fuze may cancel a subscription prior to the Subscription Start Date where reasonably required (for example, if an unforeseen Vehicle fault arises, or if the availability of the Vehicle changes) by notifying the Driver at any point prior to the Driver collecting the Vehicle (or having the Vehicle delivered to Driver, as the parties may agree), and any amounts paid shall be refunded in full to the Driver.`,
    },
  },
  {
    entry: {
      mainText: `If Drive Fuze attempts to collect the Vehicle from the Driver on the last day of the Subscription Term but is unable to do so due to the Driver's fault, Drive Fuze will allow a grace period of one day for the Driver to return the Vehicle. After this one day grace period, the Driver shall be liable to pay the daily rate for any additional time for which the Driver uses the Vehicle. This shall be calculated using the Daily Subscription Rate included in the Subscription Agreement (e.g., if a Driver returns a Vehicle two (2) days late, they will be charged two day's Subscription Fees based on the Daily Subscription Rate).`,
    },
  },
  {
    entry: {
      mainText: `Drive Fuze may terminate this Agreement if any of the following occur:`,
      subText: [
        {
          subList: `the Driver fails to pay any Subscription Fee, Fine, Toll, charges or any other amount due under this Agreement on the date when it becomes due;`,
        },
        {
          subList: `the Driver or Named Driver(s) breach their obligations under this Agreement including, without limitation, conditions 2, 5 or 7 of this Agreement`,
        },
        {
          subList: `the Driver is no longer in possession or control of the Vehicle;`,
        },
        {
          subList: `the Driver becomes bankrupt or enters into any arrangement or composition with creditors;`,
        },
        {
          subList: `the Driver or Named Driver receives endorsements or convictions on their driving licence which are unacceptable to Drive Fuze;`,
        },
        {
          subList: `the Driver is driving in a dangerous or unacceptable manner and not complying with the Telematics Terms & Conditions;`,
        },
        {
          subList: `if any insurance policy under condition 7 becomes invalid at any point during the Subscription Term;`,
        },
        {
          subList: `if any information supplied by the Driver to Drive Fuze in connection with this Agreement is inaccurate or misleading in any material way;`,
        },
        {
          subList: `if the Driver cancels the Payment Authority details in 12 without informing Drive Fuze within 3 business days of alternative Payment Instructions.`,
        },
      ],
    },
  },
  {
    entry: {
      mainText: `Drive Fuze has the right to terminate the Agreement at the end of the Minimum Subscription Term end date.  If terminated, the Vehicle must be returned to Drive Fuze, and the driver would be refunded any payments under 6.9`,
    },
  },
  {
    entry: {
      mainText: `Upon early termination of this Agreement, the Driver shall immediately be liable for (a) two months Subscription Fees as detailed in 11.2.5; (b) any arrears of Subscription Fees and other amounts due and payable at the time (including for the avoidance of doubt any Fines, Tolls, and other charges) and (c) any other amounts which may become due and payable under this Subscription Agreement.`,
    },
  },
  {
    entry: {
      mainText: `Upon expiry of the Subscription Term or on any early termination of this Subscription Agreement for any reason, the Driver's right to use of the Vehicle will cease and the Vehicle must immediately be returned to Drive Fuze or such location as Drive Fuze directs. Drive Fuze may repossess the Vehicle if the Driver fails to return the Vehicle and the Driver shall indemnify Drive Fuze in relation to any loss and damage resulting from the repossession of the Vehicle without prejudice to Drive Fuze's other rights and remedies under this Agreement.`,
    },
  },
  {
    entry: {
      mainText: `If the Driver fails to return the Vehicle to Drive Fuze on the last day of the Subscription Term or on any earlier termination as required the Driver shall be liable for an overdue fee for each day that the Vehicle is not returned (the Delay Period) pro rata as in condition 11.4.`,
    },
  },
  {
    entry: {
      mainText: `Failure by the Driver to return the Vehicle to Drive Fuze promptly in accordance with condition 11.8 may result in Drive Fuze reporting the Driver to the relevant authorities. Drive Fuze may arrange for any insurance on the Vehicle to end (if it has not ended already) and may report that the Vehicle is no longer insured to third parties in its sole discretion.`,
    },
  },
  {
    entry: {
      mainText: `On return of the Vehicle, the Driver must remove any debris, rubbish and the Driver's personal items from the Vehicle. Drive Fuze and/or our Partners will not be liable for loss or damage of belongings left in the vehicle upon collection.`,
    },
  },
  {
    entry: {
      mainText: `The Driver must then hand the keys to the Vehicle to Drive Fuze, or a third party on behalf of Drive Fuze, (or return them in a manner otherwise agreed with Drive Fuze).`,
    },
  },
  {
    entry: {
      mainText: `Drive Fuze may amend the Subscription End Date to such earlier time as may be reasonably required in connection with its business purposes, provided that the Driver shall be entitled to a pro rata refund of the Subscription Fees (at the daily subscription rate identified in the Subscription Agreement) for the number of days by which the Subscription Term has been shortened.`,
    },
  },
];
