import Icon from '../Icon';
import LinkButton from '../LinkButton';
import style from './toast-body.module.scss';

const ToastBody = ({ title, message, ctaLink, onClick }) => (
  <>
    <span className={style.toastTitle}>{title}</span>
    <p className={style.toastMessage}>{message}</p>
    {ctaLink && (
      <div className={style.linkContainer}>
        <LinkButton text={ctaLink} onClick={onClick} />
        <Icon icon="arrow-right" size={24} color="#001E78" />
      </div>
    )}
  </>
);

export default ToastBody;
