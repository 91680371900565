import React from 'react';
import ReactDOM from 'react-dom';

export default function usePortal() {
  const portal = React.useRef(document.createElement('div'));

  React.useEffect(() => {
    const portalDiv = portal.current;
    document.body.appendChild(portalDiv);
    return () => {
      document.body.removeChild(portalDiv);
    };
  }, []);

  function usePortalComponent(Component) {
    return React.useMemo(() => {
      const forward = React.forwardRef((props, ref) => {
        return portal.current
          ? ReactDOM.createPortal(<Component {...props} ref={ref} />, portal.current)
          : null;
      });
      forward.displayName = 'Portal';
      return forward;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Component]);
  }

  return { usePortalComponent, portal };
}
