/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import Icon from '@/components/general/Icon';
import { onFidoDriverCardDataCall } from '@/flow/Bookings/action';
import { replaceAllDashesWithSlash } from '@/helpers/common';
import ROUTE_NAME from '@/helpers/url-constants';
import { toastError } from '../ToastNotifications/toastType';
import style from './driverCard.module.scss';

const DriverCard = ({ driverCardDriver, type, activeSubscription, removeDate }) => {
  const [dlnreveal, setDlnReveal] = useState(0);
  const [dldreveal, setdlnReveal] = useState(0);
  const [onFidoData, setonFidoData] = useState();
  const router = useRouter();
  const { bookingId } = router.query;

  const driverLicenseNumberReveal = () => {
    if (dlnreveal === 0) {
      setDlnReveal(1);
    } else {
      setDlnReveal(0);
    }
  };
  const driverLicenseDateReveal = () => {
    if (dldreveal === 0) {
      setdlnReveal(1);
    } else {
      setdlnReveal(0);
    }
  };

  const fetchPlusReveal = async dr => {
    const driverId = driverCardDriver?.id || driverCardDriver?.driverId;
    if (type === 'upcomingDriver') {
      type = 'extraDriver';
    }
    const mainOrExtra = type;

    if (!onFidoData) {
      const dataMD = await onFidoDriverCardDataCall(driverId, mainOrExtra);
      if (!dataMD?.status) {
        toastError({
          title: dataMD?.message?.includes('Id not found')
            ? 'No driving licence'
            : 'Something went wrong',
          message: dataMD?.message?.includes('Id not found')
            ? 'There is no driving licence stored for this user'
            : dataMD?.message,
        });
      } else {
        setonFidoData(dataMD?.data);
        dr();
      }
    } else {
      dr();
    }
  };

  return (
    <div
      className={cx(
        style.driversCardContainer,
        type === 'mainDriver' ? style.driverCardMainDriver : style.driverCardExtraDriver,
        type === 'upcomingDriver' && style.upcomingDriver,
      )}
    >
      <div className={style.cardIcon}>
        <Icon
          icon="drivers-license"
          size={32}
          color={
            type === 'upcomingDriver' ? '#99A5C9' : type === 'mainDriver' ? '#001E78' : '#00E6F0'
          }
          className={style.icon}
        />
      </div>
      <div className={style.pill}>
        {type === 'mainDriver' ? <p>Main Driver</p> : <p>Extra Driver</p>}
      </div>
      <div className={style.driCardDeets}>
        <div className={style.name}>
          <h2>
            {driverCardDriver?.firstName || driverCardDriver?.extraDriverFirstName}{' '}
            {driverCardDriver?.lastName || driverCardDriver?.extraDriverLastName}
          </h2>
        </div>
        <section className={style.section}>
          <p>Driving licence</p>
          <div className={style.licenseIcon}>
            <p>
              {dlnreveal === 1 && onFidoData ? `${onFidoData?.drivingLicence}` : 'xxxxxxxxxxxx'}
            </p>
            <Icon
              icon={dlnreveal === 1 ? 'hide-eye' : 'eye'}
              size={23}
              color={type === 'upcomingDriver' ? '#99A5C9' : '#001E78'}
              className={style.licenseIconIcon}
              onClick={() => fetchPlusReveal(driverLicenseNumberReveal)}
            />
          </div>
        </section>
        <section className={style.section}>
          <p>Expiry date</p>
          <div className={style.licenseIcon}>
            <p>
              {dldreveal === 1 && onFidoData
                ? `${replaceAllDashesWithSlash(onFidoData?.expiryDate)}`
                : 'dd/mm/yyyy'}
            </p>
            <Icon
              icon={dldreveal === 1 ? 'hide-eye' : 'eye'}
              size={23}
              color={type === 'upcomingDriver' ? '#99A5C9' : '#001E78'}
              className={style.licenseIconIcon}
              onClick={() => fetchPlusReveal(driverLicenseDateReveal)}
            />
          </div>
        </section>
        {type === 'upcomingDriver' && removeDate && (
          <section className={style.section}>
            <div className={style.licenseIcon}>
              <p>Remove at {replaceAllDashesWithSlash(removeDate)}</p>
            </div>
          </section>
        )}
      </div>
      <div className={type === 'mainDriver' ? style.bckgrdIcon : style.bckgrdIcon2}>
        <Icon
          icon="brand-logo"
          size={450}
          color={
            type === 'upcomingDriver' ? '#E7EAFF' : type === 'mainDriver' ? '#BFF8FB' : '#898AFE'
          }
          className={style.icon}
        />
      </div>
      {type === 'extraDriver' && activeSubscription && (
        <div
          className={style.remove}
          onClick={() => router.push(`${ROUTE_NAME.MY_BOOKINGS}/${bookingId}/remove-driver`)}
        >
          <p>Remove driver</p>
        </div>
      )}
    </div>
  );
};

export default DriverCard;
