import classNames from 'classnames/bind';
import Icon from '../Icon';
import Button from '../Button';
import style from './modal-agreement.module.scss';

const cx = classNames.bind(style);

function ModalAgreement(props) {
  const { children, open, onClose, onDownload, loading } = props;

  return (
    <div className={cx(style.overlay, { open })}>
      <div className={style['overlay-background']} onClick={onClose}></div>
      <div className={style['overlay-modal']}>
        <div className={style.closeIcon}>
          <div onClick={onClose}>
            <Icon icon="cancel" size={32} color={style.brandColor} />
          </div>
        </div>
        <div className={style['overlay-content']}>{children}</div>
        <div className={style.cta}>
          <Button loading={loading} onClick={onDownload}>
            <div className={style.gap}>
              <Icon icon="download" size={21} color={style.fluorescentBlue} />
              <p>Download PDF</p>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ModalAgreement;
