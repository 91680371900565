import React from 'react';
import useBreakpoint from '@/helpers/customHooks/useBreakpoint';
import { convertToJsFormat } from '@/helpers/common';
import RenderTable from './Components/DesktopComponents/RenderTable';
import RenderTable2 from './Components/DesktopComponents/RenderTable2';
import RenderTable3 from './Components/DesktopComponents/RenderTable3';
import { SignatureCard, SignatureCard2 } from './Components/DesktopComponents/signatureUI';
import SubscriptionConditions from './Components/DesktopComponents/subscriptionConditions';
import style from './agreement.module.scss';

const SubscriptionAgreement = props => {
  const { data } = props;
  const isMobile = useBreakpoint('mobile');
  const handleClickScroll = id => {
    const element = document.getElementById(`#Section${id}`);
    if (element) {
      element.scrollIntoView(true);
    }
  };
  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <section className={style.headerSection}>
          <div className={style.heading}>
            Hire Agreement Regulated
            <br />
            by the Consumer Credit Act 1974
          </div>
        </section>
        <section className={style.infoSection}>
          {data?.metadata?.subscriptionId && (
            <div className={style.title}>Subscription ID: {data?.metadata?.subscriptionId}</div>
          )}
          <div className={style.infoWrapper}>
            <RenderTable
              title={`Name and Address of the Owner: (referred to as "DriveFuze") `}
              value={` Drive Fuze Limited, Building 101, Thurleigh, Bedford,Bedfordshire. MK442YP.`}
            />
            <RenderTable
              title={`Name and Address of the Driver `}
              value={` ${data?.metadata?.customerFullName}, ${
                data?.metadata?.fullDriverAddress?.flat || ''
              } ${data?.metadata?.fullDriverAddress?.address1 || ''} ${
                data?.metadata?.fullDriverAddress?.address2 || ''
              } ${data?.metadata?.fullDriverAddress?.city || ''} ${
                data?.metadata?.fullDriverAddress?.county || ''
              } ${data?.metadata?.fullDriverAddress?.postcode || ''}`}
            />
          </div>
        </section>
        <section className={style.infoSection}>
          <div className={style.title}>
            Key Financial Information
            <p className={style.text}>Vehicle details (the “vehicle”) (and see condition 1.3)</p>
          </div>
          <div className={style.infoWrapper}>
            <RenderTable
              title={`Vehicle make and model`}
              value={` ${data?.metadata?.vehicleMake}, ${data?.metadata?.vehicleModel}`}
            />
            {data?.metadata?.vehicleRegistrationNumber && (
              <RenderTable
                title={`Registration number `}
                value={` ${data?.metadata?.vehicleRegistrationNumber}`}
              />
            )}
            <RenderTable
              title={`Period of Subscription (the “Subscription Term”) ${
                data?.metaData?.tenureMonths === 1
                  ? ''
                  : 'including the (the “Minimum Subscription Term”)'
              }`}
              value={`Subscription Start Date: ${
                data?.metadata?.startDate?.split('-')?.length > 1
                  ? convertToJsFormat(data?.metadata?.startDate)
                  : data?.metadata?.startDate
              }`}
              value2={`${
                data?.version === 'v4'
                  ? `${
                      data?.metaData?.tenureMonths === 1
                        ? 'One month rolling contract'
                        : `One month rolling contract for a minimum of ${data?.metadata?.tenureMonths} months`
                    }`
                  : 'One month rolling contract'
              } `}
            />
            <RenderTable
              title={`Initial Payment`}
              value={` £${data?.metadata?.initialPaymentAmount} see condition 6.7.`}
            />
            <div className={style.tableType_1}>
              <div className={style.cardWrapper}>
                <div className={style.cardTitle}>Subscription Fee (the subscriptions)</div>
                <div className={style.multiCardData}>
                  <div className={style.multiCardInfo}>
                    <div className={style.dataTitle}>No of Payments</div>
                    <div className={style.dataValue}>
                      {data?.version === 'v4'
                        ? `${
                            data?.metadata?.tenureMonths === 1
                              ? `${data?.metadata?.tenureMonths} Month Rolling Payments and then monthly rolling thereafter`
                              : `${data?.metadata?.tenureMonths} Months of Monthly Rolling Payments (Minimum Subscription Term) followed by One month rolling Payments`
                          } `
                        : 'One Month Rolling Payments'}
                    </div>
                  </div>

                  <div className={style.multiCardInfo}>
                    <div className={style.dataTitle}>Amount Payable Including VAT</div>
                    <div className={style.dataValue}>
                      £{data?.metadata?.monthlySubscriptionCost} (“the monthly subscription rate”)
                      <br />£{data?.metadata?.dailySubscriptionCost} (“the daily subscription rate”)
                    </div>
                  </div>

                  <div className={style.multiCardInfo}>
                    <div className={style.dataTitle}>Payment Dates</div>
                    <div className={style.dataValue}>
                      {data?.metadata?.startDate?.split('-')?.length > 1
                        ? convertToJsFormat(data?.metadata?.startDate)
                        : data?.metadata?.startDate}{' '}
                      and on the same date there after.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {data?.version === 'v4' && (
              <RenderTable title={`Early Termination Fee`} value={'earlyFees'} data={data} />
            )}
          </div>
          <p className={style.text}>
            The Subscription Fee is inclusive of VAT. Drive Fuze may vary the amount of the
            Subscription Fee by giving the Driver not less than 7 days’ notice if there is a change
            in the law which increases Drive Fuze&apos;s liability when we account for VAT. If that
            happens Drive Fuze shall increase the next Subscription Fee to be paid by the amount we
            reasonably believe necessary to make good the extra expense the change causes Drive
            Fuze.
            <br />
            <br />
            Drive Fuze may also vary payments in accordance with condition 1.1 below.
          </p>
        </section>
        <section className={style.infoSection}>
          <div className={style.title}>
            Key Information
            <p className={style.text}>
              The following payments may be due from the Driver to Drive Fuze in addition to the
              Initial Payment and the Subscription Fee:
            </p>
          </div>
          <div className={style.infoWrapper}>
            <RenderTable2
              handleClickScroll={handleClickScroll}
              isMobile={isMobile}
              version={data?.version}
            />
            <p className={style.text}>
              The following charges may be payable by the Driver to Drive Fuze upon the failure of
              the Driver to do or refrain from doing anything which they are required to do or
              refrain from doing, as the case may be, under this Agreement:
            </p>
            <RenderTable3 handleClickScroll={handleClickScroll} isMobile={isMobile} data={data} />
            <p className={style.text}>
              <h4>IMPORTANT - READ THIS CAREFULLY TO FIND OUT ABOUT YOUR RIGHTS</h4>
              <br />
              The Consumer Credit Act 1974 covers this agreement and lays down certain requirements
              for your protection which should have been complied with when this agreement was made.
              If they were not, we cannot enforce this agreement against you without getting a court
              order.
              <br />
              <br />
              If you would like to know more about your rights under the Act, contact either your
              local Trading Standards Department or your nearest Citizens&apos; Advice Bureau.
            </p>
            <p className={style.text}>
              <h4>MISSING PAYMENTS</h4>
              <br />
              Missing payments could have severe consequences and may make obtaining credit more
              difficult.
              <br />
              This agreement is not cancellable.
            </p>
          </div>
          <SignatureCard customerData={data?.metadata} />
          <p className={style.text}>
            <h4>By signing the Agreement the Driver(s) also confirms that:</h4>
            <ul>
              <li>
                By electronically ticking, the Driver intends to authenticate the Agreement, and
                they will become legally bound by its terms on confirmation. The Driver’s name will
                appear above and constitute as a valid signature;
              </li>
              <li>
                The Driver requires Drive Fuze to commence its obligations under the Agreement and
                to arrange for the delivery of the Vehicle; the Driver has chosen the Vehicle as
                suitable for their purposes;
              </li>
              <li>
                The Driver will examine the Vehicle and satisfy themselves of its condition before
                accepting delivery;
              </li>
              <li>
                The Driver warrants that the particulars given by them in this Agreement are true
                and that this form was complete when it was signed;
              </li>
              <li>
                The Driver understands that the Subscription Fees and Excess Mileage Charges set out
                above include VAT at the rate in force at the date you sign this Agreement. These
                amounts will vary if there is a change in the applicable VAT rate.
              </li>
              <li>
                The Driver has selected the Vehicle and agrees that the terms of Clause 4.4 the
                restriction of liability clause is reasonable.
              </li>
            </ul>
          </p>
          <SignatureCard2 customerData={data?.metadata} version={data?.version} />
        </section>
        <section className={style.infoSection}>
          <div className={style.title}>Insurance</div>
          <p className={style.text}>
            The Drive Fuze Subscription agreement includes insurance to the Driver and Named Drivers
            as indicated below. The Insurance Product Information Document (IPID) can be found{' '}
            {data?.metadata?.ipidDocs}.
            <br />
            <br />
            Only the Driver and the following individual (the Named Driver) can drive the Vehicle
            during the Subscription Term:
            <br />
            <br />
            {data?.metadata?.mainDriverDetails?.name}, {data?.metadata?.mainDriverDetails?.dob},{' '}
            {data?.metadata?.mainDriverDetails?.licenceNumber},{' '}
            {data?.metadata?.mainDriverDetails?.expiryDate} (Driver)
            {data?.metadata?.secondDriverDetails?.name && (
              <>
                <br />
                <br />
                {data?.metadata?.secondDriverDetails?.name},{' '}
                {data?.metadata?.secondDriverDetails?.dob},{' '}
                {data?.metadata?.secondDriverDetails?.licenceNumber},{' '}
                {data?.metadata?.secondDriverDetails?.expiryDate} (Named Driver)
              </>
            )}
            <br />
            <br />
            See condition 2 for further information in relation to the responsibilities of the
            Driver and the Named Driver.
          </p>
        </section>

        <section className={style.infoSection}>
          <div className={style.title}> Subscription Conditions </div>
          <SubscriptionConditions version={data?.version} />
        </section>

        <section className={style.infoSection}>
          <div className={style.title}> Important - Your Personal Information</div>
          <p className={style.text}>
            We may use your personal information for a variety of purposes, including but not
            limited to checking your identity and credit history with one or more credit reference
            agencies, and they will share information about you. Further details of the use of
            information by us and the credit reference agencies can be found in our Fair Processing
            Notice (see below).
            <br />
            <br />
            We consider that such processing is necessary for our legitimate interests in
            considering applications and in operating Agreements and our business, and it is a
            requirement of entering into an Agreement.
            <br />
            <br />
            You have a right to object to our processing your information on grounds relating to
            your particular situation.
            <br />
            <br />
            For more information about this and your other rights see our Privacy Policy:{' '}
            <a className={style.sectionLink} href={data?.metadata?.privacyPolicyUrl}>
              {data?.metadata?.privacyPolicyUrl}
            </a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default SubscriptionAgreement;
