import React from 'react';
import useBreakpoint from '@/helpers/customHooks/useBreakpoint';
import Icon from '../Icon';
import style from './comingSoonOverlay.module.scss';

const ComingSoonOverlay = ({ vehicleDetailsPage }) => {
  const isTablet = useBreakpoint('tablet');
  const isMobile = useBreakpoint('mobile');

  return (
    <div className={vehicleDetailsPage ? style['vehicle-coming-soon'] : style['coming-soon-image']}>
      <div className={style['pink-circle']}>
        <div className={style['purple-circle']}>
          {vehicleDetailsPage ? (
            <Icon
              icon="brand-logo"
              // eslint-disable-next-line no-nested-ternary
              size={isMobile ? '32' : isTablet ? '42' : '64'}
              color={'#001E7830'}
            />
          ) : (
            <Icon
              icon="brand-logo"
              size={isTablet && !isMobile ? '54' : '32'}
              color={'#001E7830'}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ComingSoonOverlay;
