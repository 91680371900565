/* eslint-disable consistent-return */
import REQUEST from '@/helpers/http.service';
import API_ENDPOINTS from '@/helpers/apiEndpoint';

export const getOverviewDetails = async bookingId => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.BOOKINGS_DETAILS}/${bookingId}`,
    auth: true,
  });
  return res?.data;
};

export const getActiveBookings = async (query = '') => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.BOOKINGS_ACTIVE}${query}`,
    auth: true,
  });
  return res?.data;
};

export const getBookingsHistory = async () => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.BOOKINGS_HISTORY}`,
    auth: true,
  });
  return res?.data;
};

export const BBDdata = async id => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.BOOKINGS_DETAILS}/${id}/driver`,
    auth: true,
  });
  return res?.data;
};

export const onFidoDriverCardDataCall = async (driverId, mainOrExtra) => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.ONFIDO_DRIVER}/${driverId}?driverType=${mainOrExtra}`,
    auth: true,
  });
  return res?.data;
};

export const fetchBookingBillingList = async query => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.BOOKINGS_DETAILS}${query}`,
    auth: true,
  });
  return res?.data;
};

export const fetchBookingBillingInvoice = async id => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.BOOKINGS_DETAILS}/invoice/${id}`,
    auth: true,
  });
  return res?.data;
};
export const fetchMileage = async id => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.MILEAGE_DETAILS}/${id}`,
    auth: true,
  });
  return res?.data;
};

export const fetchBookingAgreement = async bookingId => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.BOOKINGS_DETAILS}/${bookingId}/aggreement`,
    auth: true,
  });

  return res?.data;
};

export const fetchFinesAndPcnList = async query => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.FINES_PCNS_LIST}${query}`,
    auth: true,
  });

  return res?.data;
};

export const fetchFinesAndPcnDetails = async finesPcnId => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.FINES_PCNS_LIST}/${finesPcnId}`,
    auth: true,
  });

  return res?.data;
};

export const fetchAllBookingsFinesAndPcn = async query => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.ALL_BOOKINGS_FINES_PCNS}${query}`,
    auth: true,
  });

  return res?.data;
};

export const getMetaData = async () => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.META_DATA}`,
    auth: false,
  });

  return res?.data;
};

export const getSubscriptionDocuments = async query => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.BOOKINGS_DETAILS}${query}`,
    auth: true,
  });
  return res?.data;
};

export const downloadCustomerSubscriptionDocument = documentId => async () => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.BOOKINGS_DETAILS}/${documentId}/download`,
    auth: true,
  });
  if (res?.data?.status) return res?.data;
};

export const getCustomerSubscriptionDocument = documentId => async () => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.BOOKINGS_DETAILS}/${documentId}/subscription-details`,
    auth: true,
  });
  if (res?.data?.status) return res?.data;
};

export const fetchMileageGraph = async query => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.MILEAGE_GRAPH}${query}`,
    auth: true,
  });
  if (res?.data?.status) return res?.data;
};

export const fetchRecommendedVehicles = async query => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.LISTING_RECOMMENDAD_VEHICLES}${query}`,
    auth: true,
  });
  if (res?.data?.status) return res?.data;
};

export const getUpcomingDriver = async query => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.BOOKINGS_DETAILS}/${query}/upcoming-driver`,
    auth: true,
  });
  return res?.data;
};

export const subscriptionChangeRequest = async bookingId => {
  const res = await REQUEST({
    method: 'GET',
    url: `subscription/customer/subscription-change-request/${bookingId}`,
    auth: true,
  });
  return res?.data;
};

export const subscriptionChangeRequestDetail = async (bookingId, changeRequestId) => {
  const res = await REQUEST({
    method: 'GET',
    url: `subscription/customer/change-request-detail/${changeRequestId}?bookingId=${bookingId}`,
    auth: true,
  });
  return res?.data;
};

export const generateSubscriptionDetail = async documentId => {
  const res = await REQUEST({
    method: 'GET',
    url: `booking/${documentId}/subscription-details`,
    auth: true,
  });
  return res?.data;
};

export const signSubscriptionAgreement = async requestId => {
  const res = await REQUEST({
    method: 'PUT',
    url: `subscription/customer/sign-agreement/${requestId}`,
    auth: true,
  });
  return res?.data;
};

export const displayPreOrderBanner = async bookingId => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.PRE_ORDER}/display-banner/pending-signature/${bookingId}`,
    auth: true,
  });
  return res?.data;
};

export const signPreOrderSubscriptionAgreement = async bookingId => {
  const res = await REQUEST({
    method: 'PUT',
    url: `${API_ENDPOINTS.PRE_ORDER}/sign-subscription-agreement/${bookingId}`,
    auth: true,
  });
  return res?.data;
};
