import SIGN_UP_TYPE from './action-type';

const initialState = {
  registrationDetails: {},
  status: false,
  message: '',
};

const signupReducer = (state = initialState, action = {}) => {
  switch (action?.type) {
    case SIGN_UP_TYPE.EMAIL_REGISTER:
      if (action?.payload?.data?.status) {
        state = {
          ...state,
          registrationDetails: action?.payload?.data,
          status: action?.payload?.data?.status,
          message: action?.payload?.data?.message,
        };
      } else {
        state = {
          ...state,
          registrationDetails: {},
          status: false,
          message: '',
        };
      }
      return state;
    case SIGN_UP_TYPE.CLEAR_FORM:
      state = {
        ...state,
        registrationDetails: {},
        status: false,
        message: '',
      };
      return state;
    default:
      return state;
  }
};

export default signupReducer;
